import {
  AUTH_URL,
  CLIENT_BASIC_AUTH,
  graphQLFetch,
  myxFetch,
  STORAGE_ACCESS_TOKEN,
  STORAGE_REFRESH_TOKEN
} from '../backend'

export function requestPasswordRecovery(email) {
  return myxFetch('/forgotpassword'
    , {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'

      },
      body: JSON.stringify({ email: email })
    })
}

export function resetPassword(token, password) {
  return myxFetch('/resetpassword',
    {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ token: token, newPassword: password
      }) }
  )
}

/**
 * @typedef {{ id: string, companyId: string }} Session
 * @return {Promise<Session>}
 */
export async function fetchSession() {

  const {
    getUser: data,
  } = await graphQLFetch(`
    {
      getUser {
        id
        companyId
        givenName
        familyName
        email
        address1
        phone
        city
        state
        zip
        inventoryAlert
        serviceAlert
        roleDetails {
          roleCode
        }
      }
    }
  `)

  const returnObj = {
    ...data,

    // convert list to object for fast-lookup
    roles: data.roleDetails.reduce((acc, roleDetail) => {
      return {
        ...acc,
        [roleDetail.roleCode]: true
      }
    }, {}),
  }
  return returnObj
}

/**
 * @typedef {{ id: string, companyId: string }} Session
 * @return {Promise<Session>}
 */
export async function fetchKioskRoles() {

  const data = await graphQLFetch(`
    {
      getMyKiosks {
       roleCode
      }
    }
  `)

  const returnObj = data.getMyKiosks.map(it => {
    return { roleCode: it.roleCode }
  })
  // eslint-disable-next-line no-console
  return returnObj
}

/**
 * Gets the account creating in token from the server
 * @param token
 * @returns {*}
 */
export function getTokenValues(token) {
  return myxFetch(`/userregistration/${token}`, {
    method: 'get',
    headers: {
      'Accept': 'application/json'
    }
  })
}

/**
 *
 * @param token -- string
 * @param newUserRequest -- Object
 * @returns {*} Success Object || MyxBackendError
 */
export function postNewUser(token, newUserRequest) {
  return myxFetch(`/user?token=${token}`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(newUserRequest)
  })
}

export function signIn(email, password) {
  return fetch(AUTH_URL, {
    method: 'post',
    headers: {
      Authorization: `Basic ${btoa(CLIENT_BASIC_AUTH)}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: [
      'grant_type=password',
      `username=${encodeURIComponent(email)}`,
      `password=${encodeURIComponent(password)}`
    ].join('&')
  }).then(response => {
    if (response.ok) {
      return response.json()
    }

    if (response.status === 400) {
      throw new Error('Incorrect email or password')
    }

    throw new Error('Error during authentication')
  }).then(responseJSON => {
    // update current session state to store the new tokens
    const accessToken = responseJSON.access_token
    const refreshToken = responseJSON.refresh_token

    localStorage.setItem(STORAGE_ACCESS_TOKEN, accessToken)
    localStorage.setItem(STORAGE_REFRESH_TOKEN, refreshToken)
  })
}

/**
 *
 * @param user
 * @returns {Promise<unknown>}
 */
export function updateUser(user) {
  return graphQLFetch(`
            mutation($update: UpdateUserInput!) {
              updateMyUser(update: $update) {
                id
              }
            }
          `, {
    update: {
      email: user.email,
      givenName: user.givenName,
      familyName: user.familyName,
      address1: user.address1,
      city: user.city,
      state: user.state,
      zip: user.zip,
      phone: user.phone
    }
  })
}

export function updateAlertPreferences(serviceAlert, inventoryAlert) {
  return graphQLFetch(
    `
          mutation updateMyAlert( $alertData: UpdateUserAlertInput!) {
            updateMyAlert( update: $alertData) {
              id
            }
          }
        `,
    {
      alertData: {
        serviceAlert: serviceAlert,
        inventoryAlert: inventoryAlert,
        alertWithEmail: serviceAlert || inventoryAlert,
        alertWithSms: false,
      }
    }
  )
}
