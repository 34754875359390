import { useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'
import * as locationHelper from '../../helpers/locationHelper'

// consume filter-related path entries
// eslint-disable-next-line max-statements
function consumePathFilterSlugs(encodedPathElementList, filterKeyList, filterSlugs) {
  const parsedFilterSlugValues = {}

  while (encodedPathElementList.length > 0) {
    const match = (/^([^:]+):(.*)$/).exec(encodedPathElementList[0])

    if (!match) {
      break
    }

    encodedPathElementList.shift()

    const slug = decodeURIComponent(match[1])
    const value = decodeURIComponent(match[2])

    parsedFilterSlugValues[slug] = value
  }

  /** @type {{ [key: string]: string }} */
  const updatedFilter = {}
  filterKeyList.forEach(filterKey => {
    const slugPrefix = filterSlugs[filterKey]
    const isPresent = Object.prototype.hasOwnProperty.call(parsedFilterSlugValues, slugPrefix)

    updatedFilter[filterKey] = isPresent ? parsedFilterSlugValues[slugPrefix] : null
  })

  return updatedFilter
}

// consume sort info
// eslint-disable-next-line max-params
function consumeSortInfo(encodedPathElementList, defaultSortColumn, sortOrder, filterKeyList, filterSlugs) {
  const sortMatch = (encodedPathElementList.length > 0
    ? (/^(\+|-)(.*)$/).exec(decodeURIComponent(encodedPathElementList[0]))
    : null
  )

  if (sortMatch) {
    encodedPathElementList.shift()

    const sortSlugPrefix = sortMatch[1]
    const sortSlug = sortMatch[2]
    const sortColumn = filterKeyList.find(filterKey => filterSlugs[filterKey] === sortSlug)

    return [ sortColumn || sortSlug, (sortSlugPrefix === '-') ]
  }

  return [ defaultSortColumn, sortOrder ]
}

// parse the suffix path slug as human-readable 1-based count
function consumePageInfo(encodedPathElementList, defaultPageSize) {
  const pageMatch = (encodedPathElementList.length > 0
    ? (/^page(\d+)(?:\*(\d+))?/).exec(decodeURIComponent(encodedPathElementList[0]))
    : null
  )

  if (pageMatch) {
    encodedPathElementList.shift()
  }

  return [
    pageMatch ? Math.max(0, parseInt(pageMatch[1], 10) - 1) : 0,
    (pageMatch && Math.min(500, parseInt(pageMatch[2], 10))) || defaultPageSize
  ]
}

function generatePath({ filterSlugs, defaultSortColumn, defaultPageSize }, filterData, sortColumn, sortReverse, pageIndex, pageSize) { // eslint-disable-line max-params
  const filterKeyList = Object.keys(filterSlugs)

  const encodedFilterSlugs = filterKeyList.map(filterKey => {
    const slugPrefix = filterSlugs[filterKey]
    const value = filterData[filterKey]

    return value === null ? null : `${encodeURIComponent(slugPrefix)}:${encodeURIComponent(value)}`
  })

  const sortPathElement = (sortColumn === defaultSortColumn && !sortReverse
    ? null
    : encodeURIComponent(`${sortReverse ? '-' : '+'}${filterSlugs[sortColumn] || sortColumn}`)
  )

  // use human-readable path slug, or empty if on first page and using default size
  const pagePathElement = (pageSize === defaultPageSize
    ? (pageIndex === 0
      ? null
      : encodeURIComponent(`page${pageIndex + 1}`)
    )
    : encodeURIComponent(`page${pageIndex + 1}*${pageSize}`)
  )

  const encodedPathElementList = [
    ...encodedFilterSlugs,
    sortPathElement,
    pagePathElement
  ]

  const encodedPath = encodedPathElementList.filter(item => item !== null).join('/')
  return (encodedPath === '' ? '' : `/${encodedPath}`)
}

/** @typedef {{
  subMatch: { path: string, url: string },
  filter: { [key: string]: string },
  sortColumn: string,
  sortReverse: boolean,
  pageIndex: number,
  pageSize: number,
  hasFilter: boolean,
  setFilter: (filterData: { [key: string]: string }) => void,
  setSort: (sortColumn: string, sortReverse: boolean) => void,
  setPage: (pageIndex: number, pageSize: number) => void
}} PaginatedTableState */

/**
 * @return {PaginatedTableState}
 */
export function usePaginatedTable({ match, filterSlugs, defaultSortColumn, defaultPageSize, orderDesc = false }) {
  const history = locationHelper.getRouterUseHistory()

  // get current active route info
  const currentMatchPath = match ? match.path : ''
  const currentMatchURL = match === null || match.url === '/' ? '' : match.url

  // find the trailing path segments below current route
  const tableMatch = useRouteMatch({
    path: `${currentMatchPath}/:filterPath*`
  })
  const path = tableMatch.params.filterPath

  const currentState = useMemo(() => {
    const filterKeyList = Object.keys(filterSlugs)

    const encodedPathElementList = path ? path.split(/\//g) : []
    const initialPathElementCount = encodedPathElementList.length

    const updatedFilter = consumePathFilterSlugs(encodedPathElementList, filterKeyList, filterSlugs)
    const [ updatedSortColumn, updatedSortReverse ] = consumeSortInfo(encodedPathElementList, defaultSortColumn, orderDesc, filterKeyList, filterSlugs)
    const [ updatedPageIndex, updatedPageSize ] = consumePageInfo(encodedPathElementList, defaultPageSize)

    // react-router type of match path for nested subpath nav routing
    const consumedPathElementCount = initialPathElementCount - encodedPathElementList.length
    const childMatchPath = currentMatchPath + Array(consumedPathElementCount).fill('/:unused').join('')

    return {
      // generate match object similar to react-router
      subMatch: {
        path: childMatchPath,
        url: currentMatchURL + generatePath(
          { filterSlugs, defaultSortColumn, defaultPageSize },
          updatedFilter,
          updatedSortColumn,
          updatedSortReverse,
          updatedPageIndex,
          updatedPageSize
        )
      },

      filter: updatedFilter,
      sortColumn: updatedSortColumn,
      sortReverse: updatedSortReverse,
      pageIndex: updatedPageIndex,
      pageSize: updatedPageSize
    }
  }, [ path, filterSlugs, defaultSortColumn, defaultPageSize, currentMatchPath, currentMatchURL, orderDesc ])

  function navigateTo(encodedPath) {
    history.push(currentMatchURL + encodedPath)
  }

  return {
    ...currentState,

    hasFilter: Object.values(currentState.filter).some(value => value !== null),

    setFilter(filterData) {
      navigateTo(generatePath(
        { filterSlugs, defaultSortColumn, defaultPageSize },
        filterData,
        currentState.sortColumn,
        currentState.sortReverse,
        0, // reset page
        currentState.pageSize
      ))
    },

    setSort(sortColumn, sortReverse) {
      navigateTo(generatePath(
        { filterSlugs, defaultSortColumn, defaultPageSize },
        currentState.filter,
        sortColumn,
        sortReverse,
        0, // reset page
        currentState.pageSize
      ))
    },

    setPage(pageIndex, pageSize) {
      navigateTo(generatePath(
        { filterSlugs, defaultSortColumn, defaultPageSize },
        currentState.filter,
        currentState.sortColumn,
        currentState.sortReverse,
        pageIndex,
        pageSize
      ))
    }
  }
}
