import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box, Button, Container, Grid, Typography, useTheme } from '@material-ui/core'
import { featuredImgs } from './featuredImgs'
import { fruitImgs } from './fruitImgs'
import { lemonadeImgs } from './lemonadeImgs'
import { sparklingImgs } from './sparklingImgs'
import { teaImgs } from './teaImgs'
import { zeroCalImgs } from './zeroCalImgs'
import FadeInUp from '../../../views/FadeInUp'

const styles = makeStyles((theme) => ({
  carouselButton: {
    background: 'transparent',
    borderRadius: 0,
    border: 0,
    padding: 0,
    minWidth: 'unset',
    margin: `0 ${theme.spacing(2)}px`,
    color: theme.palette.myxblack,
    '&:hover': {
      background: 'transparent',
      color: theme.palette.tertiary.main,
    }
  },
  carouselButtonSelected: {
    borderRadius: 0,
    border: 0,
    padding: 0,
    minWidth: 'unset',
    background: 'transparent',
    margin: `0 ${theme.spacing(2)}px`,
    color: theme.palette.tertiary.main,
    '&:hover': {
      background: 'transparent',
      color: theme.palette.tertiary.main,
    }
  },

  gridContainer: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  carouselContainer: {
    maxWidth: 1260,
    margin: '0 auto',
    padding: '40px 15px 70px'
  },
  gridButtonGroup: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: `${theme.spacing(2)}px 0`,
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  drinkContainer: {
    padding: '0 15px 40px'
  },
  drinkTitle: {
    textAlign: 'left',
    width: '100%',
    marginTop: theme.spacing(1)
  }
}))

/**
 * Enumerated array of grid option types.
 * @type {Readonly<{all: ({img: *, title: string}|{img: *, title: string}|{img: *, title: string}|{img: *, title: string}|{img: *, title: string})[], zero_cal: ({img: *, title: string}|{img: *, title: string}|{img: *, title: string}|{img: *, title: string}|{img: *, title: string})[], fruits: [{img: *, subTitle: string, title: string}, {img: *, subTitle: string, title: string}, {img: *, subTitle: string, title: string}], teas: ({img: *, title: string}|{img: *, title: string}|{img: *, title: string}|{img: *, title: string}|{img: *, title: string})[], lemonade: [{img: *, title: string}, {img: *, title: string}, {img: *, title: string}, {img: *, title: string}], sparkling: ({img: *, title: string}|{img: *, title: string}|{img: *, title: string}|{img: *, title: string}|{img: *, title: string})[]}>}
 */
const types = Object.freeze({
  all: featuredImgs,
  fruits: fruitImgs,
  lemonade: lemonadeImgs,
  sparkling: sparklingImgs,
  teas: teaImgs,
  zero_cal: zeroCalImgs
})

function FeaturedMyxGrid() {

  const [ selected, setSelected ] = useState(types.all)

  const theme = useTheme()
  const classes = styles(theme)

  const getButtonStyles = (btn) => btn === selected ? classes.carouselButtonSelected : classes.carouselButton

  return (
    <FadeInUp>
      <Container maxWidth={false} className={classes.gridContainer}>
        <Box className={classes.gridButtonGroup}>
          <Button className={getButtonStyles(types.all)} onClick={() => setSelected(types.all)}>ALL FEATURED</Button>/
          <Button className={getButtonStyles(types.fruits)} onClick={() => setSelected(types.fruits)}>FRUITS</Button>/
          <Button className={getButtonStyles(types.lemonade)} onClick={() => setSelected(types.lemonade)}>LEMONADE</Button>/
          <Button className={getButtonStyles(types.sparkling)} onClick={() => setSelected(types.sparkling)}>SPARKLING</Button>/
          <Button className={getButtonStyles(types.teas)} onClick={() => setSelected(types.teas)}>TEAS</Button>/
          <Button className={getButtonStyles(types.zero_cal)} onClick={() => setSelected(types.zero_cal)}>ZERO CALORIE</Button>
        </Box>
        <Grid container
          className={classes.carouselContainer}
          justifyContent={'center'}
          alignItems={'center'}
          alignContent={'center'}>
          {selected.map((img) => (
            <Grid key={img.title} item xs={6} sm={4} md={4} lg={3} xl={3}>
              <Box display={'flex'} className={classes.drinkContainer} flexDirection={'column'}>
                <img alt={img.title} src={img.img}/>
                <Typography className={classes.drinkTitle} variant="subtitle2">{img.title}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </FadeInUp>
  )
}

export default FeaturedMyxGrid
