import React from 'react'
import { useField } from 'formik'
import { Box, Typography } from '@material-ui/core'

const MyxFormikWrapper = (props) => {
  const [ field ] = useField({
    name: props.name,
    validate: undefined,
  })
  const errorMessage = (err) => {
    return (
      <Box data-testid={'myx-formik-wrapper-error'} key={err}>
        <Typography variant="body1" style={{ color: 'red' }}>{err}</Typography>
      </Box>
    )
  }

  const displayErrors = () => {
    if (props.errors && props.errors[props.name] && ((props.touched && props.touched[props.name]))) {
      if (Array.isArray(props.errors[props.name])) {
        return props.errors[props.name].map(err => {
          if (err) {
            return errorMessage(err)
          }
          return null
        }, () => {
          return null
        })
      }
      return errorMessage(props.errors[props.name])
    }
    return null
  }

  return (
    <>
      {React.Children.map(props.children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            key: field.name,
            ...props,
            name: field.name,
            value: field.value || '',
            onChange: field.onChange,
            onBlur: field.onBlur
          })
        }
        return child
      })
      }
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {
          displayErrors()
        }
      </div>
    </>
  )
}

export default MyxFormikWrapper
