import React from 'react'
import { Formik } from 'formik'
import * as userHelper from '../../../../helpers/userHelper'
import { Grid, TextField, Typography } from '@material-ui/core'
import MyxFormikWrapper from '../../../components/MyxFormikWrapper'
import MyxRoundedButton from '../../../components/MyxRoundedButton'
import RequestResultGrid from '../../../components/RequestResultGrid'
import * as Yup from 'yup'
import sessionContext from '../../../../state/sessionContext'
import * as locationHelper from '../../../../helpers/locationHelper'
import * as passwordHelper from '../../../../helpers/passwordHelper'

const FormSchema = Yup.object().shape({
  password: passwordHelper.passwordValidationYup(),
  passwordConfirmation: passwordHelper.passwordMatch('password'),
  givenName: Yup.string()
    .min(1, 'First Name must be at least 1 character')
    .max(45, 'First Name must be less than 45 characters'),
  lastName: Yup.string()
    .min(1, 'Last Name must be at least 1 character')
    .max(45, 'Last Name must be less than 45 characters'),
  phone: Yup.string()
    .max(15, 'Phone number must be less than 15 characters')
    .min(10, 'Phone number must be at least 10 characters')
    .matches(/[0-9]+$/, { message: 'Phone must be a number' }),
  address: Yup.string(),
  city: Yup.string(),
  state: Yup.string(),
  zip: Yup.string(),
})

function AccountVerifyForm({ token, initialValues }) {
  const session = sessionContext.useSessionContext()
  const history = locationHelper.getRouterUseHistory()
  const [ formState, setFormState ] = React.useState({ errors: [], message: undefined })
  return (<Formik
    initialValues={{
      password: '',
      ...initialValues,
    }}
    validationSchema={FormSchema}
    onSubmit={(value) => {
      userHelper.postNewUser(token, value)
        .then(() => {
          // eslint-disable-next-line no-warning-comments
          // TODO redirect them to a login page or something...
          setFormState({ errors: [], message: 'Thank you for signing up! You will be signed in shortly' })
          session.logIn(value.email, value.password)
            .then(() => {
              history.push('/my-account')
            })
            .catch(error => {
              setFormState({ errors: [ { code: '', message: 'Something went wrong, please try signing in to continue.' } ], message: 'Thank you for signing up!' })
            })
        }).catch((error) => {
          error.jsonPromise.then((res) => {
            setFormState({ errors: res.errors, message: undefined })
          }).catch((ex) => setFormState({
            errors: [ { code: '', message: 'Sorry, we couldn\'t complete your request.' } ],
            message: error.toString() }))
        })
    }}
  >
    {(({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
    }) => (
      <form data-testid={'account-verify-form-container'}

        id="account-verify" onSubmit={(event) => handleSubmit(event)}>
        <Grid container spacing={4}>

          <Grid item xs={12}>
            <MyxFormikWrapper
              name="password"
              label="Password"
              type="password"
              fullWidth
              margin="normal"
              variant="outlined"
              errors={errors}
              touched={touched}
            >
              <TextField
                inputProps={{ 'data-testid': 'account-verify-form-password' }}
              />
            </MyxFormikWrapper>
          </Grid>

          <Grid item xs={12}>
            <MyxFormikWrapper
              name="passwordConfirmation"
              label="Password Confirmation"
              type="password"
              fullWidth
              margin="normal"
              variant="outlined"
              errors={errors}
              touched={touched}
            >
              <TextField
                inputProps={{ 'data-testid': 'account-verify-form-password-confirmation' }}
              />
            </MyxFormikWrapper>
          </Grid>
          <Grid item xs={6}>
            <MyxFormikWrapper
              name="givenName"
              label="First Name"
              fullWidth
              margin="normal"
              variant="outlined"
              errors={errors}
              touched={touched}
            >
              <TextField
                inputProps={{ 'data-testid': 'account-verify-form-given-name' }}
              />
            </MyxFormikWrapper>
          </Grid>
          <Grid item xs={6}>
            <MyxFormikWrapper
              name="familyName"
              label="Last Name"
              fullWidth
              margin="normal"
              variant="outlined"
              errors={errors}
              touched={touched}
            >
              <TextField
                inputProps={{ 'data-testid': 'account-verify-form-family-name' }}
              />
            </MyxFormikWrapper>
          </Grid>

          <Grid item xs={6}>
            <MyxFormikWrapper
              name="phone"
              label="Phone Number"
              fullWidth
              margin="normal"
              variant="outlined"
              errors={errors}
              touched={touched}
            >
              <TextField
                inputProps={{ 'data-testid': 'account-verify-form-phone' }}

              />
            </MyxFormikWrapper>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">* Requires a 10 Digit Number, No Special Characters</Typography>
          </Grid>
          <Grid item xs={12}>
            {
              formState.message || formState.errors.length > 0
                ? <RequestResultGrid errors={formState.errors} message={formState.message} /> : null
            }
          </Grid>
        </Grid>
        <MyxRoundedButton
          data-testid={'account-verify-form-submit-btn'}
          disabled={ (errors && Object.keys(errors).length > 0) || formState.message === 'Thank you for signing up!' }
          type="submit">Submit</MyxRoundedButton>
      </form>))}
  </Formik>)
}

export default AccountVerifyForm
