import React from 'react'
import sessionContext from '../../state/sessionContext'
import { useModal } from 'react-modal-hook'
import { Dialog } from '@material-ui/core'
import AccountDialogs from './AccountModalDialog'
import * as locationHelper from '../../helpers/locationHelper'

function AccountDialogWrapper(props) {
  const history = locationHelper.getRouterUseHistory()
  const session = sessionContext.useSessionContext()
  const goToMyAccount = () => history.push('/my-account')

  const [ showModal, hideModal ] = useModal(({ onExited }) => {
    return <Dialog open={true} onClose={hideModal} onTransitionEnd={onExited}>
      <AccountDialogs startingDialog={props.startingDialog} hideModal={hideModal} sessionContext={session}/>
    </Dialog>
  }
  )

  function handleClick() {
    if (session.user) {
      goToMyAccount()
    } else {
      showModal()
    }
  }

  return React.cloneElement(props.children, { onClick: handleClick })
}
export default AccountDialogWrapper
