import React from 'react'
import { Box, Card, CardContent, CardMedia, Container, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/styles'
import MyxDroplet from './MyxDroplet'
import MyxUnderline from './MyxUnderline'
import FadeInUp from '../views/FadeInUp'
import arrow from '../../assets/graphics/arrow-down.svg'

const styles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  cover: {
    height: '100%',
    width: '100%'
  },
  cardDetail: {
    textAlign: 'center',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '650px',
    padding: '40px 20px',
    [theme.breakpoints.only('xl')]: {
      maxWidth: '490px',
    },
    [theme.breakpoints.only('lg')]: {
      maxWidth: '490px'
    },
    [theme.breakpoints.only('md')]: {
      maxWidth: '490px'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'none'
    }
  },
  imageGridCardSubTitle: {
    fontWeight: 'normal',
  },
  childrenWrapper: {
    marginTop: '5%'
  },
  tertiaryTextColor: {
    color: theme.palette.tertiary.main,
  },
  primaryTextColor: {
    color: theme.palette.primary.main,
  },
  textColor: {
    color: theme.palette.myxblack,
  },
  arrow: {
    position: 'relative',
    clear: 'both',
    display: 'block',
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginTop: theme.spacing(3),
    animation: '$bounce 1.5s infinite ease-in-out',
  },
  '@keyframes bounce': {
    '0%': {
      transform: 'translateY(0)'
    },
    '50%': {
      transform: 'translateY(15px)'
    },
    '100%': {
      transform: 'translateY(0)'
    }
  },
}))

const MyxMediaCard = ({ mediaSrc, mediaTitle, reverse, classes }) => <Box clone order={{ xs: 1, sm: reverse ? 2 : 1 }}>
  <Grid item key={mediaTitle} xs={12} sm={6} md={6}>
    <Card className={classes.card}>
      <CardMedia
        className={classes.cover}
        src={mediaSrc}
        component="img"
        title={mediaTitle}
      />
    </Card>
  </Grid>
</Box>

const MyxCardDetail = ({ title, subText, subTitle, color, children, reverse, classes, showDownArrow = false }) => {
  const theme = useTheme()
  const isSm = useMediaQuery(theme.breakpoints.only('sm'))
  const colorClass = () => {
    switch (color) {
    case 'tertiary':
      return classes.tertiaryTextColor
    case 'primary':
      return classes.primaryTextColor
    default:
      return classes.textColor
    }
  }
  return (
    <Box clone order={{ xs: 2, sm: reverse ? 1 : 2 }}>
      <Grid item key={title} xs={12} sm={6} md={6}>
        <Card className={classes.cardDetail}>
          <CardContent className={classes.content}>
            {!isSm && <MyxDroplet color={color} /> }
            <Typography variant="h1" style={{ maxWidth: '600px' }}>
              {title}
            </Typography>
            <MyxUnderline color={color}/>
            {
              subText ? <Typography variant="body1" >{subText}</Typography> : null
            }
            { subTitle
              ? <Typography className={colorClass()} variant="body1">
                {subTitle.toString().toUpperCase()}
              </Typography> : null
            }
            {showDownArrow && <img alt={'more'} src={arrow} className={classes.arrow}/> }
            <div className={classes.childrenWrapper}>
              {children}
            </div>
          </CardContent>
        </Card>
      </Grid>
    </Box>
  )
}

function ImageGridCard(props) {
  const classes = styles()

  const mediaCard = <MyxMediaCard
    {...props}
    classes={classes}
  />

  const cardContent = <MyxCardDetail
    {...props}
    classes={classes}
    children={props.children}
  />
  return (
    <Container maxWidth={false} style={{ clear: 'both' }}>
      <Grid container spacing={0}>
        { props.reverse ? <>{cardContent}{mediaCard}</>
          : <>{mediaCard}{cardContent}</>}
      </Grid>
    </Container>)
}

function MyxImageGridCard(props) {
  return props.noFade ? <ImageGridCard {...props} /> : <FadeInUp>
    <ImageGridCard {...props} />
  </FadeInUp>
}

export default MyxImageGridCard
