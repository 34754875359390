import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { ReactComponent as Droplet } from '../../assets/graphics/droplet.svg'
import { useTheme } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  '@keyframes tertiary': {
    '0%': {
      fill: theme.palette.tertiary.main
    },
    '50%': {
      fill: theme.palette.tertiary.fade
    },
    '100%': {
      fill: theme.palette.tertiary.main
    }
  },

  '@keyframes purple': {
    '0%': {
      fill: theme.palette.primary.main
    },
    '50%': {
      fill: theme.palette.primary.fade
    },
    '100%': {
      fill: theme.palette.primary.main
    }
  },
  droplet: {
    width: '50px',
    height: '65px',
    margin: '0 auto 25px',
    display: 'block',
    animationName: '$purple',
    animationDuration: '4s',
    animationTimingFunction: 'ease-in-out',
    animationIterationCount: 'infinite',
    animationPlayState: 'running'
  },
  dropletTertiary: {
    animationName: '$tertiary',
  },
}))

function MyxDroplet(props) {
  const theme = useTheme()
  const classes = useStyles(theme)
  const getColor = () => {
    if (props.color) {
      if (theme.palette[props.color]) {
        return theme.palette[props.color]
      }
      return props.color
    }
    return theme.palette.primary.main
  }
  const color = getColor()
  const tertiaryColor = props.color === 'tertiary'
  const theClasses = classes.droplet + ' ' + (tertiaryColor ? classes.dropletTertiary : null)
  return (
    <Droplet
      fill={color}
      className={theClasses}
      {...props}
    />)
}

export default MyxDroplet
