import React from 'react'
import MyxImageGridCard from '../../../components/MyxImageGridCard'
import MyxRoundedButton from '../../../components/MyxRoundedButton'
import BrickBottle from '../../../../assets/graphics/how-it-works/brick-bottle-1200x1200.jpg'
import GetStartedWrapper from '../../../components/GetStartedWrapper'

function BuildYourFavorites() {
  return <MyxImageGridCard
    mediaSrc={BrickBottle}
    mediaTitle="Drink"
    title="Build Your Favorites In Advance, Track Your Order History And Load Up Your Account."
    reverse
  >
    <GetStartedWrapper>
      <MyxRoundedButton myxvariant="primaryBorder" variant={'outlined'}>
            Get Started Now
      </MyxRoundedButton>
    </GetStartedWrapper>
  </MyxImageGridCard>
}

export default BuildYourFavorites
