import React from 'react'
import MyxProgressSpinner from '../components/MyxProgressSpinner'
import PropTypes from 'prop-types'
import { Box, Typography, useTheme } from '@material-ui/core'

function ExternalRedirect({ to }) {

  const theme = useTheme()

  React.useEffect(() => {
    window.location = to
  }, [ to ])

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignContent={'center'}
      alignItems={'center'}>
      <Box marginTop={theme.spacing(1)}>
        <Typography variant={'h5'}>Processing your request, just a moment...</Typography>
      </Box>
      <MyxProgressSpinner/>

    </Box>
  )
}

ExternalRedirect.propTypes = {
  to: PropTypes.string.isRequired,
}

export default ExternalRedirect
