import apple from '../../../../assets/graphics/ingredients-benefits/carousel/flavors/apple-300x300.jpg'
import blueberry from '../../../../assets/graphics/ingredients-benefits/carousel/flavors/blueberry-300x300.jpg'
import cranberry from '../../../../assets/graphics/ingredients-benefits/carousel/flavors/cranberry-300x300.jpg'
import dragonfruit from '../../../../assets/graphics/ingredients-benefits/carousel/flavors/dragon-fruit-300x300.jpg'
import ginger from '../../../../assets/graphics/ingredients-benefits/carousel/flavors/ginger-300x300.jpg'
import lemon from '../../../../assets/graphics/ingredients-benefits/carousel/flavors/lemon-300x300.jpg'
import lime from '../../../../assets/graphics/ingredients-benefits/carousel/flavors/lime-300x300.jpg'
import mango from '../../../../assets/graphics/ingredients-benefits/carousel/flavors/mango-300x300.jpg'
import orange from '../../../../assets/graphics/ingredients-benefits/carousel/flavors/orange-300x300.jpg'
import peach from '../../../../assets/graphics/ingredients-benefits/carousel/flavors/peach-300x300.jpg'
import pomegranate from '../../../../assets/graphics/ingredients-benefits/carousel/flavors/pomegranate-300x300.jpg'
import raspberry from '../../../../assets/graphics/ingredients-benefits/carousel/flavors/raspberry-300x300.jpg'
import strawberry from '../../../../assets/graphics/ingredients-benefits/carousel/flavors/strawberry-300x300.jpg'

export const flavorCarousels = [ {
  img: apple,
  title: 'Apple',
},
{
  img: blueberry,
  title: 'Blueberry',
},
{
  img: cranberry,
  title: 'Cranberry',
},
{
  img: dragonfruit,
  title: 'Dragonfruit',
},
{
  img: ginger,
  title: 'Ginger',
},
{
  img: lemon,
  title: 'Lemon',
},
{
  img: lime,
  title: 'Lime',
},
{
  img: mango,
  title: 'Mango',
},
{
  img: orange,
  title: 'Orange',
},
{
  img: peach,
  title: 'Peach',
},
{
  img: pomegranate,
  title: 'Pomegrante',
},
{
  img: raspberry,
  title: 'Raspberry',
},
{
  img: strawberry,
  title: 'Strawberry',
}
]
