import React from 'react'
import { ReactComponent as Ingredients } from '../../../../assets/graphics/MYX_FeatureIcons_HYDRATION.svg'
import { makeStyles } from '@material-ui/styles'
import Container from '@material-ui/core/Container'
import { Box, Grid, Typography, useTheme } from '@material-ui/core'
import FadeInUp from '../../../views/FadeInUp'
import MyxLink from '../../../components/MyxLink'

const styles = (theme) => ({
  easyStepIcon: {
    height: '200px',
    width: '100%',
    display: 'block',
    alignContent: 'center'
  },
  stepInstruction: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
})
const useStyles = makeStyles(styles)

function IngredientPoints() {
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <FadeInUp>
      <Container style={{ padding: `${theme.spacing(10)}px ${theme.spacing(3)}px` }}>
        <Grid container spacing={4}>
          <Grid item key={1} xs={12} sm={6} md={3}>
            <Ingredients className={classes.easyStepIcon}/>
            <Typography variant="h6" align="center">Eco-Friendly</Typography>
            <Typography variant="subtitle1" className={classes.stepInstruction}>Our state-of-the-art machines boast the lowest carbon footprint in the industry.</Typography>
          </Grid>

          <Grid item key={2} xs={12} sm={6} md={3}>
            <Ingredients className={classes.easyStepIcon}/>
            <Typography variant="h6" align="center">Healthy & Natural</Typography>
            <Typography variant="subtitle1" className={classes.stepInstruction}>Nothing but natural ingredients are used in your MYX. Which means better flavors and better for you.</Typography>
          </Grid>

          <Grid item key={3} xs={12} sm={6} md={3}>
            <Ingredients className={classes.easyStepIcon}/>
            <Typography variant="h6" align="center">Competitively Priced</Typography>
            <Box style={{ textAlign: 'center', margin: '0 auto' }}>
              <MyxLink to={'/connect'} style={{ color: theme.palette.primary.main }} >Contact us for more information</MyxLink>
            </Box>
          </Grid>

          <Grid item key={4} xs={12} sm={6} md={3}>
            <Ingredients className={classes.easyStepIcon}/>
            <Typography variant="h6" align="center">Smart</Typography>
            <Typography variant="subtitle1" className={classes.stepInstruction}>Powered by our proprietary technology, the MYX drink experience has been designed with one thing in mind – You.</Typography>
          </Grid>

        </Grid>
      </Container>
    </FadeInUp>)
}

export default IngredientPoints
