import React from 'react'
import MyxImageGridCard from '../../../components/MyxImageGridCard'
import MyxSign from '../../../../assets/graphics/operators/brick-myx-2.jpg'

function JoinTheMyxMovement() {
  return (<MyxImageGridCard
    showDownArrow={true}
    mediaTitle="Mindful Hydration"
    mediaSrc={MyxSign}
    title="Want to join the myx movement?"
    subTitle="Operators"
    color="primary"
    subText="Connect with us at myxmaster@myxdrinks.com"
  />)
}

export default JoinTheMyxMovement
