import React from 'react'
import JoinTheMyxMovement from './components/JoinTheMyxMovement'
import RevolutionizeTheBeverageIndustry from './components/RevolutionizeTheBeverageIndustry'
import SmarterDrinkKiosk from './components/SmarterDrinkKiosk'
import MyxTechnology from './components/MyxTechnology'
import BecomeAMyxPartnerToday from './components/BecomeAMyxPartnerToday'
import KioskE430Specs from './components/KioskE430Specs'
import OperatorFAQ from './components/OperatorFAQ'
import OperatorOrderForm from './components/OperatorOrderForm'

function Operators() {
  return (<>
    <JoinTheMyxMovement/>
    <RevolutionizeTheBeverageIndustry/>
    <SmarterDrinkKiosk/>
    <MyxTechnology/>
    <BecomeAMyxPartnerToday/>
    <KioskE430Specs/>
    <OperatorFAQ/>
    <OperatorOrderForm/>
  </>)
}

export default Operators
