import React from 'react'
import MyxRoundedButton from '../../../components/MyxRoundedButton'
import MyxImageGridCard from '../../../components/MyxImageGridCard'
import FadeInUp from '../../../views/FadeInUp'
import BrickGlass from '../../../../assets/graphics/how-it-works/brick-glass-1200x1200.jpg'
import * as locationHelper from '../../../../helpers/locationHelper'

function MyxMakesSplashAtNama() {
  const history = locationHelper.getRouterUseHistory()

  return (
    <FadeInUp>
      <MyxImageGridCard
        mediaSrc={BrickGlass}
        mediaTitle="Drink"
        color="tertiary"
        title={'Myx Makes a splash at NAMA'}
        subText="Bob Tullio's Product Perspective: A Closer Look at Products, Equipment and Services That Will Impact An Operator's Business"
      >
        <MyxRoundedButton myxvariant="tertiaryBorder" variant={'outlined'}
          onClick={() => history.push('/myx-in-the-news')}>
      Learn More
        </MyxRoundedButton>
      </MyxImageGridCard>
    </FadeInUp>)
}

export default MyxMakesSplashAtNama
