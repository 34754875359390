import React, { useState } from 'react'
import QrReader from 'react-qr-reader'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import * as backend from '../../backend'
import ProgressWrapper from '../components/ProgressWrapper'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
  },
}))

function QrLogin() {
  const classes = useStyles()

  const [ qrCode, setQrCode ] = useState(null)

  const [ modalState, setModalState ] = useState(null)

  const handleScan = data => {
    if (data && qrCode === null && modalState === null) {
      setQrCode(data)
    }
  }

  if (qrCode) {
    backend.graphQLFetch(`
      mutation($add: KioskQrAddInput!) {
        addQrLogin(add: $add) {
          code
        }
      }
    `, {
      add: {
        code: qrCode
      },
    }).then(() => {
      setModalState('Login Success! You should be logged in shortly')
      setQrCode(null)
    }).catch(() => {
      setModalState('Something went wrong! Try again later')
      setQrCode(null)
    })
  }

  const handleClose = () => setModalState(null)

  return (
    <Paper className={classes.paper}>
      <Typography variant="body2" color="secondary" align="center">
        Scan the QR on the kiosk
      </Typography>
      <ProgressWrapper pending={qrCode !== null && modalState === null}>
        <QrReader
          facingMode={'environment'}
          delay={300}
          onScan={handleScan}
          style={{ width: '100%', height: '75%' }}
        />
        <Dialog
          open={modalState !== null}
          onClose={handleClose}
        >
          <DialogContent>
            {modalState}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}>
                Close
            </Button>
          </DialogActions>
        </Dialog>
      </ProgressWrapper>
    </Paper>
  )
}

export default QrLogin
