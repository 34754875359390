import BerryBlast from '../../../../assets/graphics/featured-myxes/fruits/MYX-Featured-RasBlueStraw-1-300x380.jpg'
import CranRas from '../../../../assets/graphics/featured-myxes/fruits/MYX-Featured-CranRas-1-300x380.jpg'
import PomBlue from '../../../../assets/graphics/featured-myxes/fruits/MYX-Featured-PomBlue-1-300x380.jpg'

export const fruitImgs = [
  {
    img: BerryBlast,
    title: 'Berry Blast',
    subTitle: 'Fruits',
  },
  {
    img: CranRas,
    title: 'Cran Ras',
    subTitle: 'Fruits',
  },
  {
    img: PomBlue,
    title: 'Pomegranate Blueberry',
    subTitle: 'Fruits',
  }
]
