import BlackTea from '../../../../assets/graphics/zero-calories/MYX-Featured-Tea-1-300x380.jpg'
import DragonSeltzer from '../../../../assets/graphics/zero-calories/MYX-Featured-WaterDragon-300x380.jpg'
import GreenTea from '../../../../assets/graphics/zero-calories/MYX-Featured-GreenTea-1-300x380.jpg'
import WaterLemonLime from '../../../../assets/graphics/zero-calories/MYX-Featured-WaterLemonLime-1-300x380.jpg'
import LemonTea from '../../../../assets/graphics/zero-calories/MYX-Featured-TeaLemonade-1-300x380.jpg'
import MangoSeltzer from '../../../../assets/graphics/zero-calories/MYX-Featured-WaterMango-1-300x380.jpg'
import OrangeSeltzer from '../../../../assets/graphics/zero-calories/MYX-Featured-WaterOrange-1-300x380.jpg'
import PeachMangoLemonade from '../../../../assets/graphics/zero-calories/MYX-Featured-LemonadePeachMango-1-300x380.jpg'
import PeachMangoSeltzer from '../../../../assets/graphics/zero-calories/MYX-Featured-WaterPeachMango-1-300x380.jpg'
import PomBlueBerry from '../../../../assets/graphics/zero-calories/MYX-Featured-WaterPomBlue-300x380.jpg'
import PomSeltzer from '../../../../assets/graphics/zero-calories/MYX-Featured-WaterPom-1-300x380.jpg'
import WaterRasLime from '../../../../assets/graphics/zero-calories/MYX-Featured-WaterRasLime-1-300x380.jpg'
import WaterRas from '../../../../assets/graphics/zero-calories/MYX-Featured-WaterRas-1-300x380.jpg'

export const zeroCalImgs = [
  {
    img: BlackTea,
    title: 'Black Tea',
  },
  {
    img: DragonSeltzer,
    title: 'Dragonfruit Seltzer',
  },
  {
    img: GreenTea,
    title: 'Green Tea',
  },
  {
    img: WaterLemonLime,
    title: 'Lemon Lime Seltzer',
  },
  {
    img: MangoSeltzer,
    title: 'Mango Seltzer',
  },
  {
    img: OrangeSeltzer,
    title: 'Orange Seltzer',
  },
  {
    img: PeachMangoSeltzer,
    title: 'Peach Mango Seltzer',
  },
  {
    img: PomBlueBerry,
    title: 'Pomegranate Blueberry Seltzer',
  },
  {
    img: LemonTea,
    title: 'Lemon Tea',
  },
  {
    img: PeachMangoLemonade,
    title: 'Peach Mango Lemonade',
  },
  {
    img: PomSeltzer,
    title: 'Pomegranate Seltzer',
  },
  {
    img: WaterRasLime,
    title: 'Raspberry Lime Seltzer',
  },
  {
    img: WaterRas,
    title: 'Raspberry Seltzer',
  }
]
