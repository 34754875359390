import React from 'react'
import FrequentlyAskedQuestions from '../../../components/FrequentlyAskedQuestions'
import QAndA from '../../../components/QAndA'

const OperatorFAQ = () => (<FrequentlyAskedQuestions>
  <QAndA
    question="I’m not from the US, are you partnering internationally?"
    answer="Absolutely! Complete our inquiry form and we will get back to you ASAP."
  />
  <QAndA
    question="Do I manage my customers drink purchases?"
    answer="Our systems are designed to proactively provide you with customized data. This system/location specific data will manage inventories and ensure you never miss a tour. Customers can sign-up and download our app or simply step up to our system to manage their drink purchase."
  />
  <QAndA
    question="Are your natural ingredients certified?"
    answer="All our ingredients are rigorously tested and chosen to meet the highest standards. They are all certified according to their various categories as we have natural and organic products and supplements."
  />
</FrequentlyAskedQuestions>)

export default OperatorFAQ
