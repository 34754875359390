import React from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import MyxLogo from '../../assets/graphics/logo.svg'
import MyxLink from '../components/MyxLink'
import { Link } from 'react-router-dom'
import { Container, Drawer, List, ListItem, ListItemIcon, useTheme } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import InstagramIcon from '@material-ui/icons/Instagram'
import CloseIcon from '@material-ui/icons/Close'
import MuiAppBar from '@material-ui/core/AppBar'
import { makeStyles } from '@material-ui/styles'
import GetStartedWrapper from '../components/GetStartedWrapper'
import LoginWrapper from '../components/LoginWrapper'
import sessionContext from '../../state/sessionContext'

const styles = makeStyles((theme) => ({
  pageLink: {
    textTransform: 'uppercase',
    fontSize: 16,
    color: theme.palette.myxblack,
    float: 'left',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(1)

  },
  right: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    paddingLeft: theme.spacing(1)

  },

  menuButton: {
    margin: 0,
    padding: 0,
    '&:hover': {
      color: theme.palette.secondary.main
    }
  },
  lockButton: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    }
  },
  mobileTitle: {
    width: 120,
  },
  link: {
    marginLeft: 12,
    marginRight: 12,
    [theme.breakpoints.down('md')]: {
      marginLeft: 6,
      marginRight: 6,
    }
  },
  mobileLink: {
    fontWeight: '200',
    textDecoration: 'none',
    textTransform: 'capitalize',
    color: theme.palette.myxblack
  },
  closeBtn: {
    width: 35,
    height: 35
  }
}))

const MobileAppBar = () => {
  const _theme = useTheme()

  const classes = styles(_theme)

  const [ isOpen, setOpen ] = React.useState(false)
  const session = sessionContext.useSessionContext()
  const closeDrawer = () => setOpen(false)

  return (
    <Container data-testid={'mobile-app-bar-container'}>
      <MuiAppBar elevation={0} position="sticky" >
        <Toolbar >
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="primary"
            aria-label="menu"
            onClick={() => setOpen(!isOpen)}
          >
            <MenuIcon style={{ width: 35 }}/>
          </IconButton>
          <Link
            underline="none"
            color="inherit"
            to="/"
          >
            <img
              className={classes.mobileTitle}
              alt="Myx Logo"
              src={MyxLogo}/>
          </Link>
          <LoginWrapper>
            <IconButton
              edge="start"
              className={classes.lockButton}
              color="primary"
              aria-label="menu">
              <LockOpenIcon />
            </IconButton>
          </LoginWrapper>
        </Toolbar>
      </MuiAppBar>
      {
      // note: the error findDOMNode is deprecated in StrictMode is a material-ui bug that should be fixed in v5. Till then, we wait.
      }
      <Drawer
        anchor="left"
        open={isOpen}
        onClose={closeDrawer}>
        <List>
          <ListItem>
            <ListItemIcon onClick={closeDrawer}>
              <CloseIcon className={classes.closeBtn} color="primary"/>
            </ListItemIcon>
          </ListItem>
          {session.user &&
          <ListItem>
            <MyxLink
              to={'/kiosk-log-in/'}
              onClick={closeDrawer}
              className={classes.pageLink}>
              Kiosk QR Log In
            </MyxLink>
          </ListItem>
          }
          <ListItem>
            <MyxLink to={'/how-it-works/'} onClick={closeDrawer} className={classes.pageLink} >How it works</MyxLink>
          </ListItem>
          <ListItem>
            <MyxLink to={'/ingredients-benefits/'} onClick={closeDrawer} className={classes.pageLink} >Ingredients & Benefits</MyxLink>
          </ListItem>
          <ListItem>
            <MyxLink to={'/featured-myx/'} onClick={closeDrawer} className={classes.pageLink} >Featured Myx</MyxLink>
          </ListItem>
          <ListItem>
            <MyxLink to={'/operators/'} onClick={closeDrawer} className={classes.pageLink} >Operators</MyxLink>
          </ListItem>
          <ListItem>
            <MyxLink to={'/connect/'} onClick={closeDrawer} className={classes.pageLink} >Connect</MyxLink>
          </ListItem>
        </List>
        <List>
          <ListItem>
            <GetStartedWrapper>
              <MyxLink data-testid={'app-app-bar-get-started'} to="/" className={classes.mobileLink}>Get Started</MyxLink>
            </GetStartedWrapper>
          </ListItem>
          <ListItem>
            <LoginWrapper>
              <MyxLink to="/login" className={classes.mobileLink} onClick={closeDrawer}>Login</MyxLink>
            </LoginWrapper>
          </ListItem>
          <ListItem>
            <MyxLink href="https://www.instagram.com/myxdrinks/">
              <InstagramIcon color={'action'}/>
            </MyxLink>
          </ListItem>
        </List>
      </Drawer>
    </Container>
  )
}

export default MobileAppBar
