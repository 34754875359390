import React from 'react'
import InstagramIcon from '@material-ui/icons/Instagram'
import MyxLink from '../../../components/MyxLink'
import { Box, Grid, Typography } from '@material-ui/core'
import arrow from '../../../../assets/graphics/arrow.svg'
import { makeStyles } from '@material-ui/styles'
import FadeInUp from '../../../views/FadeInUp'
import GetStartedWrapper from '../../../../modules/components/GetStartedWrapper'

const styles = makeStyles((theme) => ({
  outerContainer: {
    backgroundColor: 'white',
    padding: '40px 35px',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      padding: '30px 35px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '16px 16px',
    },
  },
  gridContainer: {
    maxWidth: '1260px',
    margin: '0 auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      maxWidth: '920px',
    },
  },
  becomeMemberContainer: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: '20px'
    },
  },
  signMeUpContainer: {
    padding: '14px 15px',
    textAlign: 'center',
    borderBottom: `solid 2px ${theme.palette.primary.main}`,
    maxWidth: '280px',
    flexBasis: '280px',
    [theme.breakpoints.down('md')]: {
      padding: '14px 5px',
      maxWidth: '30%',
      flexBasis: '30%',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      flexBasis: '100%',
    }
  },
  signMeUp: {
    textTransform: 'uppercase',
    fontWeight: '500',
  },
  signUpTextContainer: {
    flexBasis: '29%',
    [theme.breakpoints.only('md')]: {
      flexBasis: '28%',
    },
    [theme.breakpoints.only('sm')]: {
      display: 'none'
    },
    [theme.breakpoints.only('xs')]: {
      maxWidth: 'unset'
    },
  },
  signMeUpArrow: {
    marginLeft: '14px',
    bottom: '3px',
    left: 0,
    width: '28px',
    height: '10px'
  },
  instaIcon: {
    width: '25px',
    height: '25px',
    color: theme.palette.myxblack,
  },
  becomeMember: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  instaContainer: {
    textAlign: 'right',
    [theme.breakpoints.only('xs')]: {
      display: 'none'
    },
  }
}))

const BecomeAMemberBar = (props) => {

  const classes = styles()

  return (
    <FadeInUp>
      <Box className={classes.outerContainer}>
        <Grid
          container
          alignItems={'center'}
          className={classes.gridContainer}>
          <Grid item className={classes.becomeMemberContainer}>
            <Typography
              className={classes.becomeMember}
              color="primary"
              align="left"
              variant="h4"
              marked="left">
          Become a Myx Member
            </Typography>
          </Grid>
          <Grid item className={classes.signUpTextContainer}>
            <Typography variant="body2" style={{ textAlign: 'center' }}>
            Sign up for your FREE MYX membership today. Choose, track, pay from the palm of your hand.
            </Typography>
          </Grid>
          <Grid item xs={3} className={classes.signMeUpContainer}>
            <GetStartedWrapper>
              <MyxLink href="#">
                <Typography variant="h5"
                  className={classes.signMeUp}>
          Sign Me Up Now <img className={classes.signMeUpArrow} alt={'arrow'} src={arrow} />
                </Typography>
              </MyxLink>
            </GetStartedWrapper>
          </Grid>

          <Grid item className={classes.instaContainer}>
            <MyxLink className={classes.instaIcon} href="https://www.instagram.com/myxdrinks/">
              <InstagramIcon className={classes.instaIcon} />
            </MyxLink>
          </Grid>
        </Grid>
      </Box>
    </FadeInUp>
  )
}

export default BecomeAMemberBar
