import React from 'react'
import { makeStyles, Link as HrefLink } from '@material-ui/core'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  myxLink: {
    flexShrink: 0,
    transition: '200ms all ease',
    fontSize: '15px',
    textTransform: 'uppercase',
  },
  whiteHoverPrimary: {
    color: theme.palette.myxblack,
    '&:hover': {
      color: theme.palette.primary.main,
    }
  },
  primaryHoverTertiary: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.tertiary.main
    }
  }
}))

const MyxLink = (props) => {
  const classes = useStyles()

  const className = classes[props.myxvariant || 'whiteHoverPrimary'] + ' ' + classes.myxLink

  return (props.href ? <HrefLink
    style={{ textDecoration: 'none' }}
    className={className}
    {...props}
  >
    {props.children}
  </HrefLink>
    : <Link
      style={{ textDecoration: 'none' }}
      className={className}
      {...props}>
      {props.children}
    </Link>)
}

export default MyxLink
