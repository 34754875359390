import React from 'react'
import MyxImageGridCard from '../../../components/MyxImageGridCard'
import MyxRoundedButton from '../../../components/MyxRoundedButton'
import BrickMindful from '../../../../assets/graphics/featured-myxes/brick-mindful-1200x1200.jpg'
import * as locationHelper from '../../../../helpers/locationHelper'

function JoinTheMyxMovement() {
  const history = locationHelper.getRouterUseHistory()
  return <MyxImageGridCard
    mediaSrc={BrickMindful}
    mediaTitle="Drink"
    color="tertiary"
    title="Join The Myx Movement"
    subText="We are on a mission to protect the environment one disposable water bottle at a time, it’s what inspired our journey but it didn’t stop there. Enjoy millions of drink combinations at your fingertips using your own vessel."
    reverse
  >
    <MyxRoundedButton
      onClick={() => history.push('/how-it-works')}
      myxvariant="tertiaryBorder"
      variant={'outlined'}>
      How MYX Works
    </MyxRoundedButton>
  </MyxImageGridCard>
}

export default JoinTheMyxMovement
