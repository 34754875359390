import React from 'react'
import hero1920 from '../../../../assets/graphics/homepage/heros/benefits/hero-Home-benefits-1920x1133.jpg'
import { makeStyles } from '@material-ui/styles'
import HomePageCardView from './HomePageCardView'
import * as locationHelper from '../../../../helpers/locationHelper'

const styles = makeStyles((theme) => ({
  background: {
    width: '100%',
    objectFit: 'cover',
    backgroundColor: 'white',
  },
}))

function MillionsOfRefreshingCombinations(props) {
  const classes = styles()
  const history = locationHelper.getRouterUseHistory()
  const handleBtnClick = () => {
    history.push('/ingredients-benefits')
  }
  return (
    <HomePageCardView
      picture={
        <picture>
          <img
            itemProp={'images'}
            alt={'The worlds smartest beverage system'}
            src={hero1920}
            className={classes.background}/>
        </picture>
      }
      title={'With Millions Of Refreshing Combinations This is My MYX.'}
      text = {<>
        <strong>I call it 'Poolside Bliss'</strong><br/>
        <strong>Base:</strong> Lemonade<br/>
        <strong>Splashes:</strong> Pomegranate, Raspberry<br/>
        <strong>Enhancement:</strong> Electrolytes
      </>}
      color={'purple'}
      buttonText={'Ingredients & Benefits'}
      handleBtnClick={handleBtnClick}
    />
  )
}

export default MillionsOfRefreshingCombinations
