import React from 'react'
import MyxLink from '../components/MyxLink'
import { Container, MenuItem, Select } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/styles'
import sessionContext from '../../state/sessionContext'
import MyxProgressSpinner from '../components/MyxProgressSpinner'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'

const styles = makeStyles((theme) => ({
  accountIcon: {
    marginRight: theme.spacing(1),
    height: theme.spacing(3),
    width: theme.spacing(3),
  },
  userSelect: {
    minWidth: 180,
  },
  link: {
    marginLeft: 12,
    marginRight: 12,
    [theme.breakpoints.down('md')]: {
      marginLeft: 6,
      marginRight: 6,
    }
  },
}))

const UserAvatarButton = () => {
  const session = sessionContext.useSessionContext()
  const classes = styles()
  return (
    <Container data-testid={'user-avatar-button'}>
      {session.user
        ? <Select
          value="-"
          className={classes.userSelect}>
          <MenuItem disabled value="-">
            <IconButton
              data-testid={'user-avatar-button-start-btn'}
              edge="start"
              className={classes.accountIcon}
              color="primary"
              aria-label="menu">
              <AccountCircleIcon />
            </IconButton>
            <em>{session.user.givenName || ''}</em>
          </MenuItem>
          <MenuItem>
            <MyxLink to="/my-account">My Account</MyxLink>
          </MenuItem>
          <MenuItem>
            <MyxLink to="/my-transactions">My Transactions</MyxLink>
          </MenuItem>
          <MenuItem>
            <MyxLink to="/sign-out">Sign Out</MyxLink>
          </MenuItem>
        </Select>
        : <MyxProgressSpinner />
      }
    </Container>
  )
}

export default UserAvatarButton
