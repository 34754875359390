import React from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import AccountInformationForm from './AccountInformationForm'
import ChangePasswordForm from './ChangePasswordForm'
import ChangeEmailForm from './ChangeEmailForm'
import FadeInUp from '../../../views/FadeInUp'
import AccountFormLinks, { forms } from './AccountFormLinks'
import EmailPreferences from '../../../components/EmailPreferences'

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(10),
  },
  grid: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      alignContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
  },
  gridButtonGroup: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: '300px',
      borderRight: `2px solid ${theme.palette.myxblack}`
    }
  },

}))

function AccountForm() {
  const _theme = useTheme()
  const classes = useStyles(_theme)

  const [ form, setForm ] = React.useState(forms.account)
  const isMobile = useMediaQuery(_theme.breakpoints.down('md'))

  const getForm = () => {
    switch (form) {
    case forms.password:
      return <ChangePasswordForm/>
    case forms.email:
      return <ChangeEmailForm />
    case forms.emailPreference:
      return <EmailPreferences />
    case forms.account:
    default:
      return <AccountInformationForm />
    }
  }
  return (
    <FadeInUp>
      <Container data-testid={'account-form-container'} className={classes.container}>
        <Grid className={classes.grid} container direction={isMobile ? 'column' : null} justifyContent={isMobile ? 'center' : null}>
          <Grid className={classes.gridButtonGroup} item xs={12} lg={3}>
            <AccountFormLinks setForm={setForm} page={form}/>
          </Grid>
          <Grid item xs={12} lg={9}>
            { getForm() }
          </Grid>
        </Grid>
      </Container>
    </FadeInUp>)
}
export default AccountForm
