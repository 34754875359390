import React from 'react'
import { makeStyles } from '@material-ui/styles'

import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import ProductHeroLayout from '../../../views/ProductHeroLayout'
import { carouselImages } from './carouselImages'
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import MyxCarouselLeftButton from '../../../components/carousel/MyxCarouselLeftButton'
import MyxCarouselRightButton from '../../../components/carousel/MyxCarouselRightButton'
import { ReactComponent as DoubleArrow } from '../../../../assets/graphics/double-arrow.svg'
import { useWindowSizeContext } from '../../../../state/WindowSizeProvider'

const styles = makeStyles((theme) => ({
  carouselContainer: {
    padding: '25px 0'
  },
  sliderItemContainer: {
    margin: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    overflow: 'hidden',
    '&:hover > $drinkNameOverlay': {
      animationName: '$fadeIn',
      animationDuration: '0.25s',
      '-webkit-animation-name': '$fadeIn',
      'animation-fill-mode': 'both',
      '-webkit-animation-duration': '0.25s',
      '-webkit-animation-fill-mode': 'both',
      zIndex: 10
    },

    '&:hover > $sliderItemImg': {
      transform: 'scale(1.1)',
      zIndex: 9
    },
  },
  sliderItemImg: {
    overflow: 'hidden',
    objectFit: 'contain',
    width: '100%',
    transition: 'transform .5s ease',
  },
  itemClass: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
  },
  overlayContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
    margin: 0,
    padding: '0 20px',
    background: theme.palette.white,
    position: 'absolute',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    top: 0,
    [theme.breakpoints.only('xl')]: {
      left: '9%',
    },
    [theme.breakpoints.only('lg')]: {
      left: '11%',
    },
    [theme.breakpoints.only('md')]: {
      left: '7.7%',
    },
    [theme.breakpoints.down('sm')]: {
      left: '12%',
    },

  },
  overlayTitle: {
    textAlign: 'center',
    marginBottom: 15,
    color: theme.palette.myxblack,
  },
  overlayText: {
    color: theme.palette.myxblack,
    textAlign: 'center',
    marginBottom: 30
  },
  overlayArrow: {
    textAlign: 'center',
    width: 35,
    height: 10,
    fill: theme.palette.tertiary.main
  },
  '@keyframes fadeIn': {
    'from': {
      opacity: 0,
      backgroundColor: 'transparent'
    },
    'to': {
      opacity: 1,
      backgroundColor: 'white'
    }
  },
  drinkNameOverlay: {
    position: 'absolute',
    overflow: 'hidden',
    opacity: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '75%',
    width: '65%',
  },
  drinkName: {
    color: theme.palette.myxblack,
    fontWeight: 500,
    textAlign: 'center'
  }
}))

function DiscoverNewMyxCarousel() {
  const theme = useTheme()

  const classes = styles(theme)

  const [ windowSize ] = useWindowSizeContext()

  const featuredDrinks = () => {
    return carouselImages.map((item) => {
      return (
        <Box key={item.title} className={classes.sliderItemContainer} >
          <Box className={classes.drinkNameOverlay}>
            <Typography className={classes.drinkName} variant="h5">{item.title}</Typography>
          </Box>
          <img className={classes.sliderItemImg} alt={item.title} src={item.img}/>
        </Box>
      )
    })
  }

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { min: 3000 },
      items: 6
    },
    desktop: {
      breakpoint: { max: 3000, min: 1920 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1920, min: 768 },
      items: 4
    },

    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1
    },
  }
  const isMd = useMediaQuery(theme.breakpoints.down('md'))
  const isSm = useMediaQuery(theme.breakpoints.down('sm'))

  const getOverlayWidth = () => (windowSize.width / 6.5)

  return (
    <ProductHeroLayout className={classes.carouselContainer}>
      <Carousel
        autoPlay={true}
        centerMode={!isMd}
        infinite={true}
        responsive={responsive}
        showDots={false}
        itemClass={classes.itemClass}
        customLeftArrow ={<MyxCarouselLeftButton/> }
        customRightArrow ={<MyxCarouselRightButton/> }
      >
        {featuredDrinks()}
      </Carousel>
      {!isSm &&
      <Box className={classes.overlayContainer} style={{ width: getOverlayWidth(), minWidth: 225, maxWidth: getOverlayWidth() }}>
        <Typography variant="h2" className={classes.overlayTitle}>Discover a new MYX</Typography>
        <Typography variant="body2" className={classes.overlayText}>Explore Our Featured MYX Combinations</Typography>
        <DoubleArrow className={classes.overlayArrow} />
      </Box>
      }
    </ProductHeroLayout>
  )
}

export default DiscoverNewMyxCarousel
