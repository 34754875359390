import React from 'react'
import { ReactComponent as MyxDroplet } from '../../assets/graphics/logo-droplet.svg'
import { Box, Container, IconButton, TextField, Typography, useTheme } from '@material-ui/core'
import { Formik } from 'formik'
import DraftsIcon from '@material-ui/icons/Drafts'
import { makeStyles } from '@material-ui/styles'
import CloseIcon from '@material-ui/icons/Close'
import * as Yup from 'yup'
import { newSignup } from '../../backend'
import MyxFormikWrapper from './MyxFormikWrapper'
import LogInSignUpLinks from './LogInSignUpLinks'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    margin: `${theme.spacing(1)}px auto`,
    maxWidth: '400px'
  },
  boxContainer: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    margin: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    maxWidth: '400px',
  },
  boxItem: {
    marginTop: theme.spacing(0.5)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  draftsIcon: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    }
  }
}))

const FormSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .email('Invalid Email')
})

const completedMsg = 'Validation email sent, please check your email for further instructions.'

function SignupDialog(props) {
  const theme = useTheme()
  const classes = useStyles(theme)

  const [ formState, setFormState ] = React.useState({ errors: [], message: undefined })

  const notifyUnableToFetch = (error) => {
    setFormState({
      errors: [ { code: '', message: 'Sorry, we couldn\'t process your request.' } ],
      message: null
    }
    )
  }

  const disableButton = () => formState.message === completedMsg

  return (

    <Container
      data-testid={'signup-dialog'}
      className={classes.container}>
      <Formik
        initialValues={{
          email: undefined,
        }}
        validationSchema={FormSchema}
        onSubmit={(value) => {
          newSignup(value)
            .then((response) => {
              setFormState({ errors: [], message: completedMsg })
            }).catch((error) => {
              if (error.jsonPromise) {
                error.jsonPromise.then((res) => {
                  setFormState({ errors: res.errors, message: undefined })
                }).catch((ex) => notifyUnableToFetch(error))

              } else {
                notifyUnableToFetch(error)

              }
            })
        }}
      >
        {({

          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,

        }) => (
          <form id="signup-form" onSubmit={(formEvent) => handleSubmit(formEvent)}>
            <IconButton aria-label="close" className={classes.closeButton} onClick={props.onClose}>
              <CloseIcon/>
            </IconButton>
            <Box className={classes.boxContainer}>
              <Box className={classes.boxItem}>
                <MyxDroplet/>
              </Box>
              <Box className={classes.boxItem}>
                <Typography variant="h4">Sign up Today</Typography>
              </Box>
              <Box className={classes.boxItem}>
                <Typography variant="body1">
                  Sign up below for your MYX Drinks account and check your email for the next steps.
                </Typography>
              </Box>
              <Box className={classes.boxItem}>
                <MyxFormikWrapper
                  name="email"
                  label="Your Email"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  errors={{ email: [ errors.email, ...formState.errors.map(err => err.message) ] }}
                  touched={touched}
                >
                  <TextField/>
                </MyxFormikWrapper>
                {
                  formState.message && <Typography style={{ color: theme.palette.primary.main }}
                    variant="body1">{formState.message}</Typography>
                }
              </Box>
              <Box className={classes.boxItem}>
                <IconButton disabled={disableButton()} type="submit" color="primary" aria-label="menu"
                  className={classes.draftsIcon}>
                  <DraftsIcon/>
                </IconButton>
              </Box>
              <LogInSignUpLinks setOpenModal={props.setOpenModal} hide={'signup'}/>

            </Box>
          </form>
        )}
      </Formik>
    </Container>
  )
}

export default SignupDialog
