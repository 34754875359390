import React from 'react'
import { useRouteMatch } from 'react-router'
import { usePaginatedTable } from '../../../components/PaginationTable'
import TransactionHistoryTable from './TransactionHistoryTable'
import { Container, Paper } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import FadeInUp from '../../../views/FadeInUp'

const useStyles = makeStyles((theme) => ({
  transactionsContainer: {
    marginBottom: theme.spacing(2)
  }
}))

function TransactionHistories() {
  const match = useRouteMatch()
  const classes = useStyles()

  const tableState = usePaginatedTable({
    match,
    filterSlugs: {
      kiosk: 'kiosk',
      accountCode: 'accountCode',
      address1: 'address1',
      city: 'city',
    },
    defaultSortColumn: 'online',
    defaultPageSize: 25,
    orderDesc: true
  })
  return (<FadeInUp>
    <Container className={classes.transactionsContainer}>
      <Paper>
        <TransactionHistoryTable
          tableState={tableState}
        />
      </Paper>
    </Container>
  </FadeInUp>)
}

export default TransactionHistories
