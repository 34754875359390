import React from 'react'
import MyxImageGridCard from '../../../components/MyxImageGridCard'
import MyxSign from '../../../../assets/graphics/brick-bottle-1200x1200.jpg'

function MyxInTheNewsTop() {
  return (
    <MyxImageGridCard
      mediaTitle="MYX IN THE NEWS"
      mediaSrc={MyxSign}
      title="MYX IN THE NEWS"
      subTitle=""
      color="primary"
      subText="Bob Tullio’s Product Perspective: A Closer Look At Products, Equipment And Services That Will Impact An Operator’s Business"
    />)
}

export default MyxInTheNewsTop
