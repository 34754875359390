import React from 'react'
import { Route, Switch } from 'react-router'
import HomePage from './modules/pages/home/HomePage'
import AppAppBar from './modules/views/AppAppBar'
import { BrowserRouter as Router } from 'react-router-dom'
import HowItWorks from './modules/pages/how-it-works/HowItWorks'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import theme from './modules/theme'
import IngredientsBenefits from './modules/pages/ingredients-benefits/IngredientsBenefits'
import MyxFooter from './modules/views/MyxFooter'
import CovidBanner from './modules/components/CovidBanner'
import FeaturedMyxs from './modules/pages/featured-myx/FeaturedMyxs'
import Operators from './modules/pages/operators/Operators'
import { WindowSizeProvider } from './state/WindowSizeProvider'
import Connect from './modules/pages/connect/Connect'
import { TransitionGroup } from 'react-transition-group'
import { ModalProvider } from 'react-modal-hook'
import AccountVerify from './modules/pages/account-verify/AccountVerify'
import MyAccount from './modules/pages/my-account/MyAccount'
import sessionContext from './state/sessionContext'
import SignOut from './modules/pages/signout/SignOut'
import MyxInTheNews from './modules/pages/myx-in-the-news/MyxInTheNews'
import MyTransactions from './modules/pages/my-transactions/MyTransactions'
import ForgotPasswordDialog from './modules/components/dialogs/ForgotPasswordDialog'
import ForgotPasswordWrapper from './modules/components/ForgotPasswordWrapper'

import QrLogin from './modules/components/QrLogin'
import ExternalRedirect from './modules/components/ExternalRedirect'

const AppRoutes = React.memo(() => {

  return (
    <ForgotPasswordWrapper>
      <Switch>
        <Route exact path="/" component={HomePage}/>
        <Route path="/how-it-works" component={HowItWorks}/>
        <Route path="/ingredients-benefits" component={IngredientsBenefits}/>
        <Route path="/ingredient" component={IngredientsBenefits}/>
        <Route path="/featured-myx/" component={FeaturedMyxs}/>
        <Route path="/operators/" component={Operators}/>
        <Route path="/connect/" component={Connect}/>
        <Route path="/account-verify" component={AccountVerify}/>
        <Route path="/my-account" component={MyAccount}/>
        <Route path="/my-transactions" component={MyTransactions}/>
        <Route path="/sign-out" component={SignOut}/>
        <Route path="/myx-in-the-news" component={MyxInTheNews}/>
        <Route path="#forgot-password" component={ForgotPasswordDialog}/>
        <Route path="/kiosk-log-in" component={QrLogin}/>

        <Route
          path="/kiosk/service/e430/installmanual/doc"
          render={() =>
            <ExternalRedirect
              to="https://drive.google.com/file/d/1dLusndSLU4UZbWxVK6zgBsI1E5hSyHMF/view?usp=sharing"
            />
          }/>
      </Switch>
    </ForgotPasswordWrapper>
  )
})

const appTheme = createTheme(theme)

function App() {

  return (
    <div data-testid={'mui-theme-provider'}>
      <MuiThemeProvider theme={appTheme}>
        <sessionContext.SessionProvider>
          <WindowSizeProvider>
            <Router>
              <ModalProvider rootComponent={TransitionGroup}>
                <AppAppBar/>
                <CovidBanner/>
                <AppRoutes/>
                <MyxFooter/>
              </ModalProvider>
            </Router>
          </WindowSizeProvider>
        </sessionContext.SessionProvider>
      </MuiThemeProvider>
    </div>
  )
}

export default App
