import React from 'react'
import { Grid, Link, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  banner: {
    display: 'flex',
    justifyContent: 'center',
    color: 'white',
    position: 'relative',
    width: '100%',
    backgroundColor: theme.palette.secondary.main,
    padding: '8px'
  },
  text: {
    color: 'white',
    textAlign: 'center',
    textTransform: 'capitalize'
  },
  underline: {
    textDecoration: 'underline'
  }
}))

const CovidBanner = (props) => {
  const theme = useTheme()

  const classes = useStyles(theme)

  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Link
      style={{ textDecoration: 'none' }}
      className={classes.whiteHoverPrimary}
      href={'/connect'}>
      <Grid container className={classes.banner}>
        <Grid item xs={12}>
          <Typography variant={isMobile ? 'body2' : 'h6'} className={classes.text}>
            Help Prevent COVID-19 With Touchless Pouring By MYX!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={isMobile ? 'body2' : 'h6'} className={classes.text}>
            <span className={classes.underline}>Contact Us</span> Today For More Information.
          </Typography>
        </Grid>
      </Grid>
    </Link>
  )
}

export default CovidBanner
