import React from 'react'
import { Container, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import FadeInUp from '../../../views/FadeInUp'
import MyxUnderline from '../../../components/MyxUnderline'

const styles = makeStyles((theme) => ({
  mindfulTypography: {
    maxWidth: 860,
    padding: '0 12px',
    textAlign: 'center',
    paddingBottom: '50px',
    paddingTop: '50px',
  },
}))

function EnjoyMindfulHydrationBar(props) {
  const classes = styles()
  return (
    <FadeInUp>
      <Container className={classes.mindfulTypography}>
        <Typography variant="h3" color="primary">
      Enjoy Mindful Hydration That is Tailored to your Taste
        </Typography>
        <MyxUnderline color={'primary'}/>
      </Container>
    </FadeInUp>
  )
}

export default EnjoyMindfulHydrationBar
