import React from 'react'
import { useMediaQuery, useTheme } from '@material-ui/core'
import MobileAppBar from './MobileAppBar'
import DesktopAppBar from './DesktopAppBar'

function AppAppBar() {

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return isMobile ? <MobileAppBar /> : <DesktopAppBar />
}

export default AppAppBar
