import React from 'react'
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import DataTable from '../../../components/DataTable'
import { getTableFetchParams, graphQLFetch } from '../../../../backend'
import { DateTime } from 'luxon'

function TransactionHistoryTable(props) {

  const tableState = props.tableState

  return (<DataTable
    tableState={tableState}
    dataSource={() =>
      graphQLFetch(`
        query($limit: Long!, $offset: Long!) {
            getUserDrinkHistory(
                map: [],
                limit: $limit,
                offset: $offset
            ) {
                total
                userDrinks {
                   transactionId
                   kioskId
                   kioskName
                   drinkId
                   userId
                   size
                   transactionOn
                }
            }
        }
      `,
      getTableFetchParams(
        tableState,
        {}
      )).then(({ getUserDrinkHistory: { total, userDrinks: data } }) =>
        ({ total, data })
      )
    }
  >
    {(data, sub) => (<>
      <colgroup>
        <col style={{ width: '25%' }}/>
        <col style={{ width: '25%' }}/>
        <col style={{ width: '25%' }}/>
        <col style={{ width: '25%' }}/>
      </colgroup>
      <TableHead>
        <TableRow>
          <TableCell>Transaction Id</TableCell>
          <TableCell>Date</TableCell>
          <TableCell>Location</TableCell>
          <TableCell>Type</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map(row => (
          <TableRow key={row.transactionId}>
            <TableCell>
              {row.transactionId}
            </TableCell>
            <TableCell>
              {DateTime.fromISO(row.transactionOn).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
            </TableCell>
            <TableCell>
              {row.kioskName}
            </TableCell>
            <TableCell>
              Kiosk Dispense
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </>)
    }
  </DataTable>)
}

export default TransactionHistoryTable
