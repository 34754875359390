import React from 'react'
import sessionContext from '../../../state/sessionContext'
import * as locationHelper from '../../../helpers/locationHelper'
import MyxProgressSpinner from '../../components/MyxProgressSpinner'

function SignOut() {
  const session = sessionContext.useSessionContext()

  const history = locationHelper.getRouterUseHistory()
  React.useEffect(() => {
    if (session.user) {
      session.logOut()
    } else {
      history.push('/my-account')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ session ])

  return <MyxProgressSpinner/>
}

export default SignOut
