import PeachMangoLemonade from '../../../../assets/graphics/featured-myxes/lemonade/MYX-Featured-LemonadePeachMango-1-300x380.jpg'
import StrawberryLemonade from '../../../../assets/graphics/featured-myxes/lemonade/MYX-Featured-LemonadeStraw-1-300x380.jpg'
import StrawberryMangoLemonade from '../../../../assets/graphics/featured-myxes/lemonade/MYX-Featured-LemonadeMangoStraw-1-300x380.jpg'
import LemonTea from '../../../../assets/graphics/featured-myxes/lemonade/MYX-Featured-TeaLemon-1-300x380.jpg'

export const lemonadeImgs = [
  {
    img: PeachMangoLemonade,
    title: 'Peach Mango'
  },
  {
    img: StrawberryLemonade,
    title: 'Strawberry Lemonade'
  },
  {
    img: StrawberryMangoLemonade,
    title: 'Strawberry Mango Lemonade'
  },
  {
    img: LemonTea,
    title: 'Tea & Lemonade'
  }
]
