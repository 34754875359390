import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const styles = makeStyles((theme) => ({
  container: {
    maxWidth: '800px',
    margin: `0 auto ${theme.spacing(8)}px auto`,
  }
}))

function QAndA(props) {
  const classes = styles()
  return (
    <Box className={classes.container}>
      <Typography variant="h6">{props.question}</Typography>
      <Typography variant="body1">{props.answer}</Typography>
    </Box>
  )
}

export default QAndA
