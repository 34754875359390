import React from 'react'
import * as backend from '../backend'
import * as userHelper from '../helpers/userHelper'

const sessionContext = React.createContext(null)

function useSessionContext() {
  const session = React.useContext(sessionContext)
  // fail-fast since this is used in places that render only after session loads
  if (!session) {
    throw new Error('Session context must be used in a Provider')
  }
  return session
}

function SessionProvider(props) {

  const [ user, setUser ] = React.useState(null)
  const [ kioskRoles, setKioskRoles ] = React.useState([])

  function setUserState() {
    if (localStorage.getItem(backend.STORAGE_ACCESS_TOKEN) !== null) {
      userHelper.fetchSession()
        .then(response => {
          setUser(response)
        })
        .catch(() => {
          setUser(null)
        })

      userHelper.fetchKioskRoles()
        .then(response => {
          setKioskRoles(response)
        })
        .catch(() => {
          setKioskRoles([])
        })
    }
  }

  /**
   * run only on first render
   */
  React.useEffect(() => {
    setUserState()
  }, [])

  const logOut = () => {
    setUser(null)
    localStorage.clear()
  }

  const logIn = (email, password) => userHelper.signIn(email, password)
    .then(() => setUserState())

  return (
    <sessionContext.Provider
      value={{ user: user, logOut: logOut, logIn: logIn, refreshUserState: setUserState, kioskRoles: kioskRoles }}
      {...props}
    />

  )
}

// eslint-disable-next-line import/no-anonymous-default-export
export default { SessionProvider, useSessionContext }

