import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Formik } from 'formik'
import { Box, Container, IconButton, TextField, Typography, useTheme } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { ReactComponent as MyxDroplet } from '../../../assets/graphics/logo-droplet.svg'
import MyxFormikWrapper from '../MyxFormikWrapper'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import PropTypes from 'prop-types'
import LogInSignUpLinks from '../LogInSignUpLinks'
import RequestResultGrid from '../RequestResultGrid'
import * as Yup from 'yup'
import { PASSWORD_VALIDATION_ERROR_MSG } from '../../../helpers/passwordHelper'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    margin: `${theme.spacing(1)}px auto`,
    maxWidth: '400px'
  },
  boxContainer: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    margin: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    maxWidth: '400px'
  },
  boxItem: {
    marginTop: theme.spacing(0.5)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  lockButton: {
    margin: '0 auto',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    }
  }
}))

const FormSchema = Yup.object().shape({
  email: Yup.string().email('Email is invalid')
})

function LoginDialog({ onClose, setOpenModal, logIn }) {
  const theme = useTheme()
  const classes = useStyles(theme)
  const [ formState, setFormState ] = React.useState({ errors: [], message: undefined })

  return (
    <Container
      data-testid={'login-dialog'}
      className={classes.container}>
      <Formik
        validationSchema={FormSchema}
        initialValues={{
          username: undefined,
          password: undefined,
        }}
        onSubmit={(value) => {
          logIn(value)
            .catch((error) => {
              setFormState({ errors: [ { code: '', message: 'Invalid username or password' } ], message: undefined })
            })
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form id="login-form" onSubmit={handleSubmit}>
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon/>
            </IconButton>
            <Box className={classes.boxContainer}>
              <Box className={classes.boxItem}>
                <MyxDroplet/>
              </Box>
              <Box className={classes.boxItem}>
                <Typography variant="h4">
                  Login Now
                </Typography>
              </Box>
              <Box className={classes.boxItem}>
                <Typography variant="body1">
                  Log In and save your favourite drinks & view past transaction history.
                </Typography>
              </Box>
              <Box className={classes.boxItem}>
                <MyxFormikWrapper
                  name="email"
                  label="Email"
                  margin="normal"
                  variant="outlined"
                  errors={errors}
                  touched={touched}
                >
                  <TextField/>
                </MyxFormikWrapper>
              </Box>
              <Box className={classes.boxItem}>
                <MyxFormikWrapper
                  name="password"
                  type="password"
                  label="Password"
                  margin="normal"
                  variant="outlined"
                  errors={errors}
                  touched={touched}
                >
                  <TextField/>
                </MyxFormikWrapper>
              </Box>
              <Box className={classes.boxItem}>
                <IconButton
                  data-testid={'login-dialog-log-in-btn'}
                  type="submit"
                  edge="start"
                  className={classes.lockButton}
                  color="primary"
                  aria-label="menu">
                  <LockOpenIcon/>
                </IconButton>
              </Box>
              <RequestResultGrid
                message={formState.message}
                errors={formState.errors}
              />
            </Box>
            {formState.errors && formState.errors.length > 0 &&
              <Box style={{
                paddingRight: theme.spacing(1),
                paddingLeft: theme.spacing(1),
              }}>
                <Typography variant={'body2'}>
                  {PASSWORD_VALIDATION_ERROR_MSG}
                </Typography>
              </Box>
            }

          </form>)}
      </Formik>

      <LogInSignUpLinks hide={'login'} setOpenModal={setOpenModal}/>

    </Container>
  )
}

LoginDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  setOpenModal: PropTypes.func,
  logIn: PropTypes.func.isRequired
}

export default LoginDialog
