import React from 'react'
import sessionContext from '../../state/sessionContext'
import EmailPreferencesView from './EmailPreferencesView'
import { updateAlertPreferences } from '../../helpers/userHelper'

const EmailPreferences = () => {

  // eslint-disable-next-line no-unused-vars
  const { user, logOut, logIn, refreshUserState, kioskRoles } = sessionContext.useSessionContext()

  return (
    <EmailPreferencesView
      roles={[ ...user.roleDetails, ...kioskRoles ].map(it => it.roleCode)}
      serviceAlert ={user.serviceAlert}
      inventoryAlerts={user.inventoryAlert}
      onPressServiceAlerts={() => {
        updateAlertPreferences(!user.serviceAlert, user.inventoryAlert)
          .then(() => {
            refreshUserState()
          })
      }}
      onPressInventoryAlerts={() => {
        updateAlertPreferences(user.serviceAlert, !user.inventoryAlert)
          .then(() => {
            refreshUserState()
          })
      }}
    />
  )
}

export default EmailPreferences
