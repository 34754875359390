import React from 'react'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import FadeInUp from '../../../views/FadeInUp'
import MyxUnderline from '../../../components/MyxUnderline'

const styles = (theme) => ({
  typographyTertiary: {
    color: theme.palette.tertiary.main
  },
  underline: {
    width: '30px',
    color: theme.palette.tertiary.main,
    margin: theme.spacing(4)
  },
  para: {
    maxWidth: '800px',
    width: '100%',
    margin: '0 auto 40px auto',
  },
  mindfulContainer: {
    textAlign: 'center',
    paddingTop: '60px',
    paddingBottom: '60px',
  }
})

const useStyles = makeStyles(styles)

function MindfulAndSustainable() {
  const classes = useStyles()

  return (<FadeInUp>
    <Container className={classes.mindfulContainer}>
      <Typography variant="h3" className={classes.typographyTertiary}>Mindful & Sustainable Ingredients</Typography>
      <MyxUnderline color={'tertiary'}/>
      <Typography className={classes.para}>
        We are committed to using the best natural ingredients available.
        Customizing your MYX begins by selecting a drink base, then adding your chosen levels of natural fruit splashes.
        Finally, finish your custom beverage off by adding one of our supplements.
      </Typography>
    </Container>
  </FadeInUp>)
}

export default MindfulAndSustainable
