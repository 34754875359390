import React from 'react'
import sessionContext from '../../../state/sessionContext'
import MyxMasterPass from '../../components/MyxMasterPass'
import AccountNavButtonGroup, { links } from '../../components/AccountNavButtonGroup'
import MyxProgressSpinner from '../../components/MyxProgressSpinner'
import TransactionHistories from './components/TransactionHistories'

function MyTransactions() {

  const session = sessionContext.useSessionContext()

  return (<>
    { session.user
      ? <>
        <MyxMasterPass user={session.user}/>
        <AccountNavButtonGroup selectedLink={links.myTransactions}/>
        <TransactionHistories/>
      </>
      : <MyxProgressSpinner/>
    }
  </>)
}

export default MyTransactions
