import React from 'react'
import MyxImageGridCard from '../../../components/MyxImageGridCard'
import BrickGlass from '../../../../assets/graphics/how-it-works/brick-glass-1200x1200.jpg'

function DiscoverOurNaturalBeverageSystem() {
  return <MyxImageGridCard
    showDownArrow={true}
    mediaSrc={BrickGlass}
    mediaTitle="Drink"
    color="primary"
    title="Discover Our Natural and revolutionary beverage system"
    subTitle="How It Works"
  />
}

export default DiscoverOurNaturalBeverageSystem
