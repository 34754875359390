import React from 'react'
import { Checkbox, Grid, Typography } from '@material-ui/core'

const EmailPreferencesView = ({ roles, serviceAlert, inventoryAlerts, onPressServiceAlerts, onPressInventoryAlerts }) => {

  if (!roles || !roles.find(it => it !== 'consumer')) {
    return null
  }

  return (
    <Grid container justifyContent={'center'} alignItems={'center'} >
      <Grid item xs={12}>
        <Typography variant={'h4'}>Email Preferences</Typography>
      </Grid>
      <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
        <Typography >Service Alerts</Typography>
      </Grid>
      <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flexStart', alignContent: 'center' }}>
        <Checkbox onClick={onPressServiceAlerts} checked ={serviceAlert}/>
      </Grid>
      <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
        <Typography >Inventory Alerts</Typography>
      </Grid>
      <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flexStart', alignContent: 'center' }}>
        <Checkbox onClick={onPressInventoryAlerts} checked={inventoryAlerts}/>
      </Grid>
    </Grid>
  )
}

export default EmailPreferencesView
