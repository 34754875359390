import React from 'react'
import { useModal } from 'react-modal-hook'
import AccountDialogs from './AccountModalDialog'
import sessionContext from '../../state/sessionContext'
import { Dialog } from '@material-ui/core'
import * as locationHelper from '../../helpers/locationHelper'

function ForgotPasswordWrapper(props) {
  const history = locationHelper.getRouterUseHistory()
  const session = sessionContext.useSessionContext()
  const [ showModal, hideModal ] = useModal(({ onExited }) =>
    <Dialog open={true} onClose={hideModal} onTransitionEnd={onExited}>
      <AccountDialogs startingDialog="forgotpassword" hideModal={hideModal} sessionContext={session}/>
    </Dialog>
  )

  React.useEffect(() => {
    if (history.location.hash === '#forgot-password') {
      showModal()
    }
  }, [ history.location.hash, showModal ])

  return props.children

}

export default ForgotPasswordWrapper
