import React from 'react'
import { Container, Grid, MenuItem, TextField, Typography, useTheme } from '@material-ui/core'
import MyxRoundedButton from '../../../components/MyxRoundedButton'
import MyxSelectInput from '../../../components/MyxSelectInput'
import MyxStateSelect from '../../../components/MyxStateSelect'
import MyxCountrySelect from '../../../components/MyxCountrySelect'
import MyxUnderline from '../../../components/MyxUnderline'
import { Formik } from 'formik'
import MyxFormikWrapper from '../../../components/MyxFormikWrapper'
import { postInquiry } from '../../../../backend'
import * as Yup from 'yup'
import FadeInUp from '../../../views/FadeInUp'
import RequestResultGrid from '../../../components/RequestResultGrid'
import { makeStyles } from '@material-ui/styles'

const StyledInputField = (props) => (<MyxFormGrid>
  <MyxFormikWrapper {...props}>
    {props.children}
  </MyxFormikWrapper>
</MyxFormGrid>)

const StyledTextField = (props) => (<StyledInputField {...props}>
  <TextField/>
</StyledInputField>)

const MyxFormGrid = (props) => <Grid item xs={12} sm={6} >
  {props.children}
</Grid>

const NumberOfLocationsInput = (props) => (<MyxSelectInput
  label="Number of Locations"
  variant="outlined"
  margin="none"
  defaultValue="single"
  fullWidth
  {...props}
>
  <MenuItem key="single" value="single">Single Location</MenuItem>
  <MenuItem key="multiple" value="multiple">Multiple Locations</MenuItem>
</MyxSelectInput>)

const NumberOfEmployeesInput = (props) => (<MyxSelectInput
  label="Number of Employees"
  variant="outlined"
  margin="none"
  defaultValue="0-19"
  fullWidth
  {...props}
>
  <MenuItem key="0-19" value="0-19">0-19</MenuItem>
  <MenuItem key="20-49" value="20-49">20-49</MenuItem>
  <MenuItem key="50-99" value="50-99">50-99</MenuItem>
  <MenuItem key="100-499" value="100-499">100-499</MenuItem>
  <MenuItem key="500+" value="500+">500+</MenuItem>
</MyxSelectInput>)

const FormSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(45, 'First Name must be less than 45 characters'),
  lastName: Yup.string()
    .max(45, 'Last Name must be less than 45 characters'),
  message: Yup.string()
    .max(2000, 'Message must be less than 2000 characters'),
  email: Yup.string()
    .email('Invalid Email')
    .required('Email is required'),
  phone: Yup.string()
    .matches(/[0-9]+$/, { message: 'Phone must be a number' })
    .max(15, 'Phone must be less than 15 characters'),
  company: Yup.string()
    .max(45, 'Company must be less than 45 characters'),
  title: Yup.string()
    .max(45, 'Title must be less than 45 characters'),
  address: Yup.string()
    .max(45, 'Address must be less than 45 characters'),
  city: Yup.string()
    .max(45, 'City must be less than 45 characters'),
  zip: Yup.string()
    .matches(/[0-9]+$/, { message: 'Zip code must be a number' })
    .max(10, 'Zip code must be less than 10 characters'),
  unitNumber: Yup.string()
    .matches(/[0-9]+$/, { message: 'Units must be a number' })
    .max(10, 'City must be less than 10 characters'),

})

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    maxWidth: '90%',
    clear: 'both',
    margin: `${theme.spacing(3)}px auto`,
  },

}))
function OperatorOrderForm() {
  const [ formState, setFormState ] = React.useState({ errors: [], message: undefined, submitted: false })
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <FadeInUp>
      <Container className={classes.container} justifyContent="center" >
        <Typography variant="h4">
          Order Now!
        </Typography>
        <MyxUnderline color="primary"/>
        <Typography variant="body1" style={{ marginBottom: theme.spacing(1) }}>
          Fill out the form below to get started!
        </Typography>
        <Formik
          initialValues={{
            'country': 'USA',
            'state': '',
            'sitePopulation': '0-19',
            'operatorNumberLocations': 'single',
            'email': undefined,
          }}
          validationSchema={FormSchema}
          onSubmit={(values => {
            postInquiry(values)
              .then(() => {
                setFormState({ errors: [], message: 'Thank you for your inquiry!', submitted: true })
              }).catch((error) => {
                error.jsonPromise.then((res) => {
                  setFormState({ errors: res.errors, message: undefined })
                }).catch((ex) => setFormState({
                  errors: [ { code: '', message: 'Sorry, we couldn\'t process your request.' } ],
                  message: error.toString() }))
              })
          })}
        >
          {({

            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,

          }) => (
            <form id="operator-order" onSubmit={((event) => handleSubmit(event))}>
              <Grid container spacing={4}>
                <StyledTextField
                  name="firstName"
                  label="First Name"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  errors={errors}
                  touched={touched}
                />

                <StyledTextField
                  name="lastName"
                  label="Last Name"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  errors={errors}
                  touched={touched}
                />

                <StyledTextField
                  name="email"
                  label="Your Email"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  errors={errors}
                  touched={touched}
                />
                <StyledTextField
                  name="phone"
                  label="Phone Number"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  errors={errors}
                  touched={touched}
                />
                <StyledTextField
                  name="company"
                  label="Company Name"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  errors={errors}
                  touched={touched}
                />

                <StyledTextField
                  name="title"
                  label="Title/Role"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  errors={errors}
                  touched={touched}
                />

                <StyledTextField
                  name="address"
                  label="Address"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  errors={errors}
                  touched={touched}
                />
                <StyledTextField
                  name="city"
                  label="City"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  errors={errors}
                  touched={touched}
                />
                <StyledInputField name="state">
                  <MyxStateSelect
                    label="State"
                    variant="outlined"
                    margin="none"
                    defaultValue={''}
                    fullWidth
                  />
                </StyledInputField>
                <StyledTextField
                  name="zip"
                  label="Zip/Postal Code"
                  margin="none"
                  fullWidth
                  variant="outlined"
                  errors={errors}
                  touched={touched}
                />
                <StyledInputField name="country">
                  <MyxCountrySelect
                    label="Country"
                    variant="outlined"
                    margin="none"
                    defaultValue="USA"
                    errors={errors}
                    touched={touched}
                    fullWidth
                  />
                </StyledInputField>
                <StyledInputField name="sitePopulation">
                  <NumberOfEmployeesInput/>
                </StyledInputField>

                <StyledInputField name="operatorNumberLocations">
                  <NumberOfLocationsInput/>
                </StyledInputField>

                <StyledTextField
                  name="unitNumber"
                  label="Number Units"
                  placeholder="Number of Requested Units"
                  fullWidth
                  margin="none"
                  variant="outlined"
                  errors={errors}
                  touched={touched}
                />
                {
                  formState.message || formState.errors.length > 0
                    ? <RequestResultGrid errors={formState.errors} message={formState.message} /> : null
                }
              </Grid>
              <MyxRoundedButton type="submit"
                disabled={Object.keys(touched).length < 1 || formState.submitted}
                onClick={() => {
                  if (Object.keys(errors).length > 0 || Object.keys(touched).length < 1) {
                    setFormState({ errors: [ { code: 'FORM_ERRORS', message: 'Please correct the errors above and resubmit' } ] })
                  }
                }}
                style={{ marginTop: '5%', marginBottom: '5%' }}>
            Submit
              </MyxRoundedButton>
            </form>)
          }
        </Formik>
      </Container>
    </FadeInUp>
  )
}

export default OperatorOrderForm
