import React from 'react'
import { Container, TextField, Typography } from '@material-ui/core'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Grid from '@material-ui/core/Grid'
import MyxFormikWrapper from '../../../components/MyxFormikWrapper'
import MyxRoundedButton from '../../../components/MyxRoundedButton'
import { graphQLFetch } from '../../../../backend'
import RequestResultGrid from '../../../components/RequestResultGrid'
import * as locationHelper from '../../../../helpers/locationHelper'
import sessionContext from '../../../../state/sessionContext'
import MyxProgressSpinner from '../../../components/MyxProgressSpinner'

const FormSchema = Yup.object().shape({
  email: Yup.string().email('Email is invalid')
})

function ChangeEmailForm() {
  const [ formState, setFormState ] = React.useState({ errors: [], message: undefined })
  const history = locationHelper.getRouterUseHistory()
  const session = sessionContext.useSessionContext()
  if (!session.user) {
    return <MyxProgressSpinner />
  }
  return (
    <Container data-testid={'change-email-form'}>
      <div style={{ marginBottom: '5%' }}>
        <Typography variant="h4" style={{ marginBottom: '5%' }}>
      Change Email
        </Typography>
        <Typography>
      Current email: <strong>{session.user.email}</strong>
        </Typography>
        <Typography variant="body1">
      You will be logged out on successful email change
        </Typography>
      </div>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={FormSchema}
        onSubmit={(values) => {
          graphQLFetch(`
          mutation($update: UpdateUserInput!) {
            updateMyUser(update: $update) {
              id
            }
          }
        `, {
            update: {
              email: values.email,
              givenName: session.user.givenName,
              familyName: session.user.familyName,
              address1: session.user.address1,
              city: session.user.city,
              state: session.user.state,
              zip: session.user.zip,
              phone: session.user.phone
            }
          }).then((response) => {
          // eslint-disable-next-line no-warning-comments
          // TODO sign user out on success update.
            setFormState({ errors: [], message: 'Email Updated' })
            history.push('/sign-out')
          }).catch((error) => setFormState({
            errors: [ { code: '', message: 'Sorry, we couldn\'t process your request.' } ],
            message: error.toString()
          }))
        }}
      >
        {({

          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,

        }) => (
          <form id="change-email-form" onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <MyxFormikWrapper
                  name="email"
                  placeholder="Email"
                  label="Email"
                  variant="outlined"
                  margin="none"
                  fullWidth
                  errors={errors}
                  touched={touched}
                >
                  <TextField/>
                </MyxFormikWrapper>
              </Grid>
              {
                formState.message || formState.errors.length > 0
                  ? <RequestResultGrid errors={formState.errors} message={formState.message} /> : null
              }
            </Grid>
            <MyxRoundedButton type="submit" style={{
              marginTop: '5%',
              marginBottom: '5%',
            }}>
            Save Changes
            </MyxRoundedButton>
          </form>
        )
        }
      </Formik>
    </Container>)
}

export default ChangeEmailForm
