import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  underline: {
    height: '1px',
    backgroundColor: theme.palette.primary.main,
    width: '30px',
    display: 'inline-block',
    margin: '25px 0'
  },
  underlineTertiary: {
    backgroundColor: theme.palette.tertiary.main,
  },
}))

function MyxUnderline({ color }) {
  const tertiaryColor = color === 'tertiary'
  const classes = useStyles()

  return <div className={classes.underline + (tertiaryColor ? (' ' + classes.underlineTertiary) : '')}/>
}

export default MyxUnderline
