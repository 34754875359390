import React from 'react'
import EnjoyMillionsOfRefreshingMyxesForYou from './components/EnjoyMillionsOfRefreshingMyxesForYou'
import MindfulAndSustainable from './components/MindfulAndSustainable'
import JoinTheMyxMovement from './components/JoinTheMyxMovement'
import IngredientPoints from './components/IngredientPoints'
import OurNaturalIngredientsCarousel from './components/OurNaturalIngredientsCarousel'
import SignUpAndGetStartedForFree from './components/SignUpAndGetStartedForFree'

const IngredientsBenefits = () => <>
  <EnjoyMillionsOfRefreshingMyxesForYou/>
  <MindfulAndSustainable/>
  <JoinTheMyxMovement/>
  <IngredientPoints/>
  <OurNaturalIngredientsCarousel/>
  <SignUpAndGetStartedForFree/>
</>

export default IngredientsBenefits
