import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Container, Typography } from '@material-ui/core'
import FadeInUp from '../../../views/FadeInUp'
import MyxUnderline from '../../../components/MyxUnderline'

const useStyles = makeStyles((theme) => ({

  container: {
    maxWidth: 1040,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
  }
}))

const RevolutionizeTheBeverageIndustry = () => {
  const classes = useStyles()
  return (
    <FadeInUp>
      <Container className={classes.container}>
        <Typography variant="h4" align={'center'}>
        We Are On A Mission To Revolutionize The Beverage Industry. Be A Part Of It.
        </Typography>
        <MyxUnderline color={'primary'}/>
        <Typography variant="body1" align={'center'}>
        For more than 7 years, the MYX team has been painstakingly developing our smart, sustainable, consciously created beverage systems.
        We are committed to leading the industry with our patented technologies.
        </Typography>
      </Container>
    </FadeInUp>)
}

export default RevolutionizeTheBeverageIndustry
