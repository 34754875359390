import React from 'react'
import MyxInTheNewsTop from './components/MyxInTheNewsTop'
import { Container } from '@material-ui/core'
import BobsPerspective from './components/BobsPerspective'

function MyxInTheNews() {
  return (
    <Container>
      <MyxInTheNewsTop/>
      <BobsPerspective/>
    </Container>
  )
}

export default MyxInTheNews
