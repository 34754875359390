import React from 'react'
import ExploreOurFeaturedMyxs from './components/ExploreOurFeaturedMyxs'
import FeaturedMyxGrid from './components/FeaturedMyxGrid'

function FeaturedMyxs() {
  return (<>
    <ExploreOurFeaturedMyxs/>
    <FeaturedMyxGrid/>
  </>)
}

export default FeaturedMyxs
