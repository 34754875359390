import React from 'react'
import { Box, Container, makeStyles, Typography, useTheme } from '@material-ui/core'
import MyxDroplet from '../../../components/MyxDroplet'
import MyxLink from '../../../components/MyxLink'
import FadeInUp from '../../../views/FadeInUp'

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '90%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    margin: `${theme.spacing(10)}px auto`,

  },
  connectContent: {
    maxWidth: 660,
  }
}))

function ConnectWithMyx() {
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <FadeInUp>
      <Container className={classes.container}>
        <Box className={classes.connectContent}>
          <MyxDroplet color="primary"/>
          <Typography variant="h1" style={{ marginBottom: theme.spacing(4), color: theme.palette.myxblack }}
            align={'center'}>
            Connect With Myx Mindful Hydration
          </Typography>
          <Typography variant="body1" align={'center'}>
            Launching nationally in Spring 2019, our MYX Hydration Stations will start to pop-up throughout North
            America. If you want a MYX Hydration Station in your workplace send us an email
            at
          </Typography>
          <Box style={{ textAlign: 'center' }}>
            <MyxLink
              myxvariant="primaryHoverTertiary"
              style={{ fontSize: '16px', textTransform: 'none' }}
              href="mailto:myxmaster@myxdrinks.com">
              myxmaster@myxdrinks.com
            </MyxLink>
          </Box>
        </Box>
      </Container>
    </FadeInUp>)
}

export default ConnectWithMyx
