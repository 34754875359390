import React from 'react'
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/styles'
import FadeInUp from './FadeInUp'

const styles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    position: 'relative',
  },
}))

function ProductHeroLayout(props) {
  const classes = styles()
  const { children } = props

  return (
    <FadeInUp>
      <section className={classes.root}>
        <Container maxWidth={false} className={classes.container + (props.className ? (' ' + props.className) : '')}>
          {children}
        </Container>
      </section>
    </FadeInUp>
  )
}

ProductHeroLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ProductHeroLayout
