import { fruitImgs } from './fruitImgs'
import { lemonadeImgs } from './lemonadeImgs'
import { sparklingImgs } from './sparklingImgs'
import { teaImgs } from './teaImgs'
import { zeroCalImgs } from './zeroCalImgs'

export const featuredImgs = [
  ...fruitImgs,
  ...lemonadeImgs,
  ...sparklingImgs,
  ...teaImgs,
  ...zeroCalImgs
]

/*
    To Do Add more under the "Show More" button
 */
