import React from 'react'
import { Container, Grid, TextField, Typography, useTheme } from '@material-ui/core'
import { Formik } from 'formik'
import * as Yup from 'yup'
import MyxFormikWrapper from '../../../components/MyxFormikWrapper'
import MyxStateSelect from '../../../components/MyxStateSelect'
import MyxRoundedButton from '../../../components/MyxRoundedButton'
import RequestResultGrid from '../../../components/RequestResultGrid'
import * as userHelper from '../../../../helpers/userHelper'
import sessionContext from '../../../../state/sessionContext'
import MyxProgressSpinner from '../../../components/MyxProgressSpinner'

const FormSchema = Yup.object().shape({
  givenName: Yup.string().max(45, 'First Name must be less than 45 characters'),
  lastName: Yup.string().max(45, 'Last Name must be less than 45 characters'),
  phoneNumber: Yup.string().max(15, 'Phone number must be less than 15 characters')
    .min(10, 'Phone number must be at least 10 characters')
    .matches(/[0-9]+$/, { message: 'Phone must be a number' }),
  address: Yup.string(),
  city: Yup.string(),
  state: Yup.string()
    .min(2, 'Please pick from the drop down')
    .max(2, 'Please pick from the drop down'),
  zip: Yup.string().required()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(5, 'Must be exactly 5 digits')
    .max(5, 'Must be exactly 5 digits'),
})

function AccountInformationForm() {
  const theme = useTheme()
  const session = sessionContext.useSessionContext()

  const [ formState, setFormState ] = React.useState({ errors: [], message: undefined })
  if (!session.user) {
    return <MyxProgressSpinner />
  }
  return (
    <Container data-testid={'account-information-form'} style={{ padding: theme.spacing(2) }}>
      <Typography variant="h4" style={{ marginBottom: theme.spacing(3) }}>
      Account Information
      </Typography>
      <Formik
        initialValues={{
          ...session.user
        }}
        validationSchema={FormSchema}
        onSubmit={(values) => {
          userHelper.updateUser(values)
            .then((response) => {
              setFormState({ errors: [], message: 'Account Updated' })

            }).catch((error) => setFormState({
              errors: [ { code: '', message: 'Sorry, we couldn\'t process your request.' } ],
              message: error ? error.toString() : 'Unknown error occurred'
            }))
            .finally(() => session.refreshUserState())
        }}
      >
        {({

          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,

        }) => (
          <form id="update-account-form" onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <MyxFormikWrapper
                  name="givenName"
                  placeholder="First Name"
                  label="First Name"
                  variant="outlined"
                  margin="none"
                  fullWidth
                  errors={errors}
                  touched={touched}
                >
                  <TextField inputProps={{ 'data-testid': 'account-info-form-given-name' }}/>
                </MyxFormikWrapper>
              </Grid>
              <Grid item xs={6}>
                <MyxFormikWrapper

                  name="familyName"
                  placeholder="Last Name"
                  label="Last Name"
                  variant="outlined"
                  margin="none"
                  fullWidth
                  errors={errors}
                  touched={touched}
                >
                  <TextField inputProps={{ 'data-testid': 'account-info-form-family-name' }}/>
                </MyxFormikWrapper>
              </Grid>
              <Grid item xs={6}>
                <MyxFormikWrapper
                  name="phone"
                  label="Phone"
                  placeholder="Phone Number"
                  variant="outlined"
                  margin="none"
                  fullWidth
                  errors={errors}
                  touched={touched}
                >
                  <TextField inputProps={{ 'data-testid': 'account-info-form-phone' }}/>
                </MyxFormikWrapper>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                * Requires A 10 Digit Number, No Special Characters.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <MyxFormikWrapper

                  name="address1"
                  label="Address"
                  placeholder="Address"
                  variant="outlined"
                  margin="none"
                  fullWidth
                  errors={errors}
                  touched={touched}
                >
                  <TextField inputProps={{ 'data-testid': 'account-info-form-address' }}/>
                </MyxFormikWrapper>
              </Grid>
              <Grid item xs={6}>
                <MyxFormikWrapper
                  name="city"
                  label="City"
                  placeholder="City"
                  variant="outlined"
                  margin="none"
                  fullWidth
                  errors={errors}
                  touched={touched}
                >
                  <TextField inputProps={{ 'data-testid': 'account-info-form-city' }}/>
                </MyxFormikWrapper>
              </Grid>
              <Grid item xs={6}>
                <MyxFormikWrapper
                  name="state"
                  label="State"
                  placeholder="State"
                  variant="outlined"
                  margin="none"
                  fullWidth
                  errors={errors}
                  touched={touched}
                >
                  <MyxStateSelect data-testid={'account-info-form-state'}/>
                </MyxFormikWrapper>
              </Grid>
              <Grid item xs={6}>
                <MyxFormikWrapper
                  name="zip"
                  label="Zip/Postal Code"
                  placeholder="Zip/Postal Code"
                  variant="outlined"
                  margin="none"
                  fullWidth
                  errors={errors}
                  touched={touched}
                >
                  <TextField inputProps={{ 'data-testid': 'account-info-form-zip' }}/>
                </MyxFormikWrapper>
              </Grid>
              {
                formState.message || formState.errors.length > 0
                  ? <RequestResultGrid errors={formState.errors} message={formState.message} /> : null
              }
            </Grid>
            <MyxRoundedButton
              data-testid={'account-information-form-submit'}
              type="submit" style={{
                marginTop: '5%',
                marginBottom: '5%',
              }}>
            Save Changes
            </MyxRoundedButton>
          </form>
        )
        }
      </Formik>
    </Container>
  )
}

export default AccountInformationForm
