import React from 'react'
import FrequentlyAskedQuestions from '../../../components/FrequentlyAskedQuestions'
import QAndA from '../../../components/QAndA'

function HowItWorksFAQ() {
  return <FrequentlyAskedQuestions>
    <QAndA
      question="How many drink combinations can I make?"
      answer="Depending on the model you are using, MYX offers anywhere from 30-80 million different combinations."
    />
    <QAndA
      question="Do I need a membership to create a personalized combination?"
      answer="You can create your own drink right at our machine without an account. However, our APP allows you to pre-mix your drinks in advance and save them for future use."
    />
    <QAndA
      question=""
      answer=""
    />
    <QAndA
      question="Do I have to bring my own drinking container?"
      answer="Yes you do! A key goal when we were creating MYX beverage systems was to minimize our impact on the environment. Together we can make a difference."
    />
  </FrequentlyAskedQuestions>
}

export default HowItWorksFAQ
