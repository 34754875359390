import EventEmitter from 'events'
import { useEffect, useState } from 'react'
import qs from 'querystring'
import { DateTime } from 'luxon'

const env = window._env_ ? window._env_ : {}

const BASE_URL = env.BASE_URL
const AUTH_BASE = env.AUTH_URL
export const USER_RESOURCE_URL = `https://${BASE_URL}/user-resource`
export const AUTH_URL = `https://${AUTH_BASE}/consumer-auth/oauth/token`
export const CLIENT_BASIC_AUTH = '64eefcac-a01c-4619-9892-8bcf98ddfcb4:jz5DtC1IZZRlhIw0tbe2JWxU9meKEpCZrHuvNlX9IQ1ihdarQL'

export const STORAGE_ACCESS_TOKEN = 'moc_ac'
export const STORAGE_REFRESH_TOKEN = 'moc_re'

export const backendErrors = new EventEmitter()

export function postInquiry(inquiry) {
  return myxFetch('/inquiry', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(inquiry)
  })
}

export function newSignup(signup) {
  return myxFetch('/userregistration', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(signup)
  })
}

export function myxFetch(urlPart, options) {
  return fetch(`${USER_RESOURCE_URL}${urlPart}`, options)
    .then((response) => {
      if (response.ok) {
        return response.json()
      }
      return Promise.reject(new MyxBackendError('Backend Error!', response.json()))
    })
}

/* eslint-disable react-hooks/exhaustive-deps */
export function useFetch(fetchFunc) {
  const [ response, setResponse ] = useState(null)
  const [ loading, setLoading ] = useState(false)
  const [ error, setError ] = useState(false)

  useEffect(() => {
    setLoading(true)
    fetchFunc()
      .then((resp) => {
        setResponse(resp)
        setLoading(false)
        setError(false)
      })
      .catch(() => {
        setLoading(false)
        setError(true)
      })
  }, [])

  return [ response, loading, error ]
}

function accessTokenFetch() {
  return fetch(AUTH_URL, {
    method: 'post',
    headers: {
      Authorization: `Basic ${btoa(CLIENT_BASIC_AUTH)}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: [
      'grant_type=refresh_token',
      `refresh_token=${localStorage.getItem(STORAGE_REFRESH_TOKEN)}`
    ].join('&')
  }).then(response => {
    if (response.ok) {
      return response.json()
    }
    localStorage.clear()
    backendErrors.emit('authError')
    throw new Error('Please sign in')
  }).then((responseJSON) => {

    // update current session state to store the new tokens
    const accessToken = responseJSON.access_token
    const refreshToken = responseJSON.refresh_token

    localStorage.setItem(STORAGE_ACCESS_TOKEN, accessToken)
    localStorage.setItem(STORAGE_REFRESH_TOKEN, refreshToken)
  })
}

// Checks if the access token is within 10 minutes of expiring.
function isAccessTokenExpired(accessToken) {
  return ((DateTime.utc().ts / 1000) > (JSON.parse(atob(accessToken.split('.')[1])).exp - 10))
}

export function graphQLFetch(query, params) {
  const accessToken = localStorage.getItem(STORAGE_ACCESS_TOKEN)

  const querySuffix = params ? `?${qs.stringify({ variables: JSON.stringify(params) })}` : ''

  // if there is no access token at all.. throw auth error and redirect
  if (!accessToken) {
    backendErrors.emit('authError')
    throw new Error('Not signed in')
  }

  const graphQlFetchF = () => fetch(`${USER_RESOURCE_URL}/graphql${querySuffix}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/graphql',
      Authorization: `Bearer ${localStorage.getItem(STORAGE_ACCESS_TOKEN)}`
    },
    body: query
  }).then(response => {
    if (response.status === 401) {
      backendErrors.emit('authError')
      throw new Error('Not signed in')
    }

    if (!response.ok) {
      backendErrors.emit('networkError')
      throw new Error('Unexpected error')
    }

    return response.json().then(gqlResponse => {
      if (gqlResponse.errors) {
        const firstError = gqlResponse.errors[0]
        throw new Error(firstError.message || firstError.toString())
      }

      return gqlResponse.data
    })
  })

  return isAccessTokenExpired(accessToken)
    ? accessTokenFetch().then(() => graphQlFetchF())
    : graphQlFetchF()

}

/**
 * @param {import('./components/PaginatedTable').PaginatedTableState} tableState
 */
export function getTableFetchParams(tableState, fieldMap = {}) {
  // get full info about sort column or fall back
  const [ , sortGQLFieldName ] = fieldMap[tableState.sortColumn] || [ null, tableState.sortColumn ]

  const sortSpec = {
    key: tableState.sortReverse ? 'orderByDesc' : 'orderBy',
    value: sortGQLFieldName
  }

  const specList = Object.keys(fieldMap).reduce((list, filterKey) => {
    const filterValue = tableState.filter[filterKey]

    // continue if not present
    // eslint-disable-next-line no-undefined
    if (filterValue === null || filterValue === undefined) {
      return list
    }

    // switch based on field type
    const [ fieldType, gqlFieldName ] = fieldMap[filterKey]

    switch (fieldType) {
    case 'string':
      return [ ...list, { key: `CO:${gqlFieldName}`, value: filterValue } ]
    case 'id':
      return [ ...list, { key: `EQ:${gqlFieldName}`, value: filterValue } ]
    case 'dateRange': {
      const [ dateA, dateB ] = filterValue.split(';')
      const dtA = dateA && DateTime.fromISO(dateA)
      const dtB = dateB && DateTime.fromISO(dateB).plus({ days: 1 })

      // format timestamp to be Java-friendly (no colon in tz offset)
      const dateConditions = [].concat(
        dtA ? [ { key: `AF:${gqlFieldName}`, value: dtA.toFormat('yyyy-MM-dd\'T\'HH:mm:ss.SSSZZZ') } ] : []
      ).concat(
        dtB ? [ { key: `BF:${gqlFieldName}`, value: dtB.toFormat('yyyy-MM-dd\'T\'HH:mm:ss.SSSZZZ') } ] : []
      )

      return [ ...list, ...dateConditions ]
    }
    case 'dateTimeRange': {
      const [ dateA, dateB ] = filterValue.split(';')
      const dtA = dateA && DateTime.fromISO(dateA)
      const dtB = dateB && DateTime.fromISO(dateB)

      // format timestamp to be Java-friendly (no colon in tz offset)
      const dateConditions = [].concat(
        dtA ? [ { key: `AF:${gqlFieldName}`, value: encodeURI(dtA.toFormat('yyyy-MM-dd\'T\'HH:mm:ss.SSSZZZ')) } ] : []
      ).concat(
        dtB ? [ { key: `BF:${gqlFieldName}`, value: encodeURI(dtB.toFormat('yyyy-MM-dd\'T\'HH:mm:ss.SSSZZZ')) } ] : []
      )

      return [ ...list, ...dateConditions ]
    }
    default:
      throw new Error(`unknown filter field type: ${fieldType}`)
    }
  }, [ sortSpec ])

  return {
    limit: tableState.pageSize,
    offset: tableState.pageIndex * tableState.pageSize,
    map: specList
  }
}

export class MyxBackendError extends Error {

  constructor(message, jsonPromise) {
    super(message)
    this.jsonPromise = jsonPromise
  }

}
