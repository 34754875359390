import cbd from '../../../../assets/graphics/ingredients-benefits/carousel/supplements/cbd-300x300.jpg'
import electrolyte from '../../../../assets/graphics/ingredients-benefits/carousel/supplements/electrolyte-300x300.jpg'
import energy from '../../../../assets/graphics/ingredients-benefits/carousel/supplements/energy-300x300.jpg'
import immunity from '../../../../assets/graphics/ingredients-benefits/carousel/supplements/immunity-300x300.jpg'

export const supplementsCarouselImages = [ {
  img: cbd,
  title: 'CBD',
},
{ img: electrolyte,
  title: 'Electrolyte',
},
{
  img: energy,
  title: 'Energy',
},
{
  img: immunity,
  title: 'Immunity',
}
]
