import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Container from '@material-ui/core/Container'
import { Box, CardMedia, Grid, Typography } from '@material-ui/core'
import E430 from '../../../../assets/graphics/operators/MYX-Machine-5-1440-white-boarder-1200x1200.jpg'
import FadeInUp from '../../../views/FadeInUp'

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 1420,
    marginBottom: theme.spacing(10),
  },
  kioskImage: {
    maxHeight: 600,
    objectFit: 'contain',
    height: '100%',
    width: '100%'
  },
  listGridContainer: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2),
    },
  },
  dtList: {
    fontWeight: '700',
    float: 'left',
    marginRight: theme.spacing(1),
    clear: 'left',
  },
  ddList: {
    fontWeight: '300',
  }
}))

const TileContent = ({ title, bulletPoints }) => {
  const classes = useStyles()
  return (
    <Box>
      <Typography variant="subtitle2">{title}</Typography>
      {Object.keys(bulletPoints).map((key) => (
        <Box key={key} className={classes.listItem}>
          <Typography className={classes.dtList}>{key}</Typography>
          <Typography className={classes.ddList}>{bulletPoints[key]}</Typography>
        </Box>
      ))}
    </Box>
  )
}

function KioskE430Specs() {
  const classes = useStyles()

  return (
    <FadeInUp>
      <Container className={classes.container} maxWidth={false}>
        <Grid container>
          <Grid item key={1} xs={12} sm={6} >
            <CardMedia
              className={classes.kioskImage}
              src={E430}
              component="img"
              title="E430"
            />
          </Grid>
          <Grid item key={2} xs={12} sm={6}>

            <Grid container spacing={2} className={classes.listGridContainer}>
              <Grid item xs={12} >
                <TileContent
                  classes={classes}
                  title="Dimensions (Above Counter)"
                  bulletPoints={{
                    'Width:': '19"',
                    'Height:': '54" with leveling feet, 53" with roller wheels',
                    'Depth:': '23"',
                    'Ventilation rear clearance': '4" minimum',
                  }}
                />
              </Grid>

              <Grid item xs={12} >
                <TileContent
                  classes={classes}
                  title="Ingredient Capabilities & Capacities"
                  bulletPoints={{
                    'Dispenses and stores 12 ingredients in 1⁄2-Gallon boxes': null
                  }}
                />
              </Grid>

              <Grid item xs={12} >
                <TileContent
                  classes={classes}
                  title="Plumbing Requirements"
                  bulletPoints={{
                    'Potable Water Supply:': 'Maximum 100 PSI pressure, minimum 5 PSI pressure at 1 GPM flow rate',
                    'Inlet Valve Connection:': 'Standard 3/4" male thread hose',
                    'External Drain Line Connection': 'N/A',
                    'Drain Options': 'Integrated, removable drip tray',
                  }}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <TileContent
                  classes={classes}
                  title="Operating Conditions"
                  bulletPoints={{
                    'Temperature & humidity range:': '64 - 80 degrees F @ 20 - 80% RH',
                    'Indoor environment only': null,
                  }}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <TileContent
                  classes={classes}
                  title="Power Requirements"
                  bulletPoints={{
                    'Voltage:': '120V (normal outlet)',
                    'Frequency:': '50-60HZ',
                    'Current:': '5A (maximum), 120V-ac 50-60 Hz'
                  }}
                />
              </Grid>

              <Grid item xs={12} >
                <TileContent
                  classes={classes}
                  title="Internet Connections"
                  bulletPoints={{
                    'Internet access methods:': 'LAN/Ethernet or 4G Cellular Required',
                  }}
                />
              </Grid>

            </Grid>

          </Grid>
        </Grid>
      </Container>
    </FadeInUp>)
}

export default KioskE430Specs
