import React from 'react'
import { Button, ButtonGroup, MenuItem, Select, useMediaQuery, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({

  buttonGroup: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      marginBottom: theme.spacing(5),
    },
    justifyContent: 'start',
  },
  button: {
    padding: theme.spacing(1),
    borderRadius: 0,
    border: 'none',
    justifyContent: 'start',
    margin: 0,
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'white',
      borderRadius: 0,
      border: 0,
    }
  },

}))

export const forms = Object.freeze({
  account: ' Edit Account Info',
  password: 'Change Password',
  email: 'Change Email',
  emailPreference: 'Email Preferences'
})

function AccountFormLinks({ page, setForm }) {
  const _theme = useTheme()
  const classes = useStyles(_theme)
  const isMobile = useMediaQuery(_theme.breakpoints.down('md'))
  if (isMobile) {
    return (
      <Select
        value={page}
        className={classes.buttonGroup}
        onChange={value => setForm(value.target.value)}>
        <MenuItem value={forms.account}>{forms.account}</MenuItem>
        <MenuItem value={forms.password}>{forms.password}</MenuItem>
        <MenuItem value={forms.email}>{forms.email}</MenuItem>
        <MenuItem value={forms.emailPreference}>{forms.emailPreference}</MenuItem>

      </Select>
    )
  }
  return (
    <ButtonGroup className={classes.buttonGroup}>
      <Button data-testid={'account-form-button-account'} className={classes.button} onClick={() => setForm(forms.account)}>{forms.account}</Button>
      <Button data-testid={'account-form-button-password'} className={classes.button} onClick={() => setForm(forms.password)}>Change Password</Button>
      <Button data-testid={'account-form-button-email'} className={classes.button} onClick={() => setForm(forms.email)}>Change Email</Button>
      <Button data-testid={'account-form-button-email-preferences'} className={classes.button} onClick={() => setForm(forms.emailPreference)}>Email Preferences</Button>

    </ButtonGroup>
  )
}
export default AccountFormLinks
