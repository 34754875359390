import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Container, Grid, Typography } from '@material-ui/core'
import { ReactComponent as Ingredients } from '../../../../assets/graphics/MYX_FeatureIcons_HYDRATION.svg'
import { ReactComponent as PourBases } from '../../../../assets/graphics/Pour-Bases.svg'
import { ReactComponent as TechIcon } from '../../../../assets/graphics/MYX_FeatureIcons_TECH.svg'
import { ReactComponent as Accessibility } from '../../../../assets/graphics/MYX_FeatureIcons_ACCESS.svg'
import FadeInUp from '../../../views/FadeInUp'

const useStyles = makeStyles((theme) => ({
  easyStepIcon: {
    height: '100px',
    width: '100%',
    display: 'block',
    paddingBottom: '10%',
    alignContent: 'center'
  },
  cardParagraph: {
    textAlign: 'center',
    paddingBottom: '10px',
    fontSize: '14px',
  },
}))

function MyxTechnology() {
  const classes = useStyles()

  return (<FadeInUp>
    <Container style={{ marginTop: '5%', marginBottom: '5%' }}>
      <Grid container spacing={4}>
        <Grid item key={1} xs={12} sm={6} md={3}>
          <Ingredients className={classes.easyStepIcon}/>
          <Typography variant="h6" align="center">Healthy Hydration</Typography>
          <Typography variant="body1" align="center">
            Still or sparkling water, natural drinks, organic options, custom supplements –  Unlimited capabilities.
          </Typography>
        </Grid>

        <Grid item key={2} xs={12} sm={6} md={3}>
          <PourBases className={classes.easyStepIcon}/>
          <Typography variant="h6" align="center">Four Patents</Typography>
          <Typography variant="body1" align="center">
            No harmful refrigerants, precise dosing, pour from the APP, CBD oil dispensing.
          </Typography>
        </Grid>

        <Grid item key={3} xs={12} sm={6} md={3}>
          <TechIcon className={classes.easyStepIcon}/>
          <Typography variant="h6" align="center">Intelligent Technology</Typography>
          <Typography variant="body1" align="center">
            Lowest energy consumption of any cooler or vendor.
            Intelligent User Interface with backend connectivity to a custom dash board – 4G.
          </Typography>
        </Grid>

        <Grid item key={4} xs={12} sm={6} md={3}>
          <Accessibility className={classes.easyStepIcon}/>
          <Typography variant="h6" align="center">Accessibility</Typography>
          <Typography variant="body1" align="center">
            For the visually and hearing impaired, our NFC technology allows access to personal history and drink order via the APP.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  </FadeInUp>)
}

export default MyxTechnology
