import React from 'react'
import MyxImageGridCard from '../../../components/MyxImageGridCard'
import BrickMindful from '../../../../assets/graphics/featured-myxes/brick-mindful-1200x1200.jpg'

function ExploreOurFeaturedMyxs() {
  return (<MyxImageGridCard
    mediaSrc={BrickMindful}
    mediaTitle="Brick Mindful"
    color="tertiary"
    title="Explore Our Featured MYXes"
    subText="Having a hard time getting your mind around the 1 Million+ flavor combinations? If that’s the case, we have curated a few Featured MYX combinations to get you started."
    subTitle={'Featured Myx'}
  />)
}

export default ExploreOurFeaturedMyxs
