import React from 'react'
import { Container, Grid, Typography, useTheme } from '@material-ui/core'
import MyxRoundedButton from '../../../components/MyxRoundedButton'
import FadeInUp from '../../../views/FadeInUp'
import * as locationHelper from '../../../../helpers/locationHelper'
import { makeStyles } from '@material-ui/styles'

const styles = makeStyles((theme) => ({
  container: {
    maxWidth: theme.maxWidth.large,
    margin: `0 auto  ${theme.spacing(10)}px auto`,
    textAlign: 'center',
  },
}))

function BecomeAMyxPartnerToday() {
  const theme = useTheme()
  const history = locationHelper.getRouterUseHistory()
  const classes = styles(theme)
  return (
    <FadeInUp>
      <Container className={classes.container}>
        <Grid container alignItems="center" alignContent="center">
          <Grid item key={1} xs={12} md={6} >
            <Typography variant="h4" align="center" color="primary">
              Become a Myx Kiosk Partner Today
            </Typography>
          </Grid>
          <Grid item key={2} xs={12} md={6} >
            <MyxRoundedButton onClick={() => history.push('/connect')}>
              Let's Connect
            </MyxRoundedButton>
          </Grid>
        </Grid>
      </Container>
    </FadeInUp>)
}

export default BecomeAMyxPartnerToday
