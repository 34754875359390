import greenTea1 from '../../../../assets/graphics/featuredDrinks/MYX-Featured-GreenTea-1-300x380.jpg'
import teaLemonade1 from '../../../../assets/graphics/featuredDrinks/MYX-Featured-TeaLemonade-1-300x380.jpg'
import teaRas1 from '../../../../assets/graphics/featuredDrinks/MYX-Featured-TeaRas-1-300x380.jpg'
import tea1 from '../../../../assets/graphics/featuredDrinks/MYX-Featured-Tea-1-300x380.jpg'
import waterOrange1 from '../../../../assets/graphics/featuredDrinks/MYX-Featured-WaterOrange-1-300x380.jpg'

export const carouselImages = [ {
  img: greenTea1,
  title: 'Green Tea',
},
{
  img: waterOrange1,
  title: 'Orange Seltzer'
},
{
  img: tea1,
  title: 'Black Tea'
},
{
  img: teaLemonade1,
  title: 'Tea & Lemonade'
},
{
  img: teaRas1,
  title: 'Raspberry Tea'
}
]
