import React from 'react'
import { Box, Typography, useTheme } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { ReactComponent as SizeLogo } from '../../../../assets/graphics/Size.svg'
import { ReactComponent as PourBases } from '../../../../assets/graphics/Pour-Bases.svg'
import { ReactComponent as PourSave } from '../../../../assets/graphics/Pour.svg'
import { ReactComponent as Ingredients } from '../../../../assets/graphics/MYX_FeatureIcons_HYDRATION.svg'
import { makeStyles } from '@material-ui/styles'
import FadeInUp from '../../../views/FadeInUp'

const styles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(9),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  personalizedTextContainer: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(10),

    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(5),
    }
  },
  easyStepIcon: {
    height: 200,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center'
  },
  personalizedGridCard: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItem: 'center'
  },
  circle: {
    position: 'absolute',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    borderBottomLeftRadius: '50%',
    borderBottomRightRadius: '50%',
    borderTopLeftRadius: '50%',
    borderTopRightRadius: '50%',
    width: 46,
    cursor: 'auto',
    fontStyle: 'normal',
    fontWeight: 400,
    height: '46px',
    top: 0,
    left: 0,
    lineHeight: '46px',
    lineStyleImage: 'none',
    lineStylePosition: 'outside',
    lineStyleType: 'none',
    textAlign: 'center',
  },
}))

const PersonalizedGridCard = (props) => {
  const theme = useTheme()
  const classes = styles(theme)
  return (
    <Grid item key={props.labelText} xs={12} sm={6} lg={3}>
      <Box className={classes.personalizedGridCard}>
        <div className={classes.circle}>{props.labelText}</div>
        {props.children}
      </Box>
    </Grid>
  )
}

function Easy4Steps() {
  const theme = useTheme()
  const classes = styles(theme)
  return (
    <FadeInUp>
      <Container className={classes.container}>
        <Box className={classes.personalizedTextContainer}>
          <Typography variant="h5" color="primary" align="center">
          - Get Your Personalized Drink in 4 Easy Steps -
          </Typography>
        </Box>
        <Grid container spacing={4}>
          <PersonalizedGridCard labelText="01">
            <SizeLogo className={classes.easyStepIcon} />
            <Typography variant="h6" align="center">Vessel + Drink Size</Typography>
            <Typography variant="body1" align="center">Bring your own bottle or cup and order the size of drink you want.</Typography>
          </PersonalizedGridCard>
          <PersonalizedGridCard labelText="02">
            <PourBases className={classes.easyStepIcon}/>
            <Typography variant="h6" align="center">Pick Your Base</Typography>
            <Typography variant="body1" align="center">Create your own drink or pick from one of our featured selections</Typography>
          </PersonalizedGridCard>
          <PersonalizedGridCard labelText="03">
            <Ingredients className={classes.easyStepIcon}/>
            <Typography variant="h6" align="center">Add Ingredients</Typography>
            <Typography variant="body1" align="center">Add Splashes of flavors you love, sweeteners or supplements</Typography>
          </PersonalizedGridCard>
          <PersonalizedGridCard labelText="04">
            <PourSave className={classes.easyStepIcon}/>
            <Typography variant="h6" align="center">Pour + Save</Typography>
            <Typography variant="body1" align="center">Pour your drink and save it to your favorites so you can enjoy it again.</Typography>
          </PersonalizedGridCard>
        </Grid>
      </Container>
    </FadeInUp>)
}

export default Easy4Steps
