import React from 'react'
import MyxMasterPass from '../../components/MyxMasterPass'
import AccountNavButtonGroup from '../../components/AccountNavButtonGroup'
import AccountForm from './components/AccountForm'
import MyxProgressSpinner from '../../components/MyxProgressSpinner'
import { Container } from '@material-ui/core'
import sessionContext from '../../../state/sessionContext'

const MyAccountPage = () => {
  const session = sessionContext.useSessionContext()
  if (session.user) {
    return (
      <Container data-testid={'myx-account-page'}>
        <MyxMasterPass userName={session.user.givenName}/>
        <AccountNavButtonGroup/>
        <AccountForm />
      </Container>
    )
  }

  return <MyxProgressSpinner/>

}

export default MyAccountPage
