import { TextareaAutosize, useTheme } from '@material-ui/core'

const MyxTextArea = (props) => {
  const theme = useTheme()
  return (
    <TextareaAutosize
      {...props}
      style={{
        border: `2px solid ${theme.palette.primary.border}`,
        ...props.style,
      }}
    >
      {props.children}
    </TextareaAutosize>
  )
}

export default MyxTextArea
