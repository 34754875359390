import React from 'react'
import { Box, Typography, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import MyxLink from '../../../components/MyxLink'

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '0 auto',
    maxWidth: 860,
    padding: '100px 50px',
    [theme.breakpoints.only('sm')]: {
      padding: '70px 15px',
    },
    [theme.breakpoints.only('xs')]: {
      padding: '40px 5px',
    }
  },
  text: {
    margin: '0 0.93em',

  },
  para: {
    margin: '0 auto 40px',
    color: theme.palette.myxblack
  }
}))

function MyxInTheNewsTop() {
  const _theme = useTheme()
  const styles = useStyles(_theme)
  return (
    <Box className={styles.container}>
      <Box className={styles.text}>
        <p className={styles.para}><Typography variant="body2">MYX, the newest entry into the personalized beverage channel, made quite a splash at the 2019 NAMA Show in Las Vegas.</Typography></p>
        <Typography className={styles.para} variant="h2">THE PRODUCT</Typography>
        <p className={styles.para}><Typography variant="body2">MYX is a personalized beverage dispensing system that offers a head spinning amount of drink options – millions per machine – by creating multiple, virtually endless combinations. These include a wide choice of flavors, still or sparkling water, supplements, calorie intake options and varying degrees of flavor strength.</Typography></p>
        <p className={styles.para}><Typography variant="body2">MYX machines vary from larger vending style units designed for more institutional accounts, like schools, healthcare and industrial, to under the counter “tower style dispensers,” designed for classic office environments.</Typography></p>
        <Typography className={styles.para} variant="h2">THE BACK STORY</Typography>
        <p className={styles.para}><Typography variant="body2">MYX originated as a student project at Washington State University about 7 years ago. Laurence Saul, President and CEO, who joined the company about two years ago, said he has been astonished by the reaction of consumers and operators, especially after they learn what the MYX unit can do.</Typography></p>
        <p className={styles.para}><Typography variant="body2">Saul said that hundreds of units, all connected directly to a MYX dashboard, are currently being operated by MYX, giving them the opportunity to gather important usage and performance data. “Right now, each placement is a learning lab,” he added. Operators working with MYX will have access to a customized dashboard, providing constant connectivity with each unit.</Typography></p>
        <p className={styles.para}><Typography variant="body2">MYX units can be set up on full vend – cash and credit accepted – or free vend, for the workplace seeking to deliver a nice perk.</Typography></p>
        <p className={styles.para}><Typography variant="body2">Saul said MYX is driven by providing personalized, customized, “better for you” drinks, while delivering products through a platform that is better for the environment and more energy efficient.</Typography></p>
        <p className={styles.para}><Typography variant="body2">Personalization is reinforced by the MYX app, which allows users to become members, save their drink combinations and reorder them, using the exact same configuration, since their beverage choice is known by the machine.</Typography></p>
        <p className={styles.para}><Typography variant="body2">As part of their environmental objectives, MYX encourages users to select reusable beverage containers. The MYX vendors themselves run on a very efficient platform – cooling the beverages with a patented thermoelectric chiller as opposed to a compressor coming off and on many times a day.</Typography></p>
        <p className={styles.para}><Typography variant="body2">Bob’s Perspective – Does MYX make sense for operators?</Typography></p>
        <p className={styles.para}><Typography variant="body2">There are several reasons why MYX is a product that every serious operator needs to consider adding to their product line.</Typography></p>
        <p className={styles.para}><Typography variant="body2">
          <ul>
            <li>MYX is taking personalized beverages to another level. Consumers will love the variety – millions of drink configurations plus popular supplements – CBD, vitamins, herbs and more. “Better for you” is a concept that sells today.</li>
            <li>The technology associated with this product – the app – the membership – the customized dashboard – indicates that MYX Drinks is a product that will evolve. Already, Saul said the MYX machine is set up to dispense cold brew and replace existing point of use water units, in addition to providing flavored drinks.</li>
            <li>Better for the environment. Again – a positive message that is backed up by the patented technology.</li>
            <li>MYX isn’t the only personalized beverage machine on the market, but right now, they are looking for operators to work with. Getting in on the ground floor is a good thing, especially if you have missed the ground floor in the past.</li>
            <li>The vend price per drink is $1.00 to $3.50, depending on how the drink is personalized. Not unrealistic at all, especially for a drink that can contain CBD and nutritional supplements.</li>
            <li>Using a MYX unit opens the door for an office ice machine rental and eco friendly cold cup sales, or even – personalized multi-use beverage containers.</li>
            <li>How often do we say: “This could be the most exciting (and realistic to operate) vending machine to come along in quite some time!” Not often. As a vending machine – MYX has endless possibilities. According to Saul, the unit is performing well in schools and healthcare settings.</li>
          </ul>
        </Typography></p>
        <p className={styles.para}><Typography variant="body2">Now, the door is open for operators.</Typography></p>
        <p className={styles.para}><Typography variant="body2">According to Saul, MYX is talking to operators “large and small” about their revolutionary personalized beverage program. To learn more – Connect with MYX through their website contact info page.</Typography></p>
        <p className={styles.para}><Typography variant="body2">Since selling his business in January of 2017, Bob Tullio has been an active industry consultant at www.tullioB2B.com focusing on content creation, strategic planning, sales training and business development. He has written over 40 columns and features, providing operators and other entrepreneurs with an inside look at how he helped build a successful business from the ground up. Currently, Tullio is developing a series of videos that are centered around his speaking presentation…</Typography></p>
        <p className={styles.para}><Typography variant="body2">
        “Ten Ways to Energize your Sales.”<br/>
        Website: <MyxLink href="http://www.tulliob2b.com">www.tulliob2b.com</MyxLink><br/>
        Phone: 818 261-1758<br/>
        Email bob@tulliob2b.com<br/>
        </Typography></p>
      </Box>
    </Box>)
}

export default MyxInTheNewsTop
