import React from 'react'
import { Card, CardMedia, Grid, useTheme } from '@material-ui/core'
import myxBrick from '../../../assets/graphics/brick-myx-2.jpg'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  grid1: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  }
}))
const MyAccountAccess = (props) => {

  const theme = useTheme()
  const classes = useStyles(theme)
  return (
    <Grid data-testid={'my-account-access'} container justifyContent={'center'} alignItems={'center'}>
      <Grid item xs={6} sm={6} className={classes.grid1}>
        <Card >
          <CardMedia
            src={myxBrick}
            component="img"
          />
        </Card>
      </Grid>
      <Grid item xs={6} sm={6} >
        {props.children}
      </Grid>
    </Grid>
  )

}
export default MyAccountAccess
