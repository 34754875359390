import React from 'react'
import MyAccountPage from './MyAccountPage'
import MyAccountAccess from './MyAccountAccess'
import * as locationHelper from '../../../helpers/locationHelper'
import PasswordReset from './PasswordReset'
import SignupDialog from '../../components/SignupDialog'
import LoginDialog from '../../components/dialogs/LoginDialog'
import ForgotPasswordDialog from '../../components/dialogs/ForgotPasswordDialog'
import sessionContext from '../../../state/sessionContext'

/**
 * Determines what to show a "user".  The user is usually lagged in, but password resets are also done with this endpoing
 * @returns {JSX.Element}
 * @constructor
 */
function MyAccount({ startingDialog }) {

  const { user, logIn } = sessionContext.useSessionContext()

  const history = locationHelper.getRouterUseHistory()
  const params = new URLSearchParams(history.location.search)
  const token = params.get('ref')
  const signup = params.get('signup')
  const forgotpassword = params.get('forgotpassword')

  const getUserView = () => {
    if (token) {
      return (
        <PasswordReset
          data-testid={'my-account-access-reset'}
          token={token}
          setOpenModal={startingDialog}
        />
      )
    } else if (signup) {
      return <SignupDialog/>
    } else if (forgotpassword) {
      return <ForgotPasswordDialog/>
    }

    const goToMyAccount = () => history.push('/my-account')

    const logInWrapper = (value) => logIn(value.email, value.password)
      .then(() => {
        goToMyAccount()
      })

    return (
      <LoginDialog
        logIn={logInWrapper}
        setOpenModal={() => {
          // do nothing?
        }}
        onComplete={() => history.push('my-account')}
        onClose={() => {
          // do nothing?
        }}
      />
    )

  }
  if (user) {
    return <MyAccountPage/>
  }
  return (
    <MyAccountAccess>
      {getUserView()}
    </MyAccountAccess>
  )
}

export default MyAccount
