import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import * as onScreenHelper from '../../onScreenHelper'

const styles = makeStyles((theme) => ({
  '@keyframes fadeInUp': {
    'from': {
      opacity: 0,
      transform: 'translate3d(0,40px,0)'
    },
    'to': {
      opacity: 1,
      transform: 'translate3d(0,0,0)'
    }
  },
  root: {
    transform: 'translateY(0) scale(1)',
    animationName: '$fadeInUp',
    animationDuration: '1.5s',
    '-webkit-animation-name': '$fadeInUp',
    'animation-fill-mode': 'both',
    '-webkit-animation-duration': '1.5s',
    '-webkit-animation-fill-mode': 'both',
    clear: 'both'
  },

}))

function FadeInUp(props) {
  const classes = styles()
  const { children } = props

  const isVisibleRef = React.useRef()
  const isVisible = onScreenHelper.useOnScreen(isVisibleRef)
  const [ showFade, setShowFade ] = React.useState(false)

  // only show the fade in the first time.
  React.useEffect(() => {
    if (isVisible === true) {
      setShowFade(true)
    }
  }, [ isVisible ])

  return (
    <div ref={isVisibleRef} className={(showFade ? classes.root : null)} style={{ zIndex: 9999, clear: 'both' }}>
      {children}
    </div>
  )
}

FadeInUp.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FadeInUp
