import React from 'react'
import { makeStyles } from '@material-ui/styles'
import HomePageCardView from './HomePageCardView'
import FadeInUp from '../../../views/FadeInUp'
import { useModal } from 'react-modal-hook'
import { Dialog } from '@material-ui/core'
import SignupDialog from '../../../components/SignupDialog'
import HeroGetStarted from '../../../../assets/graphics/get-started/hero-get-started-1920x1133.jpg'

const styles = makeStyles((theme) => ({
  background: {
    width: '100%',
    objectFit: 'cover',
    backgroundColor: 'white',
  },
}))

function SignUpForFree(props) {
  const classes = styles()

  const [ showModal, hideModal ] = useModal(({ onExited }) =>
    <Dialog open={true} onClose={hideModal} onTransitionEnd={onExited}>
      <SignupDialog onClose={hideModal}/>
    </Dialog>
  )

  return (
    <FadeInUp>
      <HomePageCardView
        cardRight
        title={'Sign up for Free And Get Started'}
        text={'Choose, track, share, pay, it\'s that easy.'}
        buttonText={'Get Started Today'}
        tertiaryColor
        handleBtnClick={showModal}
        picture={
          <picture>
            <img
              className={classes.background}
              itemProp="image"
              src={HeroGetStarted}
              alt="The World's Smartest Beverage System"/>
          </picture>}
      />
    </FadeInUp>)
}

export default SignUpForFree
