import Dragonfruit from '../../../../assets/graphics/featured-myxes/sparkling/MYX-Featured-WaterDragon-300x380.jpg'
import LemonLimeSeltzer from '../../../../assets/graphics/featured-myxes/sparkling/MYX-Featured-WaterLemonLime-1-300x380.jpg'
import MangoSeltzer from '../../../../assets/graphics/featured-myxes/sparkling/MYX-Featured-WaterMango-1-300x380.jpg'
import OrangeSeltzer from '../../../../assets/graphics/featured-myxes/sparkling/MYX-Featured-WaterOrange-1-300x380.jpg'
import PeachMango from '../../../../assets/graphics/featured-myxes/sparkling/MYX-Featured-TeaPeachMango-1-300x380.jpg'
import PomegranateBlueberry from '../../../../assets/graphics/featured-myxes/sparkling/MYX-Featured-WaterPomBlue-300x380.jpg'
import PomegranateSeltzer from '../../../../assets/graphics/featured-myxes/sparkling/MYX-Featured-WaterPom-1-300x380.jpg'
import RaspberryLime from '../../../../assets/graphics/featured-myxes/sparkling/MYX-Featured-WaterRasLime-1-300x380.jpg'
import Raspberry from '../../../../assets/graphics/featured-myxes/sparkling/MYX-Featured-WaterRas-1-300x380.jpg'
import StrawberryOrange from '../../../../assets/graphics/featured-myxes/sparkling/MYX-Featured-WaterOrangeStraw-1-300x380.jpg'

export const sparklingImgs = [
  {
    img: Dragonfruit,
    title: 'Dragonfruit Seltzer',
  },
  {
    img: LemonLimeSeltzer,
    title: 'Lemon Lime Seltzer',
  },
  {
    img: MangoSeltzer,
    title: 'Mango Seltzer',
  },
  {
    img: OrangeSeltzer,
    title: 'Orange Seltzer',
  },
  {
    img: PeachMango,
    title: 'Peach Mango Seltzer',
  },
  {
    img: PomegranateBlueberry,
    title: 'Pomegranate Blueberry Seltzer',
  },
  {
    img: PomegranateSeltzer,
    title: 'Pomegranate Seltzer',
  },
  {
    img: RaspberryLime,
    title: 'Raspberry Lime',
  },
  {
    img: Raspberry,
    title: 'Raspberry Seltzer',
  },
  {
    img: StrawberryOrange,
    title: 'Strawberry Orange Seltzer',
  }
]
