import React from 'react'
import MyxImageGridCard from '../../../components/MyxImageGridCard'
import MyxRoundedButton from '../../../components/MyxRoundedButton'
import BrickSignup from '../../../../assets/graphics/ingredients-benefits/brick-sign-up-1200x1200.jpg'
import GetStartedWrapper from '../../../components/GetStartedWrapper'
import { Box, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(10),
    [theme.breakpoints.only('lg')]: {
      paddingTop: theme.spacing(5)
    },
    [theme.breakpoints.only('md')]: {
      paddingTop: theme.spacing(5)
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2)
    }
  } }
))

function SignUpAndGetStartedForFree() {
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <Box className={classes.container} >
      <MyxImageGridCard
        mediaSrc={BrickSignup}
        mediaTitle="Sign Up For Free"
        title="Sign-Up And Get Started For Free"
        subText="Customize your MYX in advance, save it, track your order history and pay for your MYX, all with your personal MYX account."
        color="tertiary"
      >
        <GetStartedWrapper>
          <MyxRoundedButton myxvariant="tertiaryBorder" variant={'outlined'}>
        Get Started Today
          </MyxRoundedButton>
        </GetStartedWrapper>
      </MyxImageGridCard>
    </Box>
  )
}

export default SignUpAndGetStartedForFree
