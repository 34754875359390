import baseFruit from '../../../../assets/graphics/ingredients-benefits/carousel/bases/base-fruit-300x300.jpg'
import baseLemonade from '../../../../assets/graphics/ingredients-benefits/carousel/bases/base-lemonade-300x300.jpg'
import baseSparkling from '../../../../assets/graphics/ingredients-benefits/carousel/bases/base-sparkling-300x300.jpg'
import baseStill from '../../../../assets/graphics/ingredients-benefits/carousel/bases/base-still-300x300.jpg'
import baseTeas from '../../../../assets/graphics/ingredients-benefits/carousel/bases/base-teas-300x300.jpg'

export const ingredientCarouselBases = [ {
  img: baseLemonade,
  title: 'Lemonade',
},
{
  img: baseTeas,
  title: 'Tea',
},
{
  img: baseStill,
  title: 'Still Water'
},
{
  img: baseSparkling,
  title: 'Sparkling Water',
},
{
  img: baseFruit,
  title: 'Fruit',
},
]
