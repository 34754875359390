import React from 'react'
import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { ReactComponent as FeaturedBottle } from '../../../../assets/graphics/MYX_FeatureIcons_HYDRATION.svg'
import { makeStyles } from '@material-ui/styles'
import FadeInUp from '../../../views/FadeInUp'

const styles = makeStyles((theme) => ({
  outerContainer: {
    maxWidth: 1450,
    backgroundColor: 'white',
    padding: '30px 35px',
    width: '100%',
    margin: '0 auto',
    [theme.breakpoints.only('md')]: {
      padding: '10px 5px',
      maxWidth: 1160,
    }
  },
  featuredBottle: {
    height: '95px'
  },
}))

const Over1MillionCombinationsBar = (props) => {
  const theme = useTheme()
  const classes = styles(theme)
  const isMd = useMediaQuery(theme.breakpoints.only('md'))
  const isSm = useMediaQuery(theme.breakpoints.only('sm'))

  return (
    <FadeInUp>
      <Grid container
        alignItems={'center'}
        justifyContent={isMd ? 'space-around' : 'space-between'}
        direction={isSm ? 'column' : 'row'}
        className={classes.outerContainer}>

        <Grid item xs={12} md={6} lg={5}>
          <Typography color="primary" align="center" variant="h4">
          Over 1 Million Combinations
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3} style={{ textAlign: 'center' }}>
          <FeaturedBottle align="center" fill={theme.palette.myxblack} className={classes.featuredBottle}/>
        </Grid>
        { !isMd &&
      <Grid item xs={12} sm={4} md={4}>
        <Typography variant="body2" align="center" marked="center">
            You can create endless combinations with our selection of bases, flavors and supplements to make a drink as unique as you are.
        </Typography>
      </Grid>
        }
      </Grid>
    </FadeInUp>
  )
}

export default Over1MillionCombinationsBar
