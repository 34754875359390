import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box, Typography, useTheme } from '@material-ui/core'
import * as locationHelper from '../../helpers/locationHelper'

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    marginTop: theme.spacing(2),
  },
  boxItem: {
    marginTop: theme.spacing(0.3),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  lockButton: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    }
  },
  draftsIcon: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    }
  },
  dialogLinks: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
    fontWeight: 'bold',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    }
  }
}))

function LogInSignUpLinks({ setOpenModal, hide }) {
  const theme = useTheme()
  const classes = useStyles(theme)
  const history = locationHelper.getRouterUseHistory()

  /**
   * @param {String} dialog --
   * @param {String} noDialogPath -- path where you want to take the user if no modal is set
   */
  function userNavigation(dialog, noDialogPath) {
    if (setOpenModal) {
      setOpenModal(dialog)
    } else {
      history.push(noDialogPath)
    }
  }
  return (
    <Box data-testid={'log-in-sign-up-links'} className={classes.boxContainer}>
      <Box className={classes.boxItem}>
        <Typography variant="body1"
          className={classes.dialogLinks}
          onClick={() => userNavigation('forgotpassword', '/my-account?forgotpassword=true')}>
          Forgot Password?
        </Typography>
      </Box>
      {hide !== 'login' &&
      <Box className={classes.boxItem}>
        <Typography variant="body1">
        Already have an account?
          <span
            data-testid={'log-in-sign-up-links-signup'}
            className={classes.dialogLinks}
            style={{ color: theme.palette.primary.main }}
            onClick={() => userNavigation('login', '/my-account')}>
            Login!
          </span>
        </Typography>
      </Box>
      }
      {hide !== 'signup' &&
        <Box className={classes.boxItem}>
          <Typography variant="body1">
            Need an account?
            <span
              data-testid={'log-in-sign-up-links-signup'}
              className={classes.dialogLinks}
              onClick={() => userNavigation('signup', '/my-account?signup=true')}>
        Sign up!
            </span>
          </Typography>
        </Box>
      }
    </Box>
  )
}

export default LogInSignUpLinks
