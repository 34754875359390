import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  primaryHoverSecondary: {
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.main,
    height: '52px',
    width: '52px',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
      color: theme.palette.primary.light,
    }
  }
}))

const RoundedAvatarButton = (props) => {
  const classes = useStyles()
  return <Avatar className={classes.primaryHoverSecondary} aria-haspopup="true" aria-controls="user-menu" {...props}>
    {props.children}
  </Avatar>
}

export default RoundedAvatarButton
