import React from 'react'
import { Box, Container, IconButton, TextField, Typography, useTheme } from '@material-ui/core'
import { Formik } from 'formik'
import { ReactComponent as MyxDroplet } from '../../../assets/graphics/logo-droplet.svg'
import MyxFormikWrapper from '../../components/MyxFormikWrapper'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import { makeStyles } from '@material-ui/styles'
import * as userHelper from '../../../helpers/userHelper'
import LogInSignUpLinks from '../../components/LogInSignUpLinks'
import * as Yup from 'yup'
import * as passwordHelper from '../../../helpers/passwordHelper'

export const connectionFailure = { code: 'CONNECTION_FAILURE', message: 'Unable to complete request, please try again.' }
export const successMessage = 'Password updated, please click below to log in.'
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    margin: `${theme.spacing(1)}px auto`,
    maxWidth: '400px'
  },
  boxContainer: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    margin: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    maxWidth: '400px',
  },
  lockButton: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}))

const FormSchema = Yup.object().shape({
  password: passwordHelper.passwordValidationYup(),
  confirm: passwordHelper.passwordMatch('password'),
})

function PasswordReset({ token }) {
  const theme = useTheme()
  const classes = useStyles(theme)
  const [ formState, setFormState ] = React.useState({ errors: [], message: undefined })

  if (!token) {
    return (
      <Box data-testid={'password-reset-no-token'}>
        <Typography variant="body1">Link Not Found.</Typography>
      </Box>
    )
  }
  return (
    <Container
      data-testid={'password-reset-dialog'}
      className={classes.container}>
      <Formik
        validationSchema={FormSchema}
        initialValues={{
          password: undefined,
          confirm: undefined,
        }}
        onSubmit={(value) => {
          userHelper.resetPassword(token, value.password)
            .then(response => {
              setFormState({
                errors: (response.errors ? response.errors : []),
                message: (response.errors ? undefined : successMessage)
              })
            })
            .catch(err => {
              if (err && err.jsonPromise) {
                err.jsonPromise.then(json => {
                  setFormState({
                    loading: false,
                    errors: json.errors,
                    response: undefined,
                  })
                })
              } else {
                setFormState({
                  errors: [ connectionFailure ],
                  message: undefined
                })
              }
            })
        }
        }
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form data-testid={'password-reset'} id="login-form" onSubmit={handleSubmit}>
            <Box className={classes.boxContainer}>
              <Box >
                <MyxDroplet />
              </Box>
              <Box >
                <Typography variant="h4">
                    SET YOUR PASSWORD
                </Typography>
              </Box>
              <Box >
                <Typography variant="body1">
                    Set and confirm your password below.
                </Typography>
              </Box>

              <Box >
                <MyxFormikWrapper
                  name="password"
                  type="password"
                  label="Password"
                  margin="normal"
                  variant="outlined"
                  errors={ { password: errors.password }}
                  touched={touched}
                >
                  <TextField
                    inputProps={{ 'data-testid': 'password-reset-password-input' }}
                  />
                </MyxFormikWrapper>
              </Box>
              <Box >
                <MyxFormikWrapper
                  name="confirm"
                  type="password"
                  label="Confirm Password"
                  margin="normal"
                  variant="outlined"
                  errors={ { confirm: [ errors.confirm, ...formState.errors.map(err => err.message) ] }}
                  touched={touched}
                >
                  <TextField
                    inputProps={{ 'data-testid': 'password-reset-confirm-input' }}/>
                </MyxFormikWrapper>
              </Box>
              <Box >
                {
                  formState.message && <Typography data-testid={'password-reset-success-msg'} style={{ color: theme.palette.primary.main }} variant="body1">{formState.message}</Typography>
                }
              </Box>
              <Box >
                <IconButton
                  disabled = {formState.message === successMessage}
                  data-testid={'password-reset-submit-btn'}
                  type="submit"
                  edge="start"
                  className={classes.lockButton}
                  color="primary"
                  aria-label="menu">
                  <LockOpenIcon />
                </IconButton>
              </Box>
              <LogInSignUpLinks />
            </Box>
          </form>
        )}
      </Formik>
    </Container>
  )
}
export default PasswordReset
