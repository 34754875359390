import React from 'react'
import MyxRoundedButton from '../components/MyxRoundedButton'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import RoundedAvatarButton from '../components/RoundedAvatarButton'
import GetStartedWrapper from '../components/GetStartedWrapper'
import LoginWrapper from '../components/LoginWrapper'

const DesktopAvatarButton = () => {
  return (
    <>
      <GetStartedWrapper >
        <MyxRoundedButton data-testid={'desktop-avatar-button-get-started'} >
        Get Started
        </MyxRoundedButton>
      </GetStartedWrapper>
      <LoginWrapper>
        <RoundedAvatarButton data-testid={'desktop-avatar-button-login'}>
          <LockOpenIcon />
        </RoundedAvatarButton>
      </LoginWrapper>
    </>
  )
}

export default DesktopAvatarButton
