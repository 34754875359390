import React from 'react'
import { Container, Grid, MenuItem, TextField, Typography } from '@material-ui/core'
import MyxUnderline from '../../../components/MyxUnderline'
import MyxSelectInput from '../../../components/MyxSelectInput'
import MyxTextArea from '../../../components/MyxTextArea'
import MyxRoundedButton from '../../../components/MyxRoundedButton'
import MyxFormikWrapper from '../../../components/MyxFormikWrapper'
import { Formik } from 'formik'
import { postInquiry } from '../../../../backend'
import * as Yup from 'yup'
import FadeInUp from '../../../views/FadeInUp'
import RequestResultGrid from '../../../components/RequestResultGrid'

const MyxFormGrid = (props) => <Grid item xs={12} sm={8} md={6}>
  {props.children}
</Grid>

const StyledInputField = (props) => (<MyxFormGrid>
  <MyxFormikWrapper {...props}>
    {props.children}
  </MyxFormikWrapper>
</MyxFormGrid>)

const FormikWrapper = (props) => (<MyxFormikWrapper {...props}>
  {props.children}
</MyxFormikWrapper>)

const StyledTextField = (props) => (<StyledInputField {...props}>
  <TextField/>
</StyledInputField>)

const HowCanWeHelpSelect = (props) => (<MyxSelectInput
  label="How can we help?"
  name="comments"
  variant="outlined"
  margin="none"
  defaultValue="General Inquiry"
  fullWidth
  {...props}
>
  <MenuItem value="General Inquiry">General Inquiry</MenuItem>
  <MenuItem value="Kiosk Information">Kiosk Information</MenuItem>
  <MenuItem value="Kiosk Service">Kiosk Service</MenuItem>
</MyxSelectInput>)

const FormSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(45, 'First Name must be less than 45 characters'),
  lastName: Yup.string()
    .max(45, 'Last Name must be less than 45 characters'),
  message: Yup.string()
    .max(2000, 'Message must be less than 2000 characters'),
  email: Yup.string()
    .email('Invalid Email')
    .required('Email is required')
})

function BecomeAMyxPartnerForm() {

  const [ formState, setFormState ] = React.useState({ errors: [], message: undefined, submitted: false })

  return (
    <FadeInUp>
      <Container style={{ maxWidth: '90%', clear: 'both', textAlign: 'center', justifyContent: 'center' }}>
        <Typography variant="h4">
          Have Questions? Want to Become a Partner?
        </Typography>
        <MyxUnderline color="primary"/>
        <Typography variant="body1">
          Send us a note and we will get right back to you!
        </Typography>
        <Formik
          initialValues={{
            'message': '',
            'howCanWeHelp': 'General Inquiry',
            'email': undefined,
          }}
          validationSchema={FormSchema}
          onSubmit={(values) => {
            postInquiry(values)
              .then(() => {
                setFormState({ errors: [], message: 'Thank you for your inquiry!', submitted: true })
              }).catch((error) => {
                error.jsonPromise.then((res) => {
                  setFormState({ errors: res.errors, message: undefined })
                }).catch((ex) => setFormState({
                  errors: [ { code: '', message: 'Sorry, we couldn\'t process your request.' } ],
                  message: error.toString()
                }))
              })
          }}
        >

          {({

            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,

          }) => (
            <form id="become-a-myx-partner" onSubmit={((formEvent) => handleSubmit(formEvent))}>
              <Grid container spacing={4}>
                <StyledTextField
                  name="firstName"
                  label="First Name"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  errors={errors}
                  touched={touched}
                />

                <StyledTextField
                  name="lastName"
                  label="Last Name"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  errors={errors}
                  touched={touched}
                />

                <StyledTextField
                  name="email"
                  label="Email"
                  variant="outlined"
                  margin="none"
                  fullWidth
                  errors={errors}
                  touched={touched}
                />

                <StyledInputField name="comments">
                  <HowCanWeHelpSelect/>
                </StyledInputField>

                <Grid item xs={12} sm={12} md={12}>
                  <FormikWrapper
                    name="message"
                    placeholder="Your Message"
                    variant="outlined"
                    margin="none"
                    style={{ width: '100%' }}
                    minRows={12}
                    errors={errors}
                    touched={touched}
                  >
                    <MyxTextArea/>
                  </FormikWrapper>
                </Grid>
                {
                  formState.message || formState.errors.length > 0
                    ? <RequestResultGrid errors={formState.errors} message={formState.message}/> : null
                }
              </Grid>
              <MyxRoundedButton type="submit"
                disabled={Object.keys(touched).length < 1 || formState.submitted}
                onClick={() => {
                  if (Object.keys(errors).length > 0 || Object.keys(touched).length < 1) {
                    setFormState({
                      errors: [ {
                        code: 'FORM_ERRORS',
                        message: 'Please correct the errors above and resubmit'
                      } ]
                    })
                  }
                }}
                style={{ marginTop: '5%', marginBottom: '5%' }}>
                Submit
              </MyxRoundedButton>
            </form>
          )
          }

        </Formik>
      </Container>
    </FadeInUp>)
}

export default BecomeAMyxPartnerForm
