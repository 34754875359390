import MyxSelectInput from './MyxSelectInput'
import { MenuItem } from '@material-ui/core'

const countries = {
  'USA': 'United States',
  'CAN': 'Canada',
  '': 'None of the above'
}

function MyxCountrySelect(props) {
  return (<MyxSelectInput {...props}>
    {Object.keys(countries).map((country) => (<MenuItem key={country} value={country}>{countries[country]}</MenuItem>))}
  </MyxSelectInput>)
}

export default MyxCountrySelect
