import React from 'react'
import { makeStyles } from '@material-ui/styles'
import heroVendingBackground1920 from '../../../../assets/graphics/homepage/heros/vending/hero-vending-1-1920x1133.jpg'
import HomePageCardView from './HomePageCardView'
import * as locationHelper from '../../../../helpers/locationHelper'

const styles = makeStyles((theme) => ({

  background: {
    width: '100%',
    objectFit: 'cover',
    backgroundColor: 'white',
  },

}))

function WorldsSmartestBeverageSystem(props) {
  const classes = styles()
  const history = locationHelper.getRouterUseHistory()
  const handleBtnClick = () => {
    history.push('/how-it-works')
  }

  return (
    <HomePageCardView
      reversed
      title="The World's Smartest Beverage System"
      text={<>
        With an intuitive touch interface, MYX  offers customers literally millions of all-natural beverage combinations,
        right at your fingertips. Still or sparkling water, teas and fruit beverages combined with natural sweeteners including cane sugar or monk fruit blends.
        Then, top-off your MYX with one of our enhancements including a shot of energy, immunity, electrolytes or CBD oil.
        <br/><b> Your Drink. Your Way.</b></>}
      buttonText="How MYX Works"
      tertiaryColor
      handleBtnClick={handleBtnClick}
      picture={
        <picture>
          <img
            className={classes.background}
            itemProp="image"
            src={heroVendingBackground1920}
            alt="The World's Smartest Beverage System"/>
        </picture>}
    />
  )
}

export default WorldsSmartestBeverageSystem
