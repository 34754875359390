import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Container, TextField, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import MyxFormikWrapper from '../../../components/MyxFormikWrapper'
import MyxRoundedButton from '../../../components/MyxRoundedButton'
import { graphQLFetch } from '../../../../backend'
import RequestResultGrid from '../../../components/RequestResultGrid'
import * as passwordHelper from '../../../../helpers/passwordHelper'
const FormSchema = Yup.object().shape({
  newPassword: passwordHelper.passwordValidationYup(),
  passwordConfirmation: passwordHelper.passwordMatch('newPassword'),
})

function ChangePasswordForm() {
  const [ formState, setFormState ] = React.useState({ errors: [], message: undefined })

  return (
    <Container data-testid={'change-password-form'}>
      <Typography variant="h4" style={{ marginBottom: '5%' }}>
      Change Password
      </Typography>
      <Formik
        initialValues={{
          password: '',
          newPassword: '',
        }}
        validationSchema={FormSchema}
        onSubmit={(values) => {
          graphQLFetch(`
          mutation($update: UpdateUserPasswordInput!) {
            updateMyPassword(update: $update){
              id
            }
          }
        `, {
            update: {
              oldPassword: values.oldPassword,
              newPassword: values.newPassword,
            }
          }).then((response) => {
            setFormState({ errors: [], message: 'Password Updated' })
          }).catch((error) => setFormState({
            errors: [ { code: '', message: 'Sorry, we couldn\'t process your request.' } ],
            message: error.toString()
          }))
        }
        }
      >
        {({

          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,

        }) => (
          <form id="change-password-form" onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <MyxFormikWrapper
                  name="oldPassword"
                  type="password"
                  placeholder="Password"
                  label="Password"
                  variant="outlined"
                  margin="none"
                  fullWidth
                  errors={errors}
                  touched={touched}
                >
                  <TextField/>
                </MyxFormikWrapper>
              </Grid>
              <Grid item xs={12}>
                <MyxFormikWrapper
                  name="newPassword"
                  type="password"
                  placeholder="New Password"
                  label="New Password"
                  variant="outlined"
                  margin="none"
                  fullWidth
                  errors={errors}
                  touched={touched}
                >
                  <TextField/>
                </MyxFormikWrapper>
              </Grid>
              <Grid item xs={12}>
                <MyxFormikWrapper
                  name="passwordConfirmation"
                  type="password"
                  placeholder="New Password Confirmation"
                  label="New Password Confirmation"
                  variant="outlined"
                  margin="none"
                  fullWidth
                  errors={errors}
                  touched={touched}
                >
                  <TextField/>
                </MyxFormikWrapper>
              </Grid>
              {
                formState.message || formState.errors.length > 0
                  ? <RequestResultGrid errors={formState.errors} message={formState.message} /> : null
              }
            </Grid>
            <MyxRoundedButton type="submit" style={{
              marginTop: '5%',
              marginBottom: '5%',
            }}>
            Save Changes
            </MyxRoundedButton>
          </form>
        )
        }
      </Formik>
    </Container>
  )
}

export default ChangePasswordForm
