import React from 'react'
import { Box, CircularProgress, Container, Typography, useTheme } from '@material-ui/core'
import MyxUnderline from '../../components/MyxUnderline'
import queryString from 'querystring'
import * as locationHelper from '../../../helpers/locationHelper'
import * as userHelper from '../../../helpers/userHelper'
import { makeStyles } from '@material-ui/styles'
import AccountVerifyForm from './components/AccountVerifyForm'

const accountVerifyStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    clear: 'both',
    textAlign: 'center',
    margin: `${theme.spacing(3)}px auto`
  }
}))

function AccountVerify() {
  const theme = useTheme()
  const styles = accountVerifyStyles(theme)
  const history = locationHelper.getRouterUseHistory()
  const params = queryString.parse(history.location.search.replace('?', ''))
  const [ token ] = React.useState(params.token)

  const [ tokenState, setTokenState ] = React.useState({
    loading: true,
    errors: undefined,
    response: undefined
  })

  React.useEffect(() => {
    userHelper.getTokenValues(token)
      .then(response => {
        setTokenState({
          loading: false,
          errors: undefined,
          response: response,
        })
      })
      .catch(err => {
        if (err.jsonPromise) {
          err.jsonPromise.then(json => {
            setTokenState({
              loading: false,
              errors: json.errors,
              response: undefined,
            })
          })
        } else {
          // no network case
          setTokenState({
            loading: false,
            errors: [ { 'code': 'LOCAL', 'message': 'Unable to process request at this time, please try again' } ],
            response: undefined,
          })
        }
      })

  }, [ token ])

  const showErrorMessage = () => {
    return (
      <Box data-testid={'account-verify-error'}>
        <Box>
          <Typography variant="h2">OOPS... SOMETHING ISN'T RIGHT HERE.</Typography>
        </Box>
        <MyxUnderline color="primary" />
        <Box>
          <Typography variant="body1">Please check that you have the correct link.</Typography>
        </Box>
        {
          tokenState.errors.map(err => {
            return (
              <Box key={err} data-testid={'account-verify-error-text'}>
                <Typography variant="body2">{err.message}</Typography>
              </Box>
            )
          })
        }
      </Box>
    )
  }

  const showAccountVerifyFields = () => {
    return (
      <Box data-testid={'account-verify-welcome'}>
        <Typography variant="h2">Welcome To Your Myx!</Typography>
        <MyxUnderline/>
        <Typography variant="body1">
        Thank you for verifying your account. Please enter a password(required) and some information about yourself.
        </Typography>
        <AccountVerifyForm token={token} initialValues={tokenState.response}/>
      </Box>
    )
  }

  return (
    <Container className={styles.container} data-testid={'account-verify-container'}>
      {tokenState.loading
        ? <CircularProgress data-testid={'account-verify-progress'}/>
        : tokenState.errors ? showErrorMessage() : showAccountVerifyFields()
      }
    </Container>
  )
}

export default AccountVerify
