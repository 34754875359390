import React from 'react'
import { makeStyles } from '@material-ui/styles'

const styles = makeStyles((theme) => ({
  sliderArrowNext: {
    position: 'absolute',
    right: '20px',
    backgroundImage: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'49\' height=\'25\' viewBox=\'0 0 49 25\'%3E%3Cpath d=\'M48.6 13.9c0-.1.1-.1.1-.2s.1-.2.1-.2c0-.1.1-.2.1-.2 0-.1 0-.1.1-.2.1-.3.1-.7 0-1 0-.1 0-.1-.1-.2 0-.1 0-.2-.1-.2 0-.1-.1-.2-.1-.2 0-.1-.1-.1-.1-.2-.1-.1-.2-.3-.3-.4l-10-10c-1-1-2.6-1-3.5 0s-1 2.6 0 3.5l5.7 5.7h-38C1.1 10 0 11.1 0 12.5S1.1 15 2.5 15h38l-5.7 5.7c-1 1-1 2.6 0 3.5.5.5 1.1.7 1.8.7s1.3-.2 1.8-.7l10-10c0-.1.1-.2.2-.3\'/%3E%3Cpath fill=\'none\' d=\'M0 0h49v25H0z\'/%3E%3C/svg%3E")',
    transition: '200ms all ease',
    border: '0',
    padding: '0',
    top: '50%',
    zIndex: '10',
    height: '42px',
    width: '42px',
    borderRadius: '50%',
    backgroundSize: '13px',
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#fff',
    outline: 'none',
    boxShadow: '0px 3px 23px -3px rgba(0,0,0,0.5)'
  },
}))

function MyxCarouselRightButton({ onClick }) {
  const classes = styles()
  return <button className={classes.sliderArrowNext} onClick={() => onClick()} />
}

export default MyxCarouselRightButton
