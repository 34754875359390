import React from 'react'
import { CircularProgress, Container } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
  },
  progressSpinner: {
    paddingTop: '10%',
    paddingBottom: '10%',
  }
}))

function MyxProgressSpinner() {
  const classes = useStyles()
  return (
    <Container data-testid={'myx-progress-spinner'} className={classes.container}>
      <CircularProgress className={classes.progressSpinner}/>
    </Container>
  )
}

export default MyxProgressSpinner
