import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
  progressWrapper: {
    position: 'relative',
    display: 'flex', // ensure child content negative margin does not spill out
    flexDirection: 'column',
    minHeight: theme.spacing(12)
  },
  progressWrapperContents: {
    '&[data-disabled=true]': {
      pointerEvents: 'none'
    }
  },
  progressWrapperOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: fade(theme.palette.background.default, 0.75), // match page background
    opacity: 0, // hide by default
    transition: 'opacity 0.2s ease-out',
    pointerEvents: 'none', // allow clicks on content just in case
    paddingTop: theme.spacing(4), // minimum spacing for spinner from top
    paddingBottom: theme.spacing(4), // minimum spacing for spinner from bottom

    '&[data-active=true]': {
      opacity: 1
    }
  },
  progressWrapperProgress: {
    position: 'sticky',
    left: 0,
    right: 0,
    top: '40vh', // place in middle of screen
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

/**
 * ProgressWrapper preserves the scroll position on refresh of data (e.g. in a card, in a panel view)
 * because the UI is not clobbered by being replaced with a spinner (as with the traditional approach),
 * and instead gets a transparent overlay.
 */
function ProgressWrapper({ pending, children }) {
  const classes = useStyles()

  return (
    <div className={classes.progressWrapper}>
      <div className={classes.progressWrapperContents} data-disabled={!!pending}>
        {children}
      </div>

      <div className={classes.progressWrapperOverlay} data-active={!!pending}>
        <div className={classes.progressWrapperProgress}>
          <CircularProgress />
        </div>
      </div>
    </div>
  )
}

export default ProgressWrapper
