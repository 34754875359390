import React from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  white: {
    borderColor: 'white',
  },
  primaryBorder: {
    borderColor: theme.palette.primary.main,
  },
  tertiaryBorder: {
    borderColor: theme.palette.tertiary.main,
    '&:hover': {
      backgroundColor: theme.palette.tertiary.main,
      borderColor: theme.palette.tertiary.main,
      color: 'white',
    },
  },
  roundedButtonDisabled: {
    color: 'white',
    backgroundColor: '#c6c4c4',
  }
}))

const MyxRoundedButton = (props) => {
  const classes = useStyles()
  const { myxvariant, className, children } = props
  const variant = props.disabled ? classes.roundedButtonDisabled : classes[myxvariant]
  return (
    <Button {...props} className={variant + ' ' + className } >
      {children}
    </Button>
  )
}

export default MyxRoundedButton

