import React from 'react'
import { Box, Typography } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import { ReactComponent as Megaphone } from '../../assets/graphics/megaphone.svg'
import { makeStyles } from '@material-ui/styles'
import FadeInUp from '../views/FadeInUp'

const styles = makeStyles((theme) => ({
  faqContainer: {
    textAlign: 'center',
    paddingTop: theme.spacing(5),
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0.1,
    },
    '50%': {
      opacity: 1,

    },
    '100%': {
      opacity: 0.1,
    }
  },
  megaphone: {
    width: theme.spacing(10),
    height: theme.spacing(12),
    margin: `0 auto ${theme.spacing(3)}`,
    animationName: '$fadeIn',
    animationDuration: '4s',
    animationTimingFunction: 'ease-in-out',
    animationIterationCount: 'infinite',
    animationPlayState: 'running'
  },
  qAndAContainer: {
    padding: theme.spacing(3)
  }
}))

function FrequentlyAskedQuestions(props) {
  const classes = styles()
  return (
    <FadeInUp>
      <Container className={classes.faqContainer}>
        <Megaphone className={classes.megaphone}/>
        <Typography variant="h3" color="primary">Frequently Asked Questions</Typography>
        <Box className={classes.qAndAContainer}>
          {props.children}
        </Box>
      </Container>
    </FadeInUp>
  )
}

export default FrequentlyAskedQuestions

