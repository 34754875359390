import BlackTea from '../../../../assets/graphics/featured-myxes/teas/MYX-Featured-Tea-1-300x380.jpg'
import GreenTea from '../../../../assets/graphics/featured-myxes/teas/MYX-Featured-GreenTea-1-300x380.jpg'
import TeaLemon from '../../../../assets/graphics/featured-myxes/teas/MYX-Featured-TeaLemon-1-300x380.jpg'
import PeachMangoTea from '../../../../assets/graphics/featured-myxes/teas/MYX-Featured-TeaPeachMango-1-300x380.jpg'
import PomPeachMango from '../../../../assets/graphics/featured-myxes/teas/MYX-Featured-TeaPomMango-1-300x380.jpg'
import TeaRas from '../../../../assets/graphics/featured-myxes/teas/MYX-Featured-TeaRas-1-300x380.jpg'

export const teaImgs = [
  {
    img: BlackTea,
    title: 'Black Tea',
  },
  {
    img: GreenTea,
    title: 'Green Tea'
  },
  {
    img: TeaLemon,
    title: 'Lemon Tea'
  },
  {
    img: PeachMangoTea,
    title: 'Peach Mango Tea',
  },
  {
    img: PomPeachMango,
    title: 'Pomegranate Mango Tea',
  },
  {
    img: TeaRas,
    title: 'Raspberry Tea',
  },
  {
    img: TeaLemon,
    title: 'Tea & Lemonade',
  }
]
