import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { ReactComponent as LogoStacked } from '../../assets/graphics/logo-stacked.svg'
import MyxLink from '../components/MyxLink'
import InstagramIcon from '@material-ui/icons/Instagram'
import { Box, Typography, useTheme } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.myxblack,
    padding: '40px 15px',
    margin: '0 auto'
  },
  grid: {
    maxWidth: '1200px',
    margin: '0 auto',
  },
  logoStacked: {
    maxWidth: 160,
    fill: '#ffffff',
    transition: '200ms all ease;',
    '&:hover': {
      fill: theme.palette.primary.main
    }
  },
  text: {
    color: theme.palette.white,
    textTransform: 'uppercase',
    textAlign: 'center'
  },
  letsTalk: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'unset'
    }
  },
  copyright: {
    width: '100%',
    color: theme.palette.primary.light,
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  },
  footerPart: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
  }
}))

export default function MyxFooter() {
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <Box
      className={classes.root}
      component="footer">
      <Grid
        className={classes.grid}
        container
        spacing={2}
        justifyContent={'center'}
        alignItems={'center'}>
        <Grid item xs={12} md={4}>
          <Box className={classes.footerPart}>
            <MyxLink to={'/connect'}>
              <Typography variant="body1" className={classes.text}>
              Want to become an official myx distributor? <u className={classes.letsTalk}>Let's Talk</u>
              </Typography>
            </MyxLink>
          </Box>
        </Grid>
        <Grid item xs={12} md={4} >
          <Box className={classes.footerPart}>
            <LogoStacked className={classes.logoStacked}/>
          </Box>
        </Grid>
        <Grid item xs={12} md={4} >
          <Box className={classes.footerPart}>
            <Typography variant="body1" className={classes.text}>
                  Get Connected With Myx
            </Typography>
            <MyxLink href="https://www.instagram.com/myxdrinks/">
              <InstagramIcon style={{ color: '#ffff' }}/>
            </MyxLink>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box className={classes.copyright}>
            <Typography variant="body2" style={{ color: 'white' }}>
              {'© '}
              {new Date().getFullYear()}
              {' MYX DRINKS'}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>

  )
}
