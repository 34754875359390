import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { ingredientCarouselBases } from './ingredientCarouselBaseImages'
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import ProductHeroLayout from '../../../views/ProductHeroLayout'
import Carousel from 'react-multi-carousel'
import MyxCarouselLeftButton from '../../../components/carousel/MyxCarouselLeftButton'
import MyxCarouselRightButton from '../../../components/carousel/MyxCarouselRightButton'
import { flavorCarousels } from './flavorCarouselImages'
import { supplementsCarouselImages } from './supplementsCarouselImages'
import FadeInUp from '../../../views/FadeInUp'
import { useWindowSizeContext } from '../../../../state/WindowSizeProvider'

const useStyles = makeStyles((theme) => ({
  carouselContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    padding: '1% 0',
    height: 520,
    [theme.breakpoints.only('xl')]: {
      height: 520,
    },
    [theme.breakpoints.only('lg')]: {
      height: 520,
    },
    [theme.breakpoints.only('md')]: {
      height: 420,
    },
    [theme.breakpoints.only('sm')]: {
      height: 330,
    },
    [theme.breakpoints.only('xs')]: {
      height: 250,
    },
  },
  carouselInnerContainer: {
    background: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 0,
    margin: 0,
    height: '100%',
  },
  sliderItemContainer: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  sliderItemImg: {
    objectFit: 'contain',
    width: '100%'
  },
  itemClass: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: `${theme.spacing(2)}px 0`,
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  carouselButton: {
    color: theme.palette.myxblack,
    minWidth: 'unset',
    padding: 0,
    margin: `0 ${theme.spacing(2)}px `,
    textTransform: 'uppercase',
    borderRadius: 0,
    border: 0,
    fontWeight: 500,
    '&:hover': {
      color: theme.palette.tertiary.main,
      backgroundColor: 'white',
    }
  },
  carouselButtonSelected: {
    color: theme.palette.tertiary.main,
  },
  naturalIngredientGridContainer: {
    maxWidth: 1300,
    margin: '0 auto',
    padding: `0 ${theme.spacing(8)}px`,
    [theme.breakpoints.down('md')]: {
      padding: `0 ${theme.spacing(2)}px`,
    },
  },
  title: {
    fontWeight: 500,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  text: {
    fontSize: 22,
    [theme.breakpoints.only('sm')]: {
      fontSize: 20,
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: 18,
    },

    textTransform: 'capitalize',
    color: theme.palette.myxblack,
    margin: `${theme.spacing(1)}px 0`,
    width: '100%',
    textAlign: 'left'
  }
}))

const types = Object.freeze({
  base: ingredientCarouselBases,
  flavors: flavorCarousels,
  supplements: supplementsCarouselImages
})

function OurNaturalIngredientsCarousel() {
  const [ windowSize ] = useWindowSizeContext()

  const getNumberOfItems = (windowWidth) => {
    if (windowWidth >= 1920) {
      return 4 // xl
    }
    if (windowWidth >= 1280) {
      return 4 // lg
    }
    if (windowWidth >= 960) {
      return 3 // md
    }
    if (windowWidth >= 600) {
      return 3 // sm
    }
    return 2 // xs
  }
  const getItemWidth = () => (windowSize.width / (getNumberOfItems(windowSize.width) + 1))

  const theme = useTheme()
  const classes = useStyles(theme)
  const isMobile = useMediaQuery(theme.breakpoints.only('md'))

  const [ selectedCarousel, setSelectedCarousel ] = useState(types.base)

  const responsive = {
    xl: {
      // the naming can be any, depends on you.
      breakpoint: { min: 1920 },
      items: getNumberOfItems(3000)
    },
    lg: {
      breakpoint: { max: 1920, min: 1280 },
      items: getNumberOfItems(1920)
    },
    md: {
      breakpoint: { max: 1280, min: 960 },
      items: getNumberOfItems(1920)
    },
    sm: {
      breakpoint: { max: 960, min: 600 },
      items: getNumberOfItems(0)
    },
    xs: {
      breakpoint: { max: 600, min: 0 },
      items: getNumberOfItems(0)
    },
  }

  const getButtonStyles = (btn) => classes.carouselButton + (btn === selectedCarousel ? ' ' + classes.carouselButtonSelected : '')

  const getImageBoxes = (imgArr) => (imgArr
    .sort((aaa, bbb) => aaa.title > bbb.title ? 1 : -1)
    .map((item) => (
      <Box key={item.title} className={classes.sliderItemContainer} style={{ maxWidth: getItemWidth() }}>
        <img className={classes.sliderItemImg} alt={item.title} src={item.img}/>
        <Typography variant="caption" className={classes.text}>{item.title}</Typography>
      </Box>
    )))

  return (
    <FadeInUp>
      <Grid container className={classes.naturalIngredientGridContainer}>
        <Grid item xs={12} sm={12} md={6}>
          <Typography variant="h6" className={classes.title}>Our Natural Ingredients</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={classes.buttonContainer}>
          <Button variant={'outlined'} className={getButtonStyles(types.base)}
            onClick={() => setSelectedCarousel(types.base)}>Drink Bases</Button> /
          <Button variant={'outlined'} className={getButtonStyles(types.flavors)}
            onClick={() => setSelectedCarousel(types.flavors)}>Flavors</Button> /
          <Button variant={'outlined'} className={getButtonStyles(types.supplements)}
            onClick={() => setSelectedCarousel(types.supplements)}>Supplements</Button>
        </Grid>
      </Grid>
      <ProductHeroLayout className={classes.carouselContainer}>
        <Box className={classes.carouselInnerContainer}>
          <Carousel
            autoPlay={true}
            centerMode={!isMobile}
            infinite={true}
            responsive={responsive}
            showDots={false}
            itemClass={classes.itemClass}
            customLeftArrow={<MyxCarouselLeftButton/>}
            customRightArrow={<MyxCarouselRightButton/>}
          >
            {getImageBoxes(selectedCarousel)}
          </Carousel>
        </Box>
      </ProductHeroLayout>
    </FadeInUp>
  )
}

export default OurNaturalIngredientsCarousel
