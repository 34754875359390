import React from 'react'
import DiscoverOurNaturalBeverageSystem from './components/DiscoverOurNaturalBeverageSystem'
import Easy4Steps from './components/Easy4Steps'
import EnjoyMindfulHydrationBar from './components/EnjoyMindfulHydrationBar'
import BuildYourFavorites from './components/BuildYourFavorites'
import HowItWorksFAQ from './components/HowItWorksFAQ'

const HowItWorks = () => <>
  <DiscoverOurNaturalBeverageSystem/>
  <Easy4Steps/>
  <EnjoyMindfulHydrationBar/>
  <BuildYourFavorites/>
  <HowItWorksFAQ/>
</>

export default HowItWorks
