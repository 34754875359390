import React from 'react'
import ConnectWithMyx from './components/ConnectWithMyx'
import ServiceInquires from './components/ServiceInquiries'
import BecomeAMyxPartnerForm from './components/BecomeAMyxPartnerForm'

const Connect = () => <>
  <ConnectWithMyx/>
  <ServiceInquires/>
  <BecomeAMyxPartnerForm/>
</>

export default Connect
