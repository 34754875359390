import React from 'react'
import MyxRoundedButton from '../../components/MyxRoundedButton'
import heroHomeTabletImg from '../../../assets/graphics/homepage/heros/hero-home-tablet.jpg'
import heroHomeMobileImg from '../../../assets/graphics/homepage/heros/hero-home-mobile.jpg'

import heroHomeImg from '../../../assets/graphics/homepage/heros/hero-home.jpg'
import heroHomeImg1920 from '../../../assets/graphics/homepage/heros/hero-home-1920x1133.jpg'
import heroHomeImg1024 from '../../../assets/graphics/homepage/heros/hero-home-1024x750.jpg'
import heroHomeImg480 from '../../../assets/graphics/homepage/heros/hero-home-640x400.jpg'

import arrow from '../../../assets/graphics/arrow-down.svg'
import BecomeAMemberBar from './components/BecomeAMemberBar'
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import MyxMakesSplashAtNama from './components/MyxMakesSplashAtNama'
import SignUpForFree from './components/SignUpForFree'
import Over1MillionCombinationsBar from './components/Over1MillionCombinationsBar'
import MillionsOfRefreshingCombinations from './components/MillionsOfRefreshingCombinations'
import DiscoverNewMyxCarousel from './components/DiscoverNewMyxCarousel'
import WorldsSmartestBeverageSystem from './components/WorldsSmartestBeverageSystem'
import GetStartedWrapper from '../../components/GetStartedWrapper'

const useStyles = makeStyles((theme) => ({
  clearfix: {
    clear: 'both'
  },
  background: {
    position: 'relative'
  },
  button: {
    minWidth: 200,
  },
  heroText: {
    maxWidth: '600px',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.only('lg')]: {
      maxWidth: '570px'
    },
    [theme.breakpoints.only('xs')]: {
      display: 'none'
    }
  },
  more: {
    marginTop: theme.spacing(2),
  },
  row: {
    position: 'absolute',
    left: 0,
    maxWidth: '1260px',
    margin: '0 auto',
    width: '100%',
    top: '50%',
    transform: 'translateY(-50%) translateX(50%)',
    [theme.breakpoints.down('xl')]: {
      top: '50%',
      transform: 'translateY(-50%) translateX(35%)',
    },
    [theme.breakpoints.down('lg')]: {
      top: '50%',
      transform: 'translateY(-50%) translateX(10%)',
    },

    [theme.breakpoints.down('md')]: {
      top: '35%',
      transform: 'translateY(-25%)  translateX(10%)',
      float: 'left',
      maxWidth: '512px',
      width: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      top: '35%',
      transform: 'translateY(-25%)  translateX(10%)',
      float: 'left',
      maxWidth: '512px',
      width: '75%',
    },
  },
  captionContainerLeft: {
    height: '85vh',
    maxHeight: 1200,
    background: `url(${heroHomeImg})`,
    [theme.breakpoints.down('lg')]: {
      backgroundImage: `-webkit-image-set( url(${heroHomeImg1920}) 1x, url(${heroHomeImg}) 2x )`
    },
    [theme.breakpoints.down('md')]: {
      backgroundImage: `-webkit-image-set( url(${heroHomeImg1024}) 1x, url(${heroHomeTabletImg}) 2x )`
    },
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `-webkit-image-set( url(${heroHomeImg480}) 1x, url(${heroHomeMobileImg}) 2x )`
    },

    backgroundAttachment: 'fixed',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
  },

  headerGroup: {
    marginBottom: '20px',
  },
  parallaxContainer: {
    height: '100vh',
    overflowX: 'hidden',
    overflowY: 'auto',
    perspective: '1px'
  },
  parallaxImage: {
    width: '100%',
    position: 'absolute',
    top: '0',
  },
  parallaxImageContainer: {
    '-webkit-transform': 'translateZ(-1px) scale(2)',
    transform: 'translateZ(-1px) scale(2)'
  },
  heroImg: {
    position: 'absolute',
    left: '0',
    width: '100%',
    maxHeight: '100%'
  },
  getStartedButton: {
    margin: '0 10px 60px 0'
  },
  arrow: {
    position: 'relative',
    clear: 'both',
    display: 'block',
    width: '40px',
    height: '40px',
    margin: '0',
    animation: '$bounce 1.5s infinite ease-in-out',
  },
  '@keyframes bounce': {
    '0%': {
      transform: 'translateY(0)'
    },
    '50%': {
      transform: 'translateY(15px)'
    },
    '100%': {
      transform: 'translateY(0)'
    }
  },
  h1: {
    letterSpacing: 0,
    fontWeight: '500',
    lineHeight: 1.05,
    fontSize: 90,
    textTransform: 'uppercase',
    [theme.breakpoints.up('xs')]: {
      fontSize: '7vw',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '6vw',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '6vw',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 75,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 90,
    },
  },
}))

const HomePage = (props) => {

  const theme = useTheme()
  const classes = useStyles(theme)

  const isMd = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Box>
      <div className={classes.captionContainerLeft}>
        <div className={classes.row}>
          <div className={classes.headerGroup}>
            <Typography color="primary" align="left" className={classes.h1} marked="left">
                Your Drink.<br/>Your Way.
            </Typography>
          </div>
          <Typography color="inherit" align="left" variant="subtitle1" className={classes.heroText}>
              Personalization is revolutionizing the beverage industry and MYX is leading the way. MYX is the
              World’s first, green-tech, all-natural, fully customizable hydration system.
          </Typography>

          <GetStartedWrapper>
            <MyxRoundedButton
              data-testid={'home-page-get-started-btn'}
              variant={'outlined'} className={classes.getStartedButton}>
              Get Started
            </MyxRoundedButton>
          </GetStartedWrapper>
          {!isMd && <img alt={'more'} src={arrow} className={classes.arrow}/>}
        </div>
      </div>
      <Box className={classes.clearfix}>
        <BecomeAMemberBar/>
      </Box>
      <Box className={classes.clearfix}>
        <WorldsSmartestBeverageSystem/>
      </Box>
      <Box className={classes.clearfix}>
        <DiscoverNewMyxCarousel/>
      </Box>
      <Box className={classes.clearfix}>
        <MillionsOfRefreshingCombinations/>
      </Box>
      <Box className={classes.clearfix}>
        <Over1MillionCombinationsBar/>
      </Box>
      <Box className={classes.clearfix}>
        <SignUpForFree/>
      </Box>
      <Box className={classes.clearfix}>
        <BecomeAMemberBar/>
      </Box>
      <Box className={classes.clearfix}>
        <MyxMakesSplashAtNama/>
      </Box>
    </Box>
  )
}
export default HomePage
