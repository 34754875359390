import React from 'react'
import MyxImageGridCard from '../../../components/MyxImageGridCard'
import BrickIngredients from '../../../../assets/graphics/ingredients-benefits/brick-ingredients-1200x1200.jpg'

function EnjoyMillionsOfRefreshingMyxesForYou() {
  return <MyxImageGridCard
    showDownArrow={true}
    mediaSrc={BrickIngredients}
    mediaTitle="Drink"
    title="Enjoy Millions Of Refreshing MYXes - For You. By You."
    subTitle="Ingredients & Benefits"
    color="tertiary"
  />
}

export default EnjoyMillionsOfRefreshingMyxesForYou
