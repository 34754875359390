import React from 'react'
import { Box, Grid, Typography, useTheme } from '@material-ui/core'

const RequestResultGrid = ({ message, errors }) => {
  const theme = useTheme()
  return (
    <Grid item xs={12} sm={12} md={12} data-testid={'request-result-grid'}>
      {
        errors.map((error) =>
          <Box key={error.message} >
            <Typography variant="h5" data-testid={'request-result-grid-error'}
              style={{ color: errors.length > 0 ? 'red' : theme.palette.myxblack }}>
              {error.message}
            </Typography>
          </Box>
        )
      }
      {
        message &&
        <Box key={message} >
          <Typography data-testid={'request-result-grid-message'}
            style={{ color: errors.length > 0 ? 'red' : theme.palette.myxblack }}
            variant="h5">
            {message}
          </Typography>
        </Box>
      }
    </Grid>
  )
}

export default RequestResultGrid
