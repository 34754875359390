import React from 'react'
import LoginDialog from './dialogs/LoginDialog'
import SignupDialog from './SignupDialog'
import ForgotPasswordDialog from './dialogs/ForgotPasswordDialog'
import * as locationHelper from '../../helpers/locationHelper'

const getDialogComponent = (openDialog, sessionContext, hideModal, setOpenModal, goToMyAccount) => {

  const logInWrapper = (value) => sessionContext.logIn(value.email, value.password)
    .then(() => {
      goToMyAccount()
      hideModal()
    })

  switch (openDialog) {
  case 'login':
    return <LoginDialog
      data-testid={'login-dialog'}
      logIn={logInWrapper}
      onClose={hideModal}
      setOpenModal={setOpenModal}
    />
  case 'forgotpassword':
    return <ForgotPasswordDialog onClose={hideModal} setOpenModal={setOpenModal}/>
  case 'signup':
    return <SignupDialog onClose={hideModal} setOpenModal={setOpenModal}/>

  default:
    return <SignupDialog onClose={hideModal} setOpenModal={setOpenModal}/>

  }
}

const AccountDialogs = ({ startingDialog, hideModal, sessionContext }) => {

  const history = locationHelper.getRouterUseHistory()
  const [ openModal, setOpenModal ] = React.useState(startingDialog)

  return (<>
    {getDialogComponent(openModal, sessionContext, hideModal, setOpenModal, () => history.push('/my-account'))}
  </>)
}

export default AccountDialogs
