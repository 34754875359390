import React from 'react'
import ProductHeroLayout from '../../../views/ProductHeroLayout'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/styles'
import { Box, Typography } from '@material-ui/core'
import MyxDroplet from '../../../components/MyxDroplet'
import MyxRoundedButton from '../../../components/MyxRoundedButton'

const styles = makeStyles((theme) => ({
  '@keyframes tertiary': {
    '0%': {
      fill: theme.palette.tertiary.main
    },
    '50%': {
      fill: theme.palette.tertiary.fade
    },
    '100%': {
      fill: theme.palette.tertiary.main
    }
  },

  '@keyframes purple': {
    '0%': {
      fill: theme.palette.primary.main
    },
    '50%': {
      fill: theme.palette.primary.fade
    },
    '100%': {
      fill: theme.palette.primary.main
    }
  },
  background: {
    width: '100%',
    objectFit: 'cover',
    backgroundColor: 'white',
  },

  cardContainer: {
    maxWidth: '1200px',
    position: 'absolute',
    top: '50%',
    right: 0,
    left: 0,
    width: '90%',
    transform: 'translateY(-50%)',
    padding: '0 50px',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      padding: 0,
      position: 'relative',
      transform: 'translateY(0%)',
      width: '100%',
    },
  },
  beverageSystemCardRight: {
    float: 'right',
  },
  beverageSystemCard: {
    width: '35%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '380px',
    padding: '70px 50px',
    boxShadow: 'none',
    [theme.breakpoints.down('md')]: {
      padding: '50px 30px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '40px 20px',
      float: 'none !important',
    },
  },
  textContainer: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    alignContent: 'center'
  },

  title: {
    textTransform: 'uppercase',
    color: theme.palette.myxblack
  },
  para: {
    maxWidth: '340px',
    fontSize: '16px',
    fontWeight: '300',
    lineHeight: '1.6',
    letterSpacing: '0',
    margin: '0 0 40px 0',
  },
  underline: {
    height: '1px',
    backgroundColor: theme.palette.primary.main,
    width: '30px',
    display: 'inline-block',
    margin: '25px 0'
  },
  underlineTertiary: {
    backgroundColor: theme.palette.tertiary.main,
  },
  btn: {
    marginTop: '20px'
  }
}))

function HomePageCardView(props) {
  const classes = styles()
  const { picture, title, text, buttonText, handleBtnClick, tertiaryColor, cardRight } = props

  return (
    <ProductHeroLayout >
      {picture}
      <Box className={classes.cardContainer} >
        <Card className={classes.beverageSystemCard + ' ' + (cardRight ? classes.beverageSystemCardRight : null)}>
          <div className={classes.textContainer}>
            <MyxDroplet color={(tertiaryColor ? 'tertiary' : 'main')}/>
            <Typography variant="h2" className={classes.title}>{title}</Typography>
            <div className={classes.underline + (tertiaryColor ? (' ' + classes.underlineTertiary) : '')}/>
            <Typography variant="body1">
              {text}
            </Typography>
            <MyxRoundedButton
              className={classes.btn}
              onClick={handleBtnClick}
              myxvariant={tertiaryColor ? 'tertiaryBorder' : 'primaryBorder'}
              variant={'outlined'}>
              {buttonText}
            </MyxRoundedButton>
          </div>
        </Card>
      </Box>
    </ProductHeroLayout>)
}

export default HomePageCardView
