import * as Yup from 'yup'

export const PASSWORD_VALIDATION_ERROR_MSG = 'Password requirements:  8 to 100 characters. Must contain 1 letter, 1 number and 1 special character'

export function passwordValidationYup() {
  return Yup.string()
    .min(8, PASSWORD_VALIDATION_ERROR_MSG)
    .max(100, PASSWORD_VALIDATION_ERROR_MSG)
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      PASSWORD_VALIDATION_ERROR_MSG
    )
    .required('Password is required')
}

export function passwordMatch(confirmField) {
  return Yup.string()
    .oneOf([ Yup.ref(confirmField), null ], 'Passwords do not match')
}
