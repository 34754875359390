import React from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import MyxLogo from '../../assets/graphics/logo.svg'
import MyxLink from '../components/MyxLink'
import { Link } from 'react-router-dom'
import { useTheme } from '@material-ui/core'
import MuiAppBar from '@material-ui/core/AppBar'
import { makeStyles } from '@material-ui/styles'
import sessionContext from '../../state/sessionContext'
import UserAvatarButton from './UserAvatarButton'
import DesktopAvatarButton from './DesktopAvatarButton'

const styles = makeStyles((theme) => ({
  pageLink: {
    textTransform: 'uppercase',
    fontSize: 16,
    color: theme.palette.myxblack,
    float: 'left',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(1)

  },
  right: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    paddingLeft: theme.spacing(1)

  },
  middle: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    maxWidth: 700,
    margin: '0 75px',
    [theme.breakpoints.down('lg')]: {
      margin: '0 50px',
    }
  },
  menuButton: {
    margin: 0,
    padding: 0,
    '&:hover': {
      color: theme.palette.secondary.main
    }
  },
  lockButton: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    }
  },
  accountIcon: {
    marginRight: theme.spacing(1),
    height: theme.spacing(3),
    width: theme.spacing(3),
  },
  userSelect: {
    width: '180px',
  },
  mobileTitle: {
    width: 120,
  },
  link: {
    marginLeft: 12,
    marginRight: 12,
    [theme.breakpoints.down('md')]: {
      marginLeft: 6,
      marginRight: 6,
    }
  },
  mobileLink: {
    fontWeight: '200',
    textDecoration: 'none',
    textTransform: 'capitalize',
    color: theme.palette.myxblack
  },
  closeBtn: {
    width: 35,
    height: 35
  }
}))

const DesktopAppBar = () => {
  const classes = styles(useTheme())
  const session = sessionContext.useSessionContext()

  return (
    <MuiAppBar data-testid={'desktop-app-bar-container'} elevation={0} position="sticky" >
      <Toolbar >
        <div>
          <Link to="/">
            <img alt="Myx Logo" src={MyxLogo}/>
          </Link>
        </div>
        <div className={classes.middle}>
          <div className={classes.link}><MyxLink to={'/how-it-works/'}>How it works</MyxLink></div>
          <div className={classes.link}><MyxLink to={'/ingredients-benefits/'}>Ingredients & Benefits</MyxLink></div>
          <div className={classes.link}><MyxLink to={'/featured-myx/'}>Featured Myx</MyxLink></div>
          <div className={classes.link}><MyxLink to={'/operators/'}>Operators</MyxLink></div>
          <div className={classes.link}><MyxLink to={'/connect/'}>Connect</MyxLink></div>
        </div>
        <div className={classes.right}>
          { session.user ? <UserAvatarButton/> : <DesktopAvatarButton/>}
        </div>
      </Toolbar>
    </MuiAppBar>
  )
}

export default DesktopAppBar
