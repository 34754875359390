import { createTheme } from '@material-ui/core/styles'
import { green, grey, red } from '@material-ui/core/colors'

const rawTheme = createTheme({
  maxWidth: {
    large: 1300
  },
  palette: {
    primary: {
      light: '#ffffff',
      main: '#582e91',
      dark: '#1e1e1f',
      fade: '#986ed1',
      border: '#8a5acb'
    },
    secondary: {
      light: '#fff5f8',
      main: '#af3489',
      dark: '#e62958',
    },
    tertiary: {
      light: '#fff5f8',
      main: '#41beb2',
      fade: '#a0dfd9'
    },
    warning: {
      main: '#ffc071',
      dark: '#ffb25e',
    },
    error: {
      xLight: red[50],
      main: red[500],
      dark: red[700],
    },
    success: {
      xLight: green[50],
      main: green[500],
      dark: green[700],
    },
    myxblack: '#1c1b29',
    white: '#fff'
  },
  typography: {
    fontFamily: '"museo-sans-rounded",sans-serif',
    // fontSize: 14,
    fontWeightLight: 300, // Work Sans
    fontWeightRegular: 400, // Work Sans
    fontWeightMedium: 700, // Roboto Condensed
  },
})

const fontHeader = {
  color: rawTheme.palette.text.primary,
  fontWeight: rawTheme.typography.fontWeightMedium,
  fontFamily: rawTheme.typography.fontFamilySecondary,
  textTransform: 'uppercase',
}

const theme = {
  ...rawTheme,
  palette: {
    ...rawTheme.palette,
    background: {
      ...rawTheme.palette.background,
      default: rawTheme.palette.common.white,
      placeholder: grey[200],
    },
  },
  typography: {
    ...rawTheme.typography,
    fontHeader,
    h1: { // MyxImageGridCard
      ...rawTheme.typography.h1,
      ...fontHeader,
      letterSpacing: 0,
      fontWeight: '500',
      lineHeight: 1.05,
      [rawTheme.breakpoints.only('xs')]: {
        fontSize: 28,
      },
      [rawTheme.breakpoints.only('sm')]: {
        fontSize: '3.5vw',
      },
      [rawTheme.breakpoints.only('md')]: {
        fontSize: '3.5vw',
      },
      [rawTheme.breakpoints.only('lg')]: {
        fontSize: 52,
      },
      [rawTheme.breakpoints.only('xl')]: {
        fontSize: 52,
      },
      color: rawTheme.palette.myxblack
    },
    h2: { // HomePageCardView
      ...rawTheme.typography.h2,
      ...fontHeader,
      ...rawTheme.typography.h4,
      ...fontHeader,
      textTransform: 'uppercase',
      fontSize: 38,
      fontWeight: '500',
      lineHeight: 1,
      color: rawTheme.palette.primary.main,

      [rawTheme.breakpoints.only('xs')]: {
        fontSize: 28,
      },
      [rawTheme.breakpoints.only('sm')]: {
        fontSize: 38,
      },
      [rawTheme.breakpoints.only('md')]: {
        fontSize: 34,
      },
      [rawTheme.breakpoints.only('lg')]: {
        fontSize: 38,
      },
      [rawTheme.breakpoints.only('xl')]: {
        fontSize: 46,
      },
    },
    h3: {
      // FAQ
      // Enjoy Mindful Hydration
      // Mindful & Sustainable Ingredients
      ...rawTheme.typography.h3,
      ...fontHeader,
      fontSize: 38,
      lineHeight: 1.05,
      textTransform: 'uppercase',
      fontWeight: '500',
      color: rawTheme.palette.myxblack,
      [rawTheme.breakpoints.only('xs')]: {
        fontSize: 28,
      },
      [rawTheme.breakpoints.only('sm')]: {
        fontSize: 38,
      },
      [rawTheme.breakpoints.only('md')]: {
        fontSize: 32,
      },
      [rawTheme.breakpoints.only('lg')]: {
        fontSize: 40,
      },
    },
    h4: {
      // BecomeAMemberBar
      // MyxFooter(Create new if this need to be modified)
      ...rawTheme.typography.h4,
      ...fontHeader,
      textTransform: 'uppercase',
      fontWeight: '500',
      [rawTheme.breakpoints.up('lg')]: {
        fontSize: 33,
      },
      [rawTheme.breakpoints.only('lg')]: {
        fontSize: 33,
      },
      [rawTheme.breakpoints.only('md')]: {
        fontSize: '2.4vw',
      },
      [rawTheme.breakpoints.only('sm')]: {
        fontSize: '3.2vw',
      },
      [rawTheme.breakpoints.only('xs')]: {
        fontSize: 24,
      },
      color: rawTheme.palette.primary.main,
    },
    h5: {
      // Easy4Steps
      // OurNaturalIngredientCarosel
      ...rawTheme.typography.h5,
      color: rawTheme.palette.primary.main,
      fontSize: 23,
      lineHeight: 1.4,
      fontWeight: rawTheme.typography.fontWeightLight,
      [rawTheme.breakpoints.only('sm')]: {
        fontSize: '2.2vw',
      },
    },
    h6: {
      // Easy4Steps - step title
      // Ingredient Points
      ...rawTheme.typography.h6,
      ...fontHeader,
      fontSize: 25,
      textTransform: 'capitalize'

    },
    subtitle1: {
      // home page hero text
      // MyxImageGridCard ( note that font size is hardcoded to 16px)
      ...rawTheme.typography.subtitle1,
      fontSize: 22,
      fontWeight: rawTheme.typography.fontWeightLight,
      lineHeight: 1.4,
      color: rawTheme.palette.myxblack,
      [rawTheme.breakpoints.only('xs')]: {
        fontSize: '2.6vw',
      },
      [rawTheme.breakpoints.only('sm')]: {
        fontSize: 15,
      },
      [rawTheme.breakpoints.only('md')]: {
        fontSize: 17,
      },
      [rawTheme.breakpoints.only('lg')]: {
        fontSize: 20,
      },
      [rawTheme.breakpoints.only('xl')]: {
        fontSize: 22,
      }
    },
    subtitle2: {
      ...rawTheme.typography.subtitle2,
      // Featured MYX Drink Name
      color: rawTheme.palette.myxblack,
      [rawTheme.breakpoints.only('xs')]: {
        fontSize: 14,
      },
      [rawTheme.breakpoints.only('sm')]: {
        fontSize: '1.8vw',
      },
      [rawTheme.breakpoints.only('md')]: {
        fontSize: '1.9vw',
      },
      [rawTheme.breakpoints.only('lg')]: {
        fontSize: 18,
      },
      [rawTheme.breakpoints.only('xl')]: {
        fontSize: 18,
      }
    },
    body1: {
      // MYX image grid card
      ...rawTheme.typography.body1,
      fontWeight: rawTheme.typography.fontWeightLight,
      fontSize: 16,
      [rawTheme.breakpoints.only('md')]: {
        fontSize: 14,
      },
      lineHeight: 1.6,
      color: rawTheme.palette.myxblack,
    },
    body2: {
      // sign up bar messaging
      ...rawTheme.typography.body2,
      fontSize: 15,
      fontWeight: rawTheme.typography.fontWeightLight,
      color: rawTheme.palette.myxblack,
      [rawTheme.breakpoints.only('lg')]: {
        fontSize: 15,
      },
      [rawTheme.breakpoints.only('md')]: {
        fontSize: '1.1vw',
      },
      [rawTheme.breakpoints.only('sm')]: {
        fontSize: 13,
      },
      [rawTheme.breakpoints.only('sm')]: {
        fontSize: 22,
      }
    },
    caption: {
      ...rawTheme.typography.h4,
      ...fontHeader,
      textTransform: 'uppercase',
      fontSize: 33,
      fontWeight: '500',
      color: rawTheme.palette.primary.main,
      [rawTheme.breakpoints.only('xs')]: {
        fontSize: 22,
      },
      [rawTheme.breakpoints.only('sm')]: {
        fontSize: 25,
      },
      [rawTheme.breakpoints.only('md')]: {
        fontSize: 27,
      },
    }
  },
  overrides: {
    MuiGrid: {
      container: {
        display: 'flex',
        justifyContent: 'space-between',
      }
    },
    MuiContainer: {
      root: {
        marginRight: 'auto',
        marginLeft: 'auto',
        padding: 0,
        [rawTheme.breakpoints.up('xs')]: {
          padding: 0,
        },
      }
    },
    MuiAppBar: {
      root: {
        width: '100%',
        padding: '20px 35px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [rawTheme.breakpoints.down('md')]: {
          display: 'inline-block',
          padding: '0 10px',
        },
      },

      colorPrimary: {
        backgroundColor: 'white'
      },
      positionFixed: {
        left: 0
      }
    },
    MuiToolbar: {
      root: {
        width: '100%',
        maxWidth: '1320px',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'center',
        flex: 1,
      },
      regular: {

        [rawTheme.breakpoints.down('lg')]: {
          minHeight: 70,
        },
      },
      gutters: {
        paddingLeft: rawTheme.spacing(0),
        paddingRight: rawTheme.spacing(0),
        '@media (min-width: 600px)': {
          paddingLeft: rawTheme.spacing(0),
          paddingRight: rawTheme.spacing(0),
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        borderRadius: '25px',
        border: `1px ${rawTheme.palette.primary.main} solid`,
        backgroundColor: rawTheme.palette.primary.main,
        borderColor: rawTheme.palette.primary.main,
        color: '#fff',
        fontSize: '16px',
        fontWeight: '700',
        lineHeight: '1',
        padding: '15px 28px',
        margin: '0 6px 0 0',
        minWidth: 180,
        '&:hover': {
          backgroundColor: rawTheme.palette.secondary.main,
          borderColor: rawTheme.palette.secondary.main,
          color: rawTheme.palette.primary.light,
        },
        [rawTheme.breakpoints.down('lg')]: {
          margin: '0 6px 0 0',
          padding: '15px 28px',
          fontSize: '16px',
        },
        [rawTheme.breakpoints.down('md')]: {
          fontSize: '12px',
          padding: '14px 25px',
        },
      },
      outlined: {
        textTransform: 'none',
        borderRadius: '25px',
        border: '1px white solid',
        backgroundColor: 'white',
        borderColor: 'white',
        margin: '0 auto',
        color: rawTheme.palette.myxblack,
        fontSize: '20px',
        fontWeight: '700',
        lineHeight: '1',
        padding: '16px 40px',
        minWidth: 180,
        '&:hover': {
          backgroundColor: rawTheme.palette.primary.main,
          borderColor: rawTheme.palette.primary.main,
          color: 'white',
        },
        [rawTheme.breakpoints.only('lg')]: {
          margin: '0 6px 0 0',
          padding: '15px 28px',
          fontSize: '16px',
        },
        [rawTheme.breakpoints.only('md')]: {
          fontSize: '15px',
          padding: '14px 25px',
        },
        [rawTheme.breakpoints.only('sm')]: {
          fontSize: '14px',
          padding: '14px 25px',
        },
        [rawTheme.breakpoints.only('xs')]: {
          fontSize: '12px',
          padding: '14px 25px',
        },
      },
      text: {
        padding: '15px 28px',
      }
    },
    MuiSvgIcon: {
      root: {
        height: '22px',
        width: '22px'
      }
    },
    MuiPaper: {},
    MuiInputLabel: {
      outlined: {
        '&.MuiInputLabel-shrink': {
          transform: 'translate(14px, -6px) scale(0.75)',
        },
        animated: {
          // transition: 'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms'
        }
      },
    },
    MuiTextField: {
      root: {
        '& .MuiInput-underline:after': {
          borderBottomColor: rawTheme.palette.primary.main,
        },
        '& .MuiOutlinedInput-root': {
          borderRadius: '32px',
          '& fieldset': {
            border: `2px solid ${rawTheme.palette.primary.border}`,
            borderRadius: '32px',
          },
          '&:hover fieldset': {
            border: `2px solid ${rawTheme.palette.primary.border}`,
            borderRadius: '32px',
          },
          '&.Mui-focused fieldset': {
            border: `2px solid ${rawTheme.palette.primary.border}`,
            borderRadius: '32px',
          },
        },
      },
    },
    MuiSelect: {
      selectMenu: {
        overflow: 'visible'
      }
    }
  },

}

export default theme
