import React from 'react'
import FadeInUp from '../views/FadeInUp'
import { ButtonGroup, Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import * as locationHelper from '../../helpers/locationHelper'

const useStyles = makeStyles((theme) => ({
  carouselButton: {
    borderRadius: '0',
    border: '0px',
    '&:hover': {
      color: theme.palette.tertiary.main,
      backgroundColor: 'white',
      borderRadius: '0',
      border: '0px',
    }
  },
  carouselButtonSelected: {
    borderRadius: '0',
    border: '0px',
    color: theme.palette.tertiary.main,
    '&:hover': {
      color: theme.palette.tertiary.main,
      backgroundColor: 'white',
      borderRadius: '0',
      border: '0px',
    }
  },

  gridContainer: {
    marginTop: '5%',
    marginBottom: '5%',
  },
  gridButtonGroup: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

export const links = Object.freeze({
  myAccount: '/my-account',
  myTransactions: '/my-transactions',
  signOut: '/sign-out'
})

function AccountNavButtonGroup({ selectedLink }) {

  const history = locationHelper.getRouterUseHistory()
  const classes = useStyles()
  const selected = selectedLink || links.myAccount

  const getButtonStyles = (btn) => btn === selected ? classes.carouselButtonSelected : classes.carouselButton

  const navSelected = (link) => {
    history.push(link)
  }

  return (
    <FadeInUp>
      <Container maxWidth={false} className={classes.gridContainer}>
        <div className={classes.gridButtonGroup}>
          <ButtonGroup>
            <Button className={getButtonStyles(links.myAccount)} onClick={() => navSelected(links.myAccount)}>
          My Account
            </Button>
            <Button className={getButtonStyles(links.myTransactions)} onClick={() => navSelected(links.myTransactions)}>
          My Transactions
            </Button>
            <Button className={getButtonStyles(links.signOut)} onClick={() => navSelected(links.signOut)}>
          Sign Out
            </Button>
          </ButtonGroup>
        </div>
      </Container>
    </FadeInUp>)
}

export default AccountNavButtonGroup
