import React from 'react'
import * as windowHelper from '../helpers/windowHelper'

const WindowSizeContext = React.createContext(null)

export const WindowSizeProvider = (props) => {

  const [ windowDimensions, setWindowDimensions ] = React.useState(windowHelper.getWindowDimensions())
  React.useEffect(() => {
    const handleResize = () => setWindowDimensions(windowHelper.getWindowDimensions())
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const contextValue = [ windowDimensions ]

  return <WindowSizeContext.Provider value={contextValue} {...props}/>
}

export const useWindowSizeContext = () => React.useContext(WindowSizeContext)
