import React from 'react'
import MyxImageGridCard from '../../../components/MyxImageGridCard'
import HeroVending from '../../../../assets/graphics/homepage/heros/vending/hero-vending-1-1200x1200.jpg'
import MyxRoundedButton from '../../../components/MyxRoundedButton'
import * as locationHelper from '../../../../helpers/locationHelper'

function SmarterDrinkKiosk() {
  const history = locationHelper.getRouterUseHistory()
  return (<MyxImageGridCard
    mediaSrc={HeroVending}
    mediaTitle="A Smarter Drink Kiosk"
    title="A Smarter Drink Kiosk"
    subText="MYX is your all-in-one hydration solution. Using your existing water line, MYX provides still, sparkling,
     and flavored water that will never run out. Always filtered to give the best tasting drink.
     If your machine needs servicing our technicians are automatically notified and deployed to minimize downtime."
  >
    <MyxRoundedButton
      onClick={() => history.push('/connect')}
      myxvariant="primaryBorder"
      variant={'outlined'}>
          Want More? Let's Talk.
    </MyxRoundedButton>
  </MyxImageGridCard>)
}

export default SmarterDrinkKiosk
