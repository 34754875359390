import React from 'react'
import { ReactComponent as Inquire } from '../../../../assets/graphics/connect/inquire.svg'
import { ReactComponent as Service } from '../../../../assets/graphics/connect/service.svg'
import { ReactComponent as Kiosk } from '../../../../assets/graphics/connect/kiosk.svg'
import { Container, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import MyxLink from '../../../components/MyxLink'
import FadeInUp from '../../../views/FadeInUp'

const useStyles = makeStyles((theme) => ({
  icon: {
    height: '100px',
    width: '100%',
    display: 'block',
    alignContent: 'center',
    paddingBottom: '5%'
  },
  subtitle: {
    paddingTop: '5%',
    fontSize: 15,
    textAlign: 'center',
  },
}))

const InquiryGridItem = (props) => <Grid item xs={12} sm={6} md={3}>
  {props.children}
</Grid>

const linkStyles = {
  display: 'flex',
  fontSize: '16px',
  textTransform: 'none',
  justifyContent: 'center',
  paddingTop: '5%'
}

function ServiceInquires() {
  const classes = useStyles()
  return (
    <FadeInUp>
      <Container style={{ marginTop: '5%', marginBottom: '10%' }}>
        <Grid container spacing={4}>
          <InquiryGridItem>
            <Inquire className={classes.icon}/>
            <Typography variant="h6" align="center">Inquiries</Typography>
            <MyxLink
              style={linkStyles}
              href="mailto:myxmaster@myxdrinks.com">
              myxmaster@myxdrinks.com
            </MyxLink>
          </InquiryGridItem>
          <InquiryGridItem>
            <Service className={classes.icon}/>
            <Typography variant="h6" align="center">Customer Service</Typography>
            <MyxLink
              style={linkStyles}
              href="mailto:customer.service@myxdrinks.com">
              customer.service@myxdrinks.com
            </MyxLink>
          </InquiryGridItem>
          <InquiryGridItem>
            <Kiosk className={classes.icon}/>
            <Typography variant="h6" align="center">Kiosk Service</Typography>
            <MyxLink
              style={linkStyles}
              href="mailto:service@myxdrinks.com">
              service@myxdrinks.com
            </MyxLink>
          </InquiryGridItem>
        </Grid>
      </Container>
    </FadeInUp>
  )
}

export default ServiceInquires
