import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Formik } from 'formik'
import { Box, Container, IconButton, TextField, Typography, useTheme } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { ReactComponent as MyxDroplet } from '../../../assets/graphics/logo-droplet.svg'
import MyxFormikWrapper from '../MyxFormikWrapper'
import LogInSignUpLinks from '../LogInSignUpLinks'
import { MailOutlineRounded } from '@material-ui/icons'
import { requestPasswordRecovery } from '../../../helpers/userHelper'
import * as Yup from 'yup'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    margin: `${theme.spacing(1)}px auto`,
    maxWidth: '400px'
  },
  boxContainer: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    margin: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    maxWidth: '400px',
  },
  boxItem: {
    marginTop: theme.spacing(0.5)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  lockButton: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    }
  }
}))

function ForgotPasswordDialog(props) {
  // eslint-disable-next-line no-warning-comments
  // TODO: this does not work!!!!
  const theme = useTheme()
  const classes = useStyles(theme)
  const completedMsg = 'Please check your email.'
  const disableButton = () => formState.message === completedMsg

  const [ formState, setFormState ] = React.useState({ errors: [], message: undefined })
  const notifyUnableToFetch = (error) => {
    setFormState({
      errors: [ { code: '', message: 'Sorry, we couldn\'t process your request.' } ],
      message: null }
    )
  }
  const FormSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .email('Invalid Email')
  })

  return (
    <Container
      data-testid={'forgot-password-dialog'}
      className={classes.container}>
      <Formik
        initialValues={{
          email: undefined,
        }}
        validationSchema={FormSchema}

        onSubmit={(value) => {
          requestPasswordRecovery(value.email)
            .then((response) => {
              setFormState({ errors: [], message: completedMsg })
            }).catch((error) => {
              if (error.jsonPromise) {
                error.jsonPromise.then(response => {
                  setFormState({ errors: response.errors, message: undefined })
                })
              } else {
                notifyUnableToFetch(error)
              }
            })
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form id="forgot-password-form" onSubmit={handleSubmit}>
            <IconButton aria-label="close" className={classes.closeButton} onClick={props.onClose}>
              <CloseIcon/>
            </IconButton>
            <Box className={classes.boxContainer}>
              <Box className={classes.boxItem}>
                <MyxDroplet />
              </Box>
              <Box className={classes.boxItem}>
                <Typography variant="h4">
                PASSWORD RECOVERY
                </Typography>
              </Box>
              <Box className={classes.boxItem}>
                <Typography variant="body1">
                Forgot your password? Input your email below and you will receive an email to reset.
                </Typography>
              </Box>
              <Box className={classes.boxItem}>
                <MyxFormikWrapper
                  name="email"
                  label="Email"
                  margin="normal"
                  variant="outlined"
                  errors={ { email: [ errors.email, ...formState.errors.map(err => err.message) ] }}
                  touched={touched}
                >
                  <TextField/>
                </MyxFormikWrapper>
              </Box>

              <Box className={classes.boxItem}>
                <IconButton
                  disabled={disableButton()}
                  type="submit"
                  edge="start"
                  className={classes.lockButton}
                  color="primary"
                  aria-label="menu">
                  <MailOutlineRounded />
                </IconButton>
              </Box>
              {
                formState.message ? <Typography style={{ color: theme.palette.primary.main }} variant="h5">{formState.message}</Typography> : null
              }
              <LogInSignUpLinks setOpenModal={props.setOpenModal}/>
            </Box>
          </form>)}
      </Formik>
    </Container>)
}

export default ForgotPasswordDialog
