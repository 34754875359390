import React from 'react'
import { makeStyles } from '@material-ui/styles'

const styles = makeStyles((theme) => ({
  sliderArrowPrev: {
    position: 'absolute',
    left: '20px',
    backgroundImage: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'49\' height=\'25\' viewBox=\'0 0 49 25\'%3E%3Cpath d=\'M.4 11.1c0 .1-.1.1-.1.2s-.1.2-.1.2c0 .1-.1.2-.1.2 0 .1 0 .1-.1.2V13c0 .1 0 .1.1.2 0 .1 0 .2.1.2 0 .1.1.2.1.2 0 .1.1.1.1.2.1.1.2.3.3.4l10 10c1 1 2.6 1 3.5 0s1-2.6 0-3.5L8.5 15h38c1.4 0 2.5-1.1 2.5-2.5S47.9 10 46.5 10h-38l5.7-5.7c1-1 1-2.6 0-3.5-.4-.6-1.1-.8-1.7-.8s-1.3.2-1.8.7l-10 10c-.1.1-.2.3-.3.4\'/%3E%3Cpath fill=\'none\' d=\'M0 0h49v25H0z\'/%3E%3C/svg%3E")',
    transition: '200ms all ease',
    border: '0',
    padding: '0',
    top: '50%',
    zIndex: '10',
    height: '42px',
    width: '42px',
    borderRadius: '50%',
    backgroundSize: '13px',
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#fff',
    outline: 'none',
    boxShadow: '0px 3px 23px -3px rgba(0,0,0,0.5)'
  },
}))

function MyxCarouselLeftButton({ onClick }) {
  const classes = styles()
  return <button className={classes.sliderArrowPrev} onClick={() => onClick()} />
}

export default MyxCarouselLeftButton
