import React from 'react'
import { Container, Typography, useTheme } from '@material-ui/core'
import MyxUnderline from './MyxUnderline'
import { makeStyles } from '@material-ui/styles'
import MyxLink from './MyxLink'
import { ReactComponent as Kiosk } from '../../assets/graphics/connect/kiosk.svg'

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
    margin: `${theme.spacing(5)}px auto`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    flexDirection: 'column',
  }
}))

function MyxMasterPass({ userName }) {
  const theme = useTheme()
  const classes = useStyles(theme)
  return (
    <Container className={classes.container}>
      <Typography variant="h3" style={{ color: theme.palette.myxblack }}>
        Hello, {userName}.
      </Typography>
      <Typography variant="h6">
        Welcome To The Benefits of MYX Masterpass
      </Typography>
      <MyxUnderline/>
      <MyxLink to={'/kiosk-log-in/'}
        style={{ margin: `${theme.spacing(4)}px ` }}>
        <Kiosk
          fill={theme.palette.primary.main}
          style={{ height: 200, width: 200 }}
        />
        <Typography variant="h3"
          style={{ color: theme.palette.secondary.main }}>
        Click Here To Log Into A Kiosk
        </Typography>
      </MyxLink>

    </Container>)
}

export default MyxMasterPass
